import { useEffect } from 'react';
import { findLearnerGroup, getStudentLearnerGroup } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectLearnerGroupList } from '../../selectors';
// import { AuthContext } from '../../contexts';

export type UseLearnerGroupProps = {
  params?: Record<string, any> | any;
  key: string;
  classId?: string;
  doFind?: boolean;
};

export type UseLearnerGroupType = {
  handleFetchStudentLearnerGroups: (
    classId: string,
    options?: ActionOption
  ) => void;
  learnerGroups: LessonNameSpace.LearnerGroup[];
  learnerGroupList: { value: string; label: string }[];
  studentLearnerGroups: LessonNameSpace.StudentLearnerGroup[];
};

export const useLearnerGroup = ({
  params,
  classId,
  key,
  doFind = true,
}: UseLearnerGroupProps): UseLearnerGroupType => {
  const dispatch = useDispatch();

  // const { auth } = useContext(AuthContext);

  const defaultOptions: ActionOption = {
    params: {
      // user: auth?._id,
      ...(params ?? {}),
    },
    key,
  };

  const { learnerGroups, learnerGroupList, studentLearnerGroups } = useSelector(
    ({ learnerGroup }: RootState) => ({
      learnerGroup,
      learnerGroupList: selectLearnerGroupList(learnerGroup, key),
      learnerGroups: learnerGroup?.learnerGroups[key],
      studentLearnerGroups: learnerGroup?.studentLearnerGroups[key] || [],
    })
  );

  useEffect(() => {
    if (doFind) {
      dispatch(
        findLearnerGroup(defaultOptions.params ?? {}, defaultOptions.key)
      );
    }
  }, [doFind, classId]);

  const handleFetchStudentLearnerGroups = (
    classId: string,
    options?: ActionOption
  ) => {
    dispatch(
      getStudentLearnerGroup(classId, { ...defaultOptions, ...options })
    );
  };

  return {
    learnerGroups,
    learnerGroupList,
    studentLearnerGroups,
    handleFetchStudentLearnerGroups,
  };
};
