import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_RECORDING = createActionType('FIND_RECORDING', 'RECORDING');

export const findRecording = (options?: ActionOption) => ({
  type: FIND_RECORDING.START,
  meta: {
    ...options,
  },
});
