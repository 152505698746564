import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_SYLLABUS_CATEGORY = createActionType(
  'GET_SYLLABUS_CATEGORY',
  'SYLLABUS_CATEGORY'
);

export const getSyllabusCategory = (_id: string, option?: ActionOption) => ({
  type: GET_SYLLABUS_CATEGORY.START,
  meta: {
    ...option,
    _id,
  },
});
