import Uppy, { UploadResult } from '@uppy/core';
import OneDrive from '@uppy/onedrive';
import GoogleDrive from '@uppy/google-drive';
import Dropbox from '@uppy/dropbox';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { FormInstance, message } from 'antd';
import { get, isEmpty } from 'lodash';
import { getToken } from '../../../_shared/utils';
import UploadVideoOrResourceList from '../UploadVideoOrResourceList';
import { FILE_UPLOAD_URL } from '../../../_shared/constants';

type UppyUploadPropType = {
  allowedFileTypes?: string[];
  note?: string;
  fileData: Record<string, any>;
  setFilesData: Dispatch<SetStateAction<Record<string, any>>>;
  openDropDown?: any;
  trigger?: string;
  form?: FormInstance;
  submitType?: string;
  onSuccess?: (payload: any, options?: Record<string, any>) => void;
};

const UppyUpload = (props: UppyUploadPropType) => {
  const {
    allowedFileTypes = ['image/*', 'video/*'],
    fileData,
    setFilesData,
    note,
    trigger = '.uppy-video-display',
    openDropDown,
    form,
    submitType,
    onSuccess,
  } = props;
  const token = getToken();
  // const { value } = useTheme();
  // @ts-ignore
  const UPPY = new Uppy({
    meta: { type: 'avatar' },
    restrictions: {
      allowedFileTypes: allowedFileTypes,
    },
    autoProceed: false,
  });
  const uppy = useMemo(() => {
    return (
      UPPY.use(GoogleDrive, {
        companionUrl: process.env.REACT_APP_GOOGLE_DRIVE_COMPANION,
      })
        .use(Dropbox, {
          companionUrl: 'https://companion.uppy.io',
        })
        .use(OneDrive, {
          companionUrl: process.env.REACT_APP_GOOGLE_DRIVE_COMPANION,
        })
        // .use(CustomUrlUpload, {
        //   companionUrl: process.env.REACT_APP_GOOGLE_DRIVE_COMPANION,
        // })
        .use(XHRUpload, {
          endpoint: FILE_UPLOAD_URL,
          headers: {
            'x-auth-token': token,
          },
        })
    );
  }, []);

  useEffect(() => {
    return () => uppy.close();
  }, []);

  //@ts-ignore
  useEffect(() => {
    const handler: any = async (_: UploadResult<{}>, response: any) => {
      if (!isEmpty(response.body)) {
        const result =
          submitType === 'videos'
            ? {
                ...response.body.data,
                title: response.body.data.name,
              }
            : response.body.data;
        const name = get(result, 'name');
        const url = get(result, 'url');
        const id = get(result, 'id');
        await message.success(`${name} file uploaded successfully.`);
        await openDropDown(false);
        if (submitType) {
          await form?.setFields([
            {
              name: submitType,
              value: [...fileData.uploadedFiles, result],
            },
          ]);
          await onSuccess?.(form?.getFieldsValue(true), {
            onFinish: () => {
              setFilesData((prev) => ({
                file: [...prev.file, { ...result, url, id }],
                url: [...prev.url, url],
                uploadedFiles: [...prev.uploadedFiles, result],
              }));
            },
          });
        } else {
          setFilesData((prev) => ({
            file: [...prev.file, { ...result, url, id }],
            url: [...prev.url, url],
            uploadedFiles: [...prev.uploadedFiles, result],
          }));
        }
      }
    };
    uppy.on('upload-success', handler);
    return () => uppy.off('upload-success', handler);
  }, [fileData]);

  const dProps: Record<string, any> = {
    id: 'Dashboard',
    trigger: trigger,
    inline: false,
    metaFields: [],
    replaceTargetContent: true,
    showProgressDetails: true,
    height: 470,
    browserBackButtonClose: true,
    autoOpenFileEditor: false,
    animateOpenClose: true,
    closeModalOnClickOutside: true,
    note,
    // theme: themeValue,
  };

  const handleRemoveFile = (url: string, id: string, data?: string) => {
    if (data) {
      setFilesData((prev) => {
        return {
          file: prev?.file.filter(
            (file: Record<string, any>) => file.data !== data
          ),
          url: prev?.url.filter((fileUrl: string) => fileUrl !== url),
          uploadedFiles: prev?.uploadedFiles.filter(
            (file: Record<string, any>) => file.data !== data
          ),
        };
      });
      if (submitType) {
        form?.setFields([
          {
            name: submitType,
            value: fileData?.uploadedFiles.filter(
              (file: Record<string, any>) => file.data !== data
            ),
          },
        ]);
        onSuccess?.(form?.getFieldsValue(true), {});
      }
    } else {
      setFilesData((prev) => {
        return {
          file: prev?.file.filter(
            (file: Record<string, any>) => file._id !== id
          ),
          url: prev?.url.filter((fileUrl: string) => fileUrl !== url),
          uploadedFiles: prev?.uploadedFiles.filter(
            (file: Record<string, any>) => file._id !== id
          ),
        };
      });
      if (submitType) {
        form?.setFields([
          {
            name: submitType,
            value: fileData?.uploadedFiles.filter(
              (file: Record<string, any>) => file._id !== id
            ),
          },
        ]);
      }
      onSuccess?.(form?.getFieldsValue(true), {});
    }
  };

  return (
    <>
      <Dashboard
        uppy={uppy}
        {...dProps}
        plugins={['GoogleDrive', 'OneDrive', 'Dropbox']}
      />
      <UploadVideoOrResourceList
        fileData={fileData?.file}
        submitType={submitType}
        handleRemoveFile={handleRemoveFile}
      />
    </>
  );
};

export default UppyUpload;
