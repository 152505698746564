import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ATTENDANCE_CLASS_PERCENTAGE = createActionType(
  'GET_ATTENDANCE_CLASS_PERCENTAGE',
  'ATTENDANCE'
);

export const getAttendanceClassPercentage = (
  _id: string,
  option?: ActionOption
) => ({
  type: GET_ATTENDANCE_CLASS_PERCENTAGE.START,
  meta: {
    _id,
    ...option,
  },
});
