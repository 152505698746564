import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const POST_ANNOUNCEMENT_COMMENTS = createActionType(
  'POST_ANNOUNCEMENT_COMMENTS',
  'ANNOUNCEMENTS'
);

export const postAnnouncementComments = (
  announcementId: string,
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: POST_ANNOUNCEMENT_COMMENTS.START,
  meta: {
    announcementId,
    payload,
    ...option,
  },
});
