import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GROUP_LESSON_BY_UNIT = createActionType(
  'GROUP_LESSON_BY_UNIT',
  'UNIT'
);

export const groupLessonByUnit = (options?: ActionOption) => ({
  type: GROUP_LESSON_BY_UNIT.START,
  meta: {
    ...options,
  },
});
