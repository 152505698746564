import { createContext, ReactNode, ReactNodeArray } from 'react';
import { useStandard } from '../../hooks';
import { PROVIDER_KEYS } from '../../_shared/constants';

export type StandardProviderStateType = {
  standards: LessonNameSpace.Standard[];
  standardList: { value: string; label: string }[];
  key: string;
  standardsById: Record<string, LessonNameSpace.Standard>;
};

const initialState: StandardProviderStateType = {
  standards: [],
  standardList: [],
  standardsById: {},
  key: PROVIDER_KEYS.Standards,
};

interface StandardProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const StandardsContext = createContext(initialState);

export const StandardProvider = ({ children }: StandardProviderProps) => {
  const { standards, standardList, standardsById } = useStandard({
    params: {
      all: true,
    },
    key: PROVIDER_KEYS.Standards,
    doFind: true,
  });

  return (
    <StandardsContext.Provider
      value={{
        standards,
        key: PROVIDER_KEYS.Standards,
        standardList,
        standardsById,
      }}
    >
      {children}
    </StandardsContext.Provider>
  );
};
