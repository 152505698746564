import {
  getAttendanceCount,
  getExitTicketStandardCount,
  getRedFlagsCount,
  getSchoolAttendance,
  getSchoolRedFlags,
  getGradeLevels,
  getSchoolClasses,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';

export type SchoolProps = {
  key: string;
  schoolID?: string;
  params?: Record<string, any> | any;
  doFind?: {
    attendance?: boolean;
    redFlags?: boolean;
    exitTicketStandard?: boolean;
  };
};

export type SchoolType = {
  getAttendanceCount: (schoolID: string, options?: ActionOption) => void;
  getRedFlagsCount: (schoolID: string, options?: ActionOption) => void;
  getAttendance: (schoolID: string, options?: ActionOption) => void;
  getRedFlags: (schoolID: string, options?: ActionOption) => void;
  getExitTicketStandardCount: (
    schoolID: string,
    options?: ActionOption
  ) => void;
  getGradeLevels: (accountID: string, options?: ActionOption) => void;
  getSchoolClasses: (options?: ActionOption) => void;
  attendanceCount: Record<string, any>[];
  redFlagsCount: Record<string, any>[];
  exitTicketStandardCount: Record<string, any>[];
  attendance: Record<string, any>[];
  redFlags: Record<string, any>[];
  gradeLevels: string[];
  classes: Record<string, any>[];
};

export const useSchool = ({
  schoolID,
  params,
  key,
  doFind,
}: SchoolProps): SchoolType => {
  const dispatch = useDispatch();

  const {
    attendanceCount,
    redFlagsCount,
    exitTicketStandardCount,
    redFlags,
    attendance,
    gradeLevels,
    classes,
  } = useSelector(({ school }: RootState) => ({
    attendanceCount: school?.attendanceCount[key],
    redFlagsCount: school?.redFlagsCount[key],
    attendance: school?.attendance[key],
    redFlags: school?.redFlags[key],
    exitTicketStandardCount: school?.exitTicketStandardCount[key],
    gradeLevels: school?.gradeLevels[key],
    classes: school?.classes[key],
  }));

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const $getAttendanceCount = (schoolID: string, options?: ActionOption) => {
    dispatch(getAttendanceCount(schoolID, { ...defaultOptions, ...options }));
  };

  const $getRedFlagsCount = (schoolID: string, options?: ActionOption) => {
    dispatch(getRedFlagsCount(schoolID, { ...defaultOptions, ...options }));
  };

  const $getAttendance = (schoolID: string, options?: ActionOption) => {
    dispatch(getSchoolAttendance(schoolID, { ...defaultOptions, ...options }));
  };

  const $getRedFlags = (schoolID: string, options?: ActionOption) => {
    dispatch(getSchoolRedFlags(schoolID, { ...defaultOptions, ...options }));
  };

  const $getExitTicketStandardCount = (
    schoolID: string,
    options?: ActionOption
  ) => {
    dispatch(
      getExitTicketStandardCount(schoolID, { ...defaultOptions, ...options })
    );
  };

  const $getGradeLevels = (accountId: string, options?: ActionOption) => {
    dispatch(getGradeLevels(accountId, { ...defaultOptions, ...options }));
  };

  const $getSchoolClasses = (options?: ActionOption) => {
    dispatch(getSchoolClasses({ ...defaultOptions, ...options }));
  };

  useEffect(() => {
    if (schoolID) {
      if (doFind?.attendance) {
        $getAttendanceCount(schoolID, defaultOptions);
      }
      if (doFind?.redFlags) {
        $getRedFlagsCount(schoolID, defaultOptions);
      }
      if (doFind?.exitTicketStandard) {
        $getExitTicketStandardCount(schoolID, defaultOptions);
      }
    }
  }, [schoolID]);

  return {
    redFlagsCount,
    attendanceCount,
    exitTicketStandardCount,
    redFlags,
    attendance,
    gradeLevels,
    classes,
    getAttendanceCount: $getAttendanceCount,
    getRedFlagsCount: $getRedFlagsCount,
    getExitTicketStandardCount: $getExitTicketStandardCount,
    getAttendance: $getAttendance,
    getRedFlags: $getRedFlags,
    getGradeLevels: $getGradeLevels,
    getSchoolClasses: $getSchoolClasses,
  };
};
