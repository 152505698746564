import {
  createExitTicket,
  createLesson,
  deleteLesson,
  findLesson,
  getLesson,
  updateLesson,
  getTodayLesson,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectLessonList, selectTodayLesson } from '../../selectors/lesson';
import { useEffect } from 'react';

export type UseLessonProps = {
  key: string;
  userId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseLessonType = {
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleCreateExitTicket: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleUpdate: (
    _id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleFetch: (options?: ActionOption) => void;
  handleFetchTodayLesson: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  handleDelete: (_id: string, options?: ActionOption) => void;
  lessons: LessonNameSpace.Lesson[];
  lessonList: { label: string; value: string }[];
  currentLesson: LessonNameSpace.Lesson;
  lessonsById: Record<string, LessonNameSpace.Lesson>;
  todayLesson: LessonNameSpace.Lesson[];
};

export const useLesson = ({
  params,
  key,
  doFind,
}: UseLessonProps): UseLessonType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
  };

  const { currentLesson, lessons, lessonList, todayLesson, lessonsById } =
    useSelector(({ lesson }: RootState) => ({
      currentLesson: lesson?.currentLesson[key],
      lessonList: selectLessonList(lesson, key) || [],
      lessons: lesson?.lessons[key] || [],
      lessonsById: lesson?.lessonsById[key],
      todayLesson: selectTodayLesson(lesson, key),
    }));

  useEffect(() => {
    if (doFind) {
      dispatch(
        findLesson({
          params,
          key,
        })
      );
    }
  }, [doFind]);

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createLesson(payload, { ...defaultOptions, ...options }));
  };

  const handleCreateExitTicket = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createExitTicket(payload, { ...defaultOptions, ...options }));
  };

  const handleUpdate = (
    id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(updateLesson(id, payload, { ...defaultOptions, ...options }));
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(deleteLesson(id, { ...defaultOptions, ...options }));
  };

  const handleFetch = (options?: ActionOption) => {
    dispatch(findLesson({ ...defaultOptions, ...options }));
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getLesson(id, { ...defaultOptions, ...options }));
  };

  const handleFetchTodayLesson = (options?: ActionOption) => {
    dispatch(getTodayLesson({ ...defaultOptions, ...options }));
  };

  return {
    handleCreateExitTicket,
    handleCreate,
    handleUpdate,
    handleFetch,
    handleFetchOne,
    handleDelete,
    handleFetchTodayLesson,
    currentLesson,
    lessonList,
    lessons,
    lessonsById,
    todayLesson,
  };
};
