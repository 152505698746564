import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const UPDATE_QUESTION = createActionType('UPDATE_QUESTION', 'QUESTION');

export const updateQuestion = (_id: string, payload: Record<string, any>, options?: ActionOption) => ({
  type: UPDATE_QUESTION.START,
  meta: {
    _id,
    payload,
    ...options
  }
});
