import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_FINAL_ACTIVITY_GRADE = createActionType(
  'UPDATE_FINAL_ACTIVITY_GRADE',
  'ASSIGNMENTS'
);

export const updateFinalActivityGrade = (
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: UPDATE_FINAL_ACTIVITY_GRADE.START,
  meta: {
    payload,
    ...option,
  },
});
