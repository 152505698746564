import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_MEMBERS = createActionType('GET_MEMBERS', 'MEMBERS');

export const getMembers = (userId: string, options: ActionOption) => ({
  type: GET_MEMBERS.START,
  meta: {
    ...options,
    userId,
  },
});
