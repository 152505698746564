import {Avatar, Button, Comment, Form, Popconfirm, Popover, Tooltip, Typography,} from 'antd';
import {get, isEmpty, upperCase} from 'lodash';
import {COLOR_LIST_ALPHA} from 'src/_shared/constants';
import {formatDistanceToNow} from 'date-fns';
import {motion} from 'framer-motion';
import {useState} from 'react';
import './commentItem.scss';
import {useAuth, useUIState} from 'src/hooks';
import {Parser} from 'html-to-react';
import {ConditionalRenderer} from '../..';
import {parseFilenameFromURL} from 'src/_shared/utils';
import {CKEditorExtended} from '../../CKEditorExtended';

const { Paragraph } = Typography;
const HTMLParser = new Parser();

interface CommentItemProps {
  onReportAbuseFormVisibilityChange?: (visibility: boolean) => void;
  item: ClassesNamespace.Comments;
  onPostComment: (
    values: Record<string, any>,
    commentId: string,
    cb: (visibility: boolean) => void
  ) => void;
  onUpdateComment: (
    values: Record<string, any>,
    commentId: string,
    cb: (visibility: boolean) => void
  ) => void;
  onDeleteComment: (commentId: string) => void;
  loading: boolean;
  isReply?: boolean;
  baseKey?: string;
}
export const CommentItem = (props: CommentItemProps) => {
  const {
    item,
    onReportAbuseFormVisibilityChange,
    onPostComment,
    onUpdateComment,
    onDeleteComment,
    loading,
    isReply,
    baseKey,
  } = props;

  const [showReply, setShowReply] = useState(false);
  const [viewReplies, setViewReplies] = useState(false);
  const [update, setUpdate] = useState(false);

  const { auth } = useAuth();
  const { uiLoaders } = useUIState();

  const updateLoading = baseKey && uiLoaders[`${baseKey}/${item.id}`];

  const onShowReplyChange = (visibility: boolean) => {
    setShowReply(visibility);
  };

  const onViewReplies = (visibility: boolean) => {
    setViewReplies(visibility);
  };

  const onUpdateVisibilityChange = (visibility: boolean) => {
    setUpdate(visibility);
  };

  const [replyForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  return (
    <motion.div
      exit={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      initial={{ opacity: 0, x: -100 }}
      className={`flex min-w-full ${isReply && '-mb-10'}`}
      // layout
    >
      <Comment
        className="w-full"
        actions={
          !showReply
            ? [
                <div key={item._id} className="flex justify-between min-w-full">
                  {!isEmpty(item.replies) && !isReply && (
                    <Button
                      type="link"
                      onClick={() => onViewReplies(!viewReplies)}
                    >
                      {!viewReplies
                        ? `View all comments (${item.replies?.length})`
                        : 'Collapse Replies'}
                    </Button>
                  )}
                  {!showReply && !isReply && (
                    <div className="ml-auto stm-text-color-primary">
                      <Button
                        data-testid="reply"
                        shape="round"
                        className="stm-comments-replyButton"
                        icon={
                          <span className="anticon">
                            <i className="ri-send-plane-2-line" />
                          </span>
                        }
                        onClick={() => onShowReplyChange(true)}
                      >
                        Reply
                      </Button>
                    </div>
                  )}
                </div>,
              ]
            : []
        }
        author={
          <span className="stm-fw-3 stm-fs-text-sm-4">
            <span className={'stm-heading-color'}>
              {item?.user?.firstName} {item?.user?.lastName}
            </span>
          </span>
        }
        avatar={
          <Avatar
            style={{
              color: 'var(--text-color-inverse)',
              backgroundColor: get(
                COLOR_LIST_ALPHA,
                upperCase(item?.user?.firstName?.charAt(0)) ?? 'A'
              ),
            }}
            src={get(item, 'user.avatar.url', null)}
          >
            {upperCase(item?.user?.firstName?.charAt(0))}
            {upperCase(item?.user?.lastName?.charAt(0))}
          </Avatar>
        }
        content={
          update ? (
            <Form
              initialValues={{ body: item.body }}
              form={updateForm}
              onFinish={(values: any) =>
                onUpdateComment(values, item._id, onUpdateVisibilityChange)
              }
            >
              <motion.div
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                className="flex space-x-2 w-full"
              >
                <Form.Item
                  name={'body'}
                  rules={[
                    { whitespace: true, message: 'Comment cannot be empty' },
                    { required: true, message: 'Missing comment!' },
                  ]}
                  className="w-full"
                  data-testid="comment-update-form"
                >
                  <CKEditorExtended
                    initialValue={updateForm.getFieldValue('body')}
                    config={{ placeholder: 'What would you like to say?...' }}
                  />
                </Form.Item>
              </motion.div>
              <div className="w-full flex justify-end">
                <Button
                  danger
                  type="link"
                  onClick={() => onUpdateVisibilityChange(false)}
                  role={'comment-cancel-button'}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={
                    <span className="anticon stm-text-color-primary">
                      <i className="ri-send-plane-2-line" />
                    </span>
                  }
                  loading={updateLoading || loading}
                  role={'comment-update-button'}
                >
                  Update Reply
                </Button>
              </div>
            </Form>
          ) : (
            <>
              <Paragraph
                ellipsis={{
                  rows: 3,
                  expandable: true,
                  symbol: 'show more',
                  onExpand: (e) => e?.stopPropagation(),
                }}
              >
                {HTMLParser.parse(item.body ?? '')}
              </Paragraph>
              <ConditionalRenderer condition={!isEmpty(item.attachments)}>
                {item?.attachments?.map((resource: any) => {
                  return (
                    <motion.div
                      key={item._id}
                      whileHover={{
                        x: 5,
                      }}
                    >
                      <Tooltip placement={'right'} title={'Download'}>
                        <motion.a
                          href={parseFilenameFromURL(resource)?.href}
                          whileHover={{
                            color: 'var(--accent)',
                          }}
                          whileTap={{ scale: 0.95 }}
                          style={{
                            color: 'var(--accent)',
                          }}
                        >
                          <span>
                            {parseFilenameFromURL(resource)?.filename ?? '--'}
                          </span>
                        </motion.a>
                      </Tooltip>
                    </motion.div>
                  );
                })}
              </ConditionalRenderer>
            </>
          )
        }
        datetime={`${formatDistanceToNow(
          new Date(item.createdAt ?? Date.now()),
          {
            addSuffix: true,
          }
        )} ${item.edited ? '(edited)' : ''}`}
      >
        {showReply && (
          <Form
            form={replyForm}
            onFinish={(values: any) =>
              onPostComment(values, item._id, onShowReplyChange)
            }
            data-testid="comment-reply-form"
          >
            <Form.Item
              name={'body'}
              rules={[
                { whitespace: true, message: 'Comment cannot be empty' },
                { required: true, message: 'Missing comment!' },
              ]}
            >
              <CKEditorExtended
                initialValue={replyForm.getFieldValue('body')}
                config={{ placeholder: 'What would you like to say?...' }}
              />
            </Form.Item>
            <div className="w-full flex justify-end">
              <Button
                danger
                type="link"
                onClick={() => onShowReplyChange(false)}
                data-testid={'hide-reply-button'}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                icon={
                  <span className="anticon stm-text-color-primary">
                    <i className="ri-send-plane-2-line" />
                  </span>
                }
                loading={loading}
                data-testid={'post-reply-button'}
              >
                Post Reply
              </Button>
            </div>
          </Form>
        )}
        {!isEmpty(item.replies) &&
          viewReplies &&
          item.replies?.map((reply: any) => {
            return (
              <CommentItem
                key={reply._id}
                onReportAbuseFormVisibilityChange={
                  onReportAbuseFormVisibilityChange
                }
                item={reply}
                onPostComment={onPostComment}
                onUpdateComment={onUpdateComment}
                onDeleteComment={onDeleteComment}
                loading={loading}
                isReply={true}
                baseKey={baseKey}
              />
            );
          })}
      </Comment>
      <Popover
        content={
          <div className="flex flex-col">
            {onReportAbuseFormVisibilityChange && (
              <Button
                type={'text'}
                onClick={() => onReportAbuseFormVisibilityChange(true)}
              >
                Report
              </Button>
            )}
            {item?.user?._id === auth?._id && (
              <>
                {/* <Button
                  type={'text'}
                  onClick={() => onUpdateVisibilityChange(true)}
                >
                  Edit
                </Button> */}
                <Popconfirm
                  title="Are you sure to delete this comment?"
                  onConfirm={() => onDeleteComment(item._id)}
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  okButtonProps={{ role: 'delete-comment-button' }}
                >
                  <Button
                    data-testid={'delete-comment-popconfirm-trigger'}
                    type={'text'}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        }
        trigger="click"
        placement="topRight"
      >
        <Button
          type="link"
          data-testid={'delete-comment-popover-trigger'}
          icon={
            <span className="anticon">
              <i className="ri-more-fill" />
            </span>
          }
        />
      </Popover>
    </motion.div>
  );
};
