import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension';
import customMiddleWares from './middlewares';
import appReducers from './reducers';
import {Action} from './types';

export const history = createBrowserHistory();

const persistConfig = {
  key: '@@stemuli',
  storage,
  whitelist: ['app'],
};

// const persistedReducer = persistReducer(persistConfig, appReducers(history));
// Implement a top-level app reducer to handle user logout and nullify state.

const rootReducer = (state: any, action: Action) => {
  if (action.type === 'RESET_APP_STATE') {
    // Re-initializes default UI state, to prevent revert of state
    // Todo: remove this when ui preference is initialize from the back-end
    state = {
      ui: Object.assign({}),
    };
  }
  return appReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// add the middleWares
const middleWares = [...customMiddleWares];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleWares.push(createLogger());
}

// apply the middleware
const middleware = composeWithDevTools(applyMiddleware(...middleWares));
// create the store

const store = createStore(persistedReducer, middleware);

const persistor = persistStore(store);

export { store, persistor };
