import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from 'src/_shared/constants';
import { apiRequest } from '../../../actions/app';
import { GOOGLE_IMPORT_GET_AUTH } from '../../../actions';

export const getGoogleAuth: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GOOGLE_IMPORT_GET_AUTH.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.googleClassRoom}/me`,
          key: key || 'getGoogleAuth',
          noErrorMessage: true,
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GOOGLE_IMPORT_GET_AUTH.SUCCESS,
              payload: data,
              key,
            });
          },
          onError: () => {
            dispatch({
              type: GOOGLE_IMPORT_GET_AUTH.SUCCESS,
              payload: null,
              key,
            });
          },
        })
      );
    }
  };
