export const MOCK_EXIT_TICKET = [
  {
    title: 'MTS - Factorization',
    standard: 'Standard',
    date: new Date(),
    description:
      'We will cover interaction in VR. You will discover different types of interaction, and how interaction works with different HMDs Iorem is  need all students to understand the implication of coming to school.',
    _id: '1',
    scores: [
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Miracle',
          lastName: 'Lipshutz',
          learnerGroup: {
            name: 'Does Not Meet',
            color: [235, 0, 0],
          },
        },
        score: 31,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Jocelyn',
          lastName: 'Mango',
          learnerGroup: {
            name: 'Meets',
            color: [21, 163, 54],
          },
        },
        score: 77,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Ashlynn',
          lastName: 'Korsgaard',
          learnerGroup: {
            name: 'Approaches',
            color: [245, 166, 51],
          },
        },
        score: 58,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Haylie',
          lastName: 'Herwitz',
          learnerGroup: {
            name: 'Masters',
            color: [30, 94, 255],
          },
        },
        score: 91,
      },
    ],
  },
  {
    title: 'MTS - Factorization',
    standard: 'Standard',
    date: new Date(),
    description:
      'We will cover interaction in VR. You will discover different types of interaction, and how interaction works with different HMDs Iorem is  need all students to understand the implication of coming to school.',
    _id: '2',
    scores: [
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Miracle',
          lastName: 'Lipshutz',
          learnerGroup: {
            name: 'Does Not Meet',
            color: [235, 0, 0],
          },
        },
        score: 31,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Jocelyn',
          lastName: 'Mango',
          learnerGroup: {
            name: 'Meets',
            color: [21, 163, 54],
          },
        },
        score: 77,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Ashlynn',
          lastName: 'Korsgaard',
          learnerGroup: {
            name: 'Approaches',
            color: [245, 166, 51],
          },
        },
        score: 58,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Haylie',
          lastName: 'Herwitz',
          learnerGroup: {
            name: 'Masters',
            color: [30, 94, 255],
          },
        },
        score: 91,
      },
    ],
  },
  {
    title: 'MTS - Factorization',
    standard: 'Standard',
    date: new Date(),
    description:
      'We will cover interaction in VR. You will discover different types of interaction, and how interaction works with different HMDs Iorem is  need all students to understand the implication of coming to school.',
    _id: '3',
    scores: [
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Miracle',
          lastName: 'Lipshutz',
          learnerGroup: {
            name: 'Does Not Meet',
            color: [235, 0, 0],
          },
        },
        score: 31,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Jocelyn',
          lastName: 'Mango',
          learnerGroup: {
            name: 'Meets',
            color: [21, 163, 54],
          },
        },
        score: 77,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Ashlynn',
          lastName: 'Korsgaard',
          learnerGroup: {
            name: 'Approaches',
            color: [245, 166, 51],
          },
        },
        score: 58,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Haylie',
          lastName: 'Herwitz',
          learnerGroup: {
            name: 'Masters',
            color: [30, 94, 255],
          },
        },
        score: 91,
      },
    ],
  },
  {
    title: 'MTS - Factorization',
    standard: 'Standard',
    date: new Date(),
    description:
      'We will cover interaction in VR. You will discover different types of interaction, and how interaction works with different HMDs Iorem is  need all students to understand the implication of coming to school.',
    _id: '4',
    scores: [
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Miracle',
          lastName: 'Lipshutz',
          learnerGroup: {
            name: 'Does Not Meet',
            color: [235, 0, 0],
          },
        },
        score: 31,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Jocelyn',
          lastName: 'Mango',
          learnerGroup: {
            name: 'Meets',
            color: [21, 163, 54],
          },
        },
        score: 77,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Ashlynn',
          lastName: 'Korsgaard',
          learnerGroup: {
            name: 'Approaches',
            color: [245, 166, 51],
          },
        },
        score: 58,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Haylie',
          lastName: 'Herwitz',
          learnerGroup: {
            name: 'Masters',
            color: [30, 94, 255],
          },
        },
        score: 91,
      },
    ],
  },
  {
    title: 'MTS - Factorization',
    standard: 'Standard',
    date: new Date(),
    description:
      'We will cover interaction in VR. You will discover different types of interaction, and how interaction works with different HMDs Iorem is  need all students to understand the implication of coming to school.',
    _id: '5',
    scores: [
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Miracle',
          lastName: 'Lipshutz',
          learnerGroup: {
            name: 'Does Not Meet',
            color: [235, 0, 0],
          },
        },
        score: 31,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Jocelyn',
          lastName: 'Mango',
          learnerGroup: {
            name: 'Meets',
            color: [21, 163, 54],
          },
        },
        score: 77,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Ashlynn',
          lastName: 'Korsgaard',
          learnerGroup: {
            name: 'Approaches',
            color: [245, 166, 51],
          },
        },
        score: 58,
      },
      {
        student: {
          profilePicture: 'https://source.unsplash.com/random?people',
          firstName: 'Haylie',
          lastName: 'Herwitz',
          learnerGroup: {
            name: 'Masters',
            color: [30, 94, 255],
          },
        },
        score: 91,
      },
    ],
  },
];
