import { add, sub } from 'date-fns';
import UUIDClass from 'uuidjs';
import { randInt } from '../utils';

const ASSIGNMENT_TYPES = [
  'Quiz',
  'Curriki',
  'Multi-choice',
  'Multi-choice',
  'Assessment',
];
const ASSIGNMENT_NAMES = [
  'Trigonometric Functions',
  'Variance & Standard Deviation',
  'Family tree',
  'How far is it from the goal line to the field goal post?',
  'Factorization and Polynomials',
  'Probability & Statistics',
  'Water cycle',
  'Pollution',
  'Calculus 1 (Derivatives)',
  'Logarithm',
  'Exponentials',
  'Craft',
];

export const MOCK_ASSIGNMENTS = Array.from(
  ASSIGNMENT_NAMES,
  (assignment: string) => {
    const deadline = add(new Date(), { days: randInt(1, 30) });
    return {
      id: UUIDClass.generate(),
      deadline,
      submitted: randInt(1, 30),
      pending: randInt(1, 30),
      missing: randInt(0, 30),
      name: assignment,
      assignedOn: sub(deadline, { days: randInt(1, 30) }),
      type: ASSIGNMENT_TYPES[randInt(0, ASSIGNMENT_TYPES.length - 1)],
    };
  }
);
