import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { CREATE_SYLLABUS_CATEGORY } from '../../../actions';

export const createSyllabusCategory: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === CREATE_SYLLABUS_CATEGORY.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: POST,
          url: ServicesURLs.syllabusCategory,
          key: key || 'createSyllabusCategory',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: CREATE_SYLLABUS_CATEGORY.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
