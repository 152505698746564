import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_GRADE_LEVELS = createActionType('GET_GRADE_LEVELS', 'SCHOOL');

export const getGradeLevels = (_id: string, option?: ActionOption) => ({
  type: GET_GRADE_LEVELS.START,
  meta: {
    _id,
    ...option,
  },
});
