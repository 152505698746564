import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_STANDARD = createActionType('UPDATE_STANDARD', 'STANDARD');

export const updateStandard = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_STANDARD.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
