import { apiRequest, GET_OVERVIEW_STATS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const getOverviewStats: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_OVERVIEW_STATS.START) {
      const { key, classId } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.classes}/${classId}/stats`,
          key: key || 'getOverviewStats',
          onSuccess: (data: any) => {
            dispatch({ type: GET_OVERVIEW_STATS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
