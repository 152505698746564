import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GOOGLE_IMPORT_GET_AUTH = createActionType(
  'GOOGLE_IMPORT_GET_AUTH',
  'GOOGLE IMPORT'
);

export const getGoogleAuth = (option?: ActionOption) => ({
  type: GOOGLE_IMPORT_GET_AUTH.START,
  meta: {
    ...option,
  },
});
