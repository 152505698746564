import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ATTENDANCE = createActionType('GET_ATTENDANCE', 'ATTENDANCE');

export const getAttendance = (_id: string, option?: ActionOption) => ({
  type: GET_ATTENDANCE.START,
  meta: {
    _id,
    ...option,
  },
});
