import { getWeekAtAGlance } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';

export type UseWeekAtAGlance = {
  key: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
  daysInWeek: Date[];
  week: Date | string;
};

export type UseWeekAtAGlanceReturnType = {
  findAll: (
    daysInWeek: Date[],
    week: Date | string,
    options?: ActionOption
  ) => void;
  weekAtAGlance: {
    [week: string]: ClassesNamespace.WeekAtAGlance[];
  };
};

export const useWeekAtAGlance = ({
  params,
  key,
  doFind,
  daysInWeek,
  week,
}: UseWeekAtAGlance): UseWeekAtAGlanceReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const { weekAtAGlance } = useSelector(({ overview }: RootState) => ({
    weekAtAGlance: overview?.weekAtAGlance[key],
  }));

  const findAll = (
    daysInWeek: Date[],
    week: Date | string,
    options?: ActionOption
  ) => {
    dispatch(
      getWeekAtAGlance(daysInWeek, week, { ...defaultOptions, ...options })
    );
  };

  useEffect(() => {
    if (doFind) {
      findAll(daysInWeek, week, defaultOptions);
    }
  }, []);

  return {
    findAll,
    weekAtAGlance,
  };
};
