import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const CREATE_QUESTION = createActionType('CREATE_QUESTION', 'QUESTION');

export const createQuestion = (payload: Record<string, any>, options?: ActionOption) => ({
  type: CREATE_QUESTION.START,
  meta: {
    payload,
    ...options
  }
});
