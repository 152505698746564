import {
  createPlaylist,
  deletePlaylist,
  deletePlaylistActivity,
  findPlaylist,
  getPlaylist,
  updatePlaylist,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectPlayLists } from '../../selectors/playlist';
import { useEffect } from 'react';

export type UsePlaylistProps = {
  key: string;
  userId?: string;
  alternateKey?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UsePlaylistType = {
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    _id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleFetch: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  handleDelete: (_id: string, options?: ActionOption) => void;
  handleDeletePlaylist: (
    playlistId: string,
    activityId: string,
    options?: ActionOption
  ) => void;
  playlists: LessonNameSpace.Playlist[];
  playlistList: Record<string, any>[];
  currentPlaylist: LessonNameSpace.Playlist;
};

export const usePlaylist = ({
  userId,
  params,
  key,
  doFind,
  alternateKey,
}: UsePlaylistProps): UsePlaylistType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    alternateKey,
  };

  const { currentPlaylist, playlists, playlistList } = useSelector(
    ({ playlist }: RootState) => ({
      currentPlaylist: playlist?.currentPlaylist[key],
      playlistList: selectPlayLists(playlist, key) || [],
      playlists: playlist?.playlists[key] || [],
    })
  );

  useEffect(() => {
    if (userId && doFind) {
      dispatch(
        findPlaylist({
          params,
          key,
        })
      );
    }
  }, [userId, doFind]);

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createPlaylist(payload, { ...defaultOptions, ...options }));
  };

  const handleUpdate = (
    id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(updatePlaylist(id, payload, { ...defaultOptions, ...options }));
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(deletePlaylist(id, { ...defaultOptions, ...options }));
  };

  const handleDeletePlaylist = (
    playlistId: string,
    activityId: string,
    options?: ActionOption
  ) => {
    dispatch(
      deletePlaylistActivity(playlistId, activityId, {
        ...defaultOptions,
        ...options,
      })
    );
  };

  const handleFetch = (options?: ActionOption) => {
    dispatch(findPlaylist({ ...defaultOptions, ...options }));
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getPlaylist(id, { ...defaultOptions, ...options }));
  };

  return {
    handleCreate,
    handleUpdate,
    handleDelete,
    handleDeletePlaylist,
    handleFetch,
    handleFetchOne,
    currentPlaylist,
    playlistList,
    playlists,
  };
};
