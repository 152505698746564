import { Action } from '../../types';
import {
  CREATE_GRADE,
  DELETE_GRADE,
  FIND_GRADE,
  GET_GRADE,
  UPDATE_GRADE,
} from '../../actions';
import { arrayToById } from '../../../_shared/utils';
import { SET_CURRENT_GRADE } from '../../actions/grades/setCurrentGrade';
import { get, pick } from 'lodash';

export interface GradeState {
  grades: Record<string, ClassesNamespace.Grade[]> | any;
  gradesById: Record<string, Record<string, ClassesNamespace.Grade>> | any;
  currentGrade: ClassesNamespace.Grade | any;
}

export const GradeStateDefaultState: GradeState = {
  grades: {},
  gradesById: {},
  currentGrade: {},
};

const gradeReducer = (state = GradeStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CURRENT_GRADE: {
      return {
        ...state,
        currentGrade: {
          ...state.currentGrade,
          [action.key]: payload,
        },
      };
    }
    case FIND_GRADE.SUCCESS: {
      // Map by ID as student Id instead
      const byIdFind = arrayToById(payload || [], 'student._id');
      return Object.assign({}, state, {
        gradesById: {
          ...state.gradesById,
          [action.key]: byIdFind,
        },
        grades: {
          ...state.grades,
          [action.key]: Object.values(byIdFind),
        },
        currentGrade: {
          ...state.currentGrade,
          [action.key]: {},
        },
      });
    }
    case GET_GRADE.SUCCESS: {
      return {
        ...state,
        currentGrade: {
          ...state.currentGrade,
          [action.key]: Object.assign({}, state.currentGrade[action.key], {
            grade: Object.assign(
              {},
              payload?.length < 0
                ? pick(get(state.currentGrade[action.key], 'grade', {}), [
                    'student',
                    'classActivity',
                  ])
                : get(state.currentGrade[action.key], 'grade', {}),
              payload?.[0]
            ),
          }),
        },
      };
    }
    case CREATE_GRADE.SUCCESS:
    case UPDATE_GRADE.SUCCESS: {
      // Map by ID as student Id instead
      const studentId = get(payload, 'student._id');
      const byIdCreate = {
        ...state.gradesById[action.key],
        [studentId]: Object.assign(
          {},
          get(state, ['gradesById', action.key, studentId], {}),
          { [get(payload, 'classActivity')]: get(payload, 'pointScore') } // Appends the pair [classActivityId:pointScore] to the grade-book
        ),
      };
      return Object.assign({}, state, {
        gradesById: {
          ...state.gradesById,
          [action.key]: byIdCreate,
        },
        grades: {
          ...state.grades,
          [action.key]: Object.values(byIdCreate),
        },
        currentGrade: {
          ...state.currentGrade,
          [action.key]: Object.assign({}, state.currentGrade[action.key], {
            grade: payload,
          }),
        },
      });
    }
    case DELETE_GRADE.SUCCESS: {
      const byId = { ...state.gradesById[action.key] };
      delete byId[payload._id];
      return Object.assign({}, state, {
        gradesById: {
          ...state.gradesById,
          [action.key]: byId,
        },
        grades: {
          ...state.grades,
          [action.key]: Object.values(byId),
        },
        currentGrade: null,
      });
    }
    default:
      return state;
  }
};

export default gradeReducer;
