import { ReactNode, ReactNodeArray } from 'react';
import './form-list-container.scss';
import ScrollBar from 'react-perfect-scrollbar';

interface FormListContainerProps {
  children: ReactNode | ReactNodeArray;
}
export const FormListContainer = (props: FormListContainerProps) => {
  const { children } = props;
  return (
    <div className="stm-create-lesson-form-list-container">
      <ScrollBar>
        <div className={'stm-create-lesson-form-list-container-inner'}>
          {children}
        </div>
      </ScrollBar>
    </div>
  );
};
