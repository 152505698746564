import { createSelector } from 'reselect';
import { QuestionState } from '../../redux/reducers/question';

const questions = (question: QuestionState, key: string) =>
  question?.questions[key] || [];

export const selectQuestionList = createSelector(
  questions,
  (questions: LessonNameSpace.Question[]) => {
    return questions.map(({ _id, question }) => ({ _id, title: question }));
  }
);
