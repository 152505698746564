import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {PUT, Services, ServicesURLs} from '../../../../_shared/constants';
import {apiRequest} from '../../../actions/app';
import {UPDATE_QUESTION} from '../../../actions/question/updateQuestion';

export const updateQuestion: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === UPDATE_QUESTION.START) {
          const {key, _id, ...rest} = action.meta;
          dispatch(
            apiRequest(
              {
                service: Services.class,
                method: PUT,
                url: `${ServicesURLs.questions}/${_id}`,
                key: key || 'updateQuestion',
                ...rest,
                onSuccess: (data: any) => {
                  dispatch({type: UPDATE_QUESTION.SUCCESS, payload: data, key});
                }
              }
            )
          );
        }
      };
