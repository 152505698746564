import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_GRADE = createActionType('CREATE_GRADE', 'GRADE');

export const createGrade = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_GRADE.START,
  meta: {
    payload,
    ...options,
  },
});
