import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_UNIT = createActionType('GET_UNIT', 'UNIT');

export const getUnit = (_id: string, option?: ActionOption) => ({
  type: GET_UNIT.START,
  meta: {
    ...option,
    _id,
  },
});
