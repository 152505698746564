import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {DELETE, Services, ServicesURLs} from '../../../../_shared/constants';
import {apiRequest} from '../../../actions/app';
import {DELETE_LESSON} from '../../../actions';

export const deleteLesson: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === DELETE_LESSON.START) {
          const {key, _id, ...rest} = action.meta;
          dispatch(
            apiRequest(
              {
                service: Services.class,
                method: DELETE,
                url: `${ServicesURLs.lessons}/${_id}`,
                key: key || 'deleteLesson',
                ...rest,
                onSuccess: (data: any) => {
                  dispatch({type: DELETE_LESSON.SUCCESS, payload: data, key});
                }
              }
            )
          );
        }
      };
