import { createActionString } from '../../../../_shared/utils';

export const SET_CURRENT_ASSIGNMENT = createActionString(
  'SET_CURRENT_ASSIGNMENT',
  'ASSIGNMENTS'
);

export const setCurrentAssignment = (
  assignment: ClassesNamespace.Assignments | object,
  key: string
) => ({
  type: SET_CURRENT_ASSIGNMENT,
  payload: assignment,
  key,
});
