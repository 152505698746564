import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_EXIT_TICKET_SUBMISSIONS = createActionType(
  'FIND_EXIT_TICKET_SUBMISSIONS',
  'ACTIVITY'
);

export const findExitTicketSubmissions = (
  activityId: string,
  option?: ActionOption
) => ({
  type: FIND_EXIT_TICKET_SUBMISSIONS.START,
  meta: {
    activityId,
    ...option,
  },
});
