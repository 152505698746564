import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_PLAYLIST = createActionType('UPDATE_PLAYLIST', 'PLAYLIST');

export const updatePlaylist = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_PLAYLIST.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
