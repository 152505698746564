import { accessPrompt } from './accessPrompt';
import { getCourses } from './getCourses';
import { getCourseWorks } from './getCourseWorks';
import { syncCourseWork } from './syncCourseWork';
import { getGoogleAuth } from './getGoogleAuth';

export default [
  accessPrompt,
  getCourses,
  getCourseWorks,
  syncCourseWork,
  getGoogleAuth,
];
