import { apiRequest, CREATE_NOTIFICATIONS_CHANNEL } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services } from '../../../../_shared/constants';

export const createNotificationChannel: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === CREATE_NOTIFICATIONS_CHANNEL.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: POST,
          url: `/devices`,
          key: key || 'createNotificationChannel',
          ...rest,
          noSuccessMessage: true,
          onSuccess: (data: any) => {
            dispatch({
              type: CREATE_NOTIFICATIONS_CHANNEL.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
