import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ANNOUNCEMENT_COMMENTS = createActionType(
  'FIND_ANNOUNCEMENT_COMMENTS',
  'ANNOUNCEMENTS'
);

export const findAnnouncementComments = (
  announcementId: string,
  option?: ActionOption
) => ({
  type: FIND_ANNOUNCEMENT_COMMENTS.START,
  meta: {
    ...option,
    announcementId,
  },
});
