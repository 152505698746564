import { createSelector } from 'reselect';
import { LessonState } from '../../redux/reducers/lesson';
import { capitalize } from 'lodash';

const lessonList = (lesson: LessonState, key: string) =>
  lesson?.lessons[key] || [];

const todayLesson = (lesson: LessonState, key: string) =>
  lesson?.todayLesson[key] || [];

export const selectTodayLesson = createSelector(
  todayLesson,
  (todayLesson: LessonNameSpace.Lesson[]) => todayLesson
);

export const selectLessonList = createSelector(lessonList, (lessonList) => {
  return lessonList?.map(({ _id, title }: any) => ({
    value: _id,
    label: capitalize(title),
  }));
});
