import { Action } from '../../types';
import { GET_MASTERY } from '../../actions';

export interface MasteryState {
  masteries: Record<string, any>;
  masteriesById: Record<string, any>;
}

export const MasteryDefaultState: MasteryState = {
  masteries: {},
  masteriesById: {},
};

const masteryReducer = (state = MasteryDefaultState, action: Action) => {
  const { payload, key } = action;
  switch (action.type) {
    case GET_MASTERY.SUCCESS: {
      return Object.assign({}, state, {
        masteries: {
          ...state.masteries,
          [key]: Object.values(payload),
        },
        masteriesById: {
          ...state.masteriesById,
          [key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default masteryReducer;
