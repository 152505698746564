import { motion } from 'framer-motion';
import { get, isEmpty } from 'lodash';

import { FormPlaylistListItem } from './FormPlaylistListItem';
import { Empty } from 'antd';
import { ActivityTypeToName } from '../../playlist/Activity/ActivityType';

interface FormPlaylistListProps {
  items: Array<any>;
  playlist: Record<string, any>;
}
export const FormPlaylistList = (props: FormPlaylistListProps) => {
  const { items, playlist } = props;
  return (
    <motion.div className={'stm-create-lesson-form-list stm-pa-5'}>
      {!isEmpty(items) &&
        items.map((item) => {
          return (
            <FormPlaylistListItem
              key={item._id}
              columns={[
                item.title,
                get(ActivityTypeToName, item.type),
                [],
                false,
              ]}
              onGradedActivity={() => null}
              itemId={item.id}
              playlistId={playlist._id}
            />
          );
        })}
      {isEmpty(items) && (
        <div className="stm-pt-10">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div className="stm-flex stm-items-center stm-justify-center stm-flex-col w-100">
                <span className="stm-mt-5 stm-pt-5 stm-fs-text-1 stm-mb-5">
                  No activity added.
                </span>
              </div>
            }
          />
        </div>
      )}
    </motion.div>
  );
};
