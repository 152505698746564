import { createGrade, findGrade, getGrade } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import { setCurrentGrade } from '../../redux/actions/grades/setCurrentGrade';

export type GradeProps = {
  key: string;
  options?: ActionOption;
  classId?: string;
  doFind?: boolean;
};

export type GradeType = {
  findAll: (classId: string, options?: ActionOption) => void;
  createScore: (payload: Record<string, any>, options?: ActionOption) => void;
  getScore: (
    studentId: string,
    classActivityId: string,
    options?: ActionOption
  ) => void;
  setCurrent: (payload: Record<string, any>, key: string) => void;
  grades: ClassesNamespace.Grade[];
  gradesById: Record<string, ClassesNamespace.Grade>;
  currentGrade: ClassesNamespace.Grade;
};

export const useGrade = ({
  options,
  key,
  classId,
  doFind = true,
}: GradeProps): GradeType => {
  const dispatch = useDispatch();

  const { currentGrade, grades, gradesById } = useSelector(
    ({ grades }: RootState) => ({
      currentGrade: grades?.currentGrade[key],
      grades: grades?.grades[key] || [],
      gradesById: grades?.gradesById[key] ?? {},
    })
  );

  const defaultOptions: ActionOption = {
    key,
    ...options,
  };

  const findAll = (classId: string, options?: ActionOption) => {
    dispatch(findGrade(classId, { ...defaultOptions, ...options }));
  };

  const setCurrent = (payload: Record<string, any>, key: string) => {
    dispatch(setCurrentGrade(payload, key));
  };

  const getScore = (
    studentId: string,
    classActivityId: string,
    options?: ActionOption
  ) => {
    dispatch(getGrade(studentId, classActivityId, options));
  };

  const createScore = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createGrade(payload, options));
  };

  useEffect(() => {
    if (classId && doFind) {
      findAll(classId, defaultOptions);
    }
  }, [classId]);

  return {
    findAll,
    setCurrent,
    getScore,
    createScore,
    currentGrade,
    grades,
    gradesById,
  };
};
