import { useEffect } from 'react';
import { findClassMember, getUserClasses } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import {
  selectClassList,
  selectUserClasses,
  selectTeacherClass,
  selectMembersClasses,
  selectMemberClassList,
} from '../../selectors';

export type UseClassMemberProps = {
  key: string;
  isPrincipal?: boolean;
  userId?: string;
  params?: Record<string, any>;
  doFind?: boolean;
};

export type UseClassMemberType = {
  classMembers: LessonNameSpace.ClassMember[];
  classList: Record<string, any>[];
  teacherClass: {
    _id: string;
    title: string;
    studentsCount: number | undefined;
  }[];
  classesById: Record<string, LessonNameSpace.ClassMember>;
  userClasses: LessonNameSpace.Class[];
  getClassById: (classId: string) => LessonNameSpace.Class | undefined;
  getClassMembers: (options?: ActionOption & {searchURLPath?: string}, userId?: string) => void;
};

export const useClassMember = ({
  key,
  isPrincipal,
  userId,
  params = {},
  doFind = true,
}: UseClassMemberProps): UseClassMemberType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params: Object.assign(
      {},
      {
        user: userId,
        population: JSON.stringify(['user', 'class']),
      },
      params
    ),
  };
  const { classList, classMembers, userClasses, classesById, teacherClass } =
    useSelector(({ classMember }: RootState) => ({
      classMember,
      isPrincipal,
      userClasses: !isPrincipal
        ? selectMembersClasses(classMember, key)
        : selectUserClasses(classMember, key),
      classList: !isPrincipal
        ? selectMemberClassList(classMember, key)
        : selectClassList(classMember, key),
      classMembers: classMember?.classMembers[key],
      classesById: classMember?.classMembersById[key],
      teacherClass: selectTeacherClass(classMember, key),
    }));

  useEffect(() => {
    if (doFind) {
      getClassMembers({ ...defaultOptions }, userId);
    }
  }, [userId]);

  const getClassById = (classId: string) => {
    return userClasses.find((c: LessonNameSpace.Class) => c._id === classId);
  };

  const getClassMembers = (options: ActionOption & {searchURLPath?: string} = {}, userId?: string) => {
    if (isPrincipal && userId) {
      dispatch(
        getUserClasses(userId, {
          ...defaultOptions,
          ...options,
        })
      );
    } else {
      dispatch(
        findClassMember({
          ...defaultOptions,
          ...options,
        })
      );
    }
  };

  return {
    userClasses,
    classMembers,
    classList,
    classesById,
    getClassById,
    getClassMembers,
    teacherClass,
  };
};
