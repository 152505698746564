import UUIDClass from 'uuidjs';

export const MEMBERS_MOCK = [
  {
    sisid: 'SD-10292',
    firstName: 'Dulce',
    lastName: 'Press',
    email: 'Dulce_press@gmail.com',
    graduationYear: 2024,
    grade: 'Grade 4',
  },
  {
    sisid: 'SD-10293',
    firstName: 'Chance',
    lastName: 'Botosh',
    email: 'Chance_Botosh@gmail.com',
    graduationYear: 2021,
    grade: 'Grade 4',
  },
  {
    sisid: 'SD-10294',
    firstName: 'James',
    lastName: 'Herwitz',
    email: 'James_Herwitz@gmail.com',
    graduationYear: 2024,
    grade: 'Grade 4',
  },
  {
    sisid: 'SD-10295',
    firstName: 'Marcus',
    lastName: 'Gouse',
    email: 'Marcus_Gouse@gmail.com',
    graduationYear: 2024,
    grade: 'Grade 4',
  },
  {
    sisid: 'SD-10296',
    firstName: 'Abram',
    lastName: 'Ekstrom Bothman',
    email: 'Abram_Ekstrom_Bothman@gmail.com',
    graduationYear: 2023,
    grade: 'Grade 4',
  },
];

export const MOCK_TEACHER_PROFILE = {
  _id: UUIDClass.generate(),
  firstName: 'Kierra',
  lastName: 'Culhane',
  grade: 'Grade 5',
  bio: 'I am a Grade 2 teacher with the understanding that the present situation is a cool',
  email: 'emmanuel@lifeline.com',
  phoneNumber: '972 809-1123',
  classes: [
    {
      _id: UUIDClass.generate(),
      title: 'Math 202',
      time: new Date(),
      studentCount: 200000,
    },
    {
      _id: UUIDClass.generate(),
      title: 'Math 302',
      time: new Date(),
      studentCount: 200000,
    },
    {
      _id: UUIDClass.generate(),
      title: 'Math 201',
      time: new Date(),
      studentCount: 200000,
    },
    {
      _id: UUIDClass.generate(),
      title: 'Math 102',
      time: new Date(),
      studentCount: 200000,
    },
    {
      _id: UUIDClass.generate(),
      title: 'Math 101',
      time: new Date(),
      studentCount: 200000,
    },
    {
      _id: UUIDClass.generate(),
      title: 'Math 203',
      time: new Date(),
      studentCount: 200000,
    },
  ],
};
