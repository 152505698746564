import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const REMOVE_DEVICE_FROM_CHANNEL = createActionType(
  'REMOVE_DEVICE_FROM_CHANNEL',
  'NOTIFICATION'
);

export const removeDeviceFromChannel = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: REMOVE_DEVICE_FROM_CHANNEL.START,
  meta: {
    payload,
    ...options,
  },
});
