import { createSelector } from 'reselect';
import { ClassMemberState } from '../../redux/reducers/classMember';

const classMembers = (classMember: ClassMemberState, key: string) =>
  classMember?.classMembers[key] || [];

const userClasses = (classMember: ClassMemberState, key: string) =>
  classMember?.userClasses[key] || [];

export const selectMembersClasses = createSelector(
  classMembers,
  (classMembers: LessonNameSpace.ClassMember[]) => {
    return classMembers.map((c) => c.class).filter((u) => !!u);
  }
);

export const selectUserClasses = createSelector(
  userClasses,
  (classes: LessonNameSpace.Class[]) => {
    return classes;
  }
);

export const selectMemberClassList = createSelector(
  selectMembersClasses,
  (userClasses: LessonNameSpace.Class[]) => {
    return userClasses.map(({ _id, title }) => ({ _id, title }));
  }
);

export const selectClassList = createSelector(
  selectUserClasses,
  (userClasses: LessonNameSpace.Class[]) => {
    return userClasses.map(({ _id, title }) => ({ _id, title }));
  }
);

export const selectTeacherClass = createSelector(
  classMembers,
  (classMembers: LessonNameSpace.ClassMember[]) => {
    return classMembers.map((classMember) => {
      return {
        _id: classMember?.class?._id,
        title: classMember?.class?.title,
        studentsCount: classMember?.class?.studentsCount,
      };
    });
  }
);
