import { init as initializeSentry } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import { NetworkOverlay } from './app/layout/network-overlay';
import { StemuliPreload } from './app/layout/preload';
import { AuthProvider, ClassProvider, StandardProvider } from './contexts';
import i18n from './i18n';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';

initializeSentry({
  dsn: process.env.REACT_APP_SENTRY_DNS_URL,
  attachStacktrace: ['production', 'development'].includes(
    process.env.NODE_ENV
  ),
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NetworkOverlay>
            <AuthProvider>
              <ClassProvider>
                <StandardProvider>
                  <StemuliPreload>
                    <App />
                  </StemuliPreload>
                </StandardProvider>
              </ClassProvider>
            </AuthProvider>
          </NetworkOverlay>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
