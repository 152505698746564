import { Button, Input, Modal, Space } from 'antd';
import { getToken, isIFrame } from '../../../../../../_shared/utils';
import './curriki.scss';
import { useEffect } from 'react';

interface CurrikiExternalActivityProps {
  onVisibilityChange: (visibility: boolean) => void;
  visibility: boolean;
  setCurrikiURL: (url: string) => void;
}
export const CurrikiExternalActivity = (
  props: CurrikiExternalActivityProps
) => {
  const { visibility, onVisibilityChange, setCurrikiURL } = props;

  const URL = `${process.env.REACT_APP_BASEURL}/classroom/v1/class-activities/lti/curriki`;
  const onListen = (e: MessageEvent<any>) => {
    // && window.origin === e.origin
    if (typeof e.data === 'string') {
      try {
        const data = JSON.parse(e.data);
        // const type = data.type;
        const value = data.value; // Can have string or object
        switch (data.type) {
          case 'success':
            //  value.url => the url of the activity. Set this as the url for your external resource and close this modal window
            setCurrikiURL(value.url);
            break;
          case 'event':
            if (value === 'page-loaded') {
              // document.getElementById('output-data').innerHTML = 'Creating request and redirecting to Curriki';
            }
            break;
        }
      } catch (e) {
        console.error(`Curriki: ${e}`);
      }
    }
  };

  const onRefresh = () => {
    const iframe = document.querySelector('#stm-playlist-curriki-iframe');
    if (isIFrame(iframe)) {
      iframe.src = '';
      iframe.src = `${URL}?token=${token}`;
    }
  };

  useEffect(() => {
    window.addEventListener('message', onListen);
    const iframe = document.querySelector('#stm-playlist-curriki-iframe');
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.addEventListener('load', (a) => {
        console.log(a);
      });
    }

    return () => {
      window.removeEventListener('message', onListen);
    };
  }, []);

  const token = getToken();
  return (
    <Modal
      title="Curriki Activity"
      visible={visibility}
      onCancel={() => onVisibilityChange(false)}
      width={'98vw'}
      bodyStyle={{
        height: 'calc(98vh - 80px)',
        padding: 0,
        paddingBottom: 10,
        position: 'relative',
        overflow: 'hidden',
        background: '#fff',
      }}
      centered
      closable
      footer={
        <div className="stm-flex stm-items-center w-100 stm-justify-end" />
      }
      destroyOnClose
      keyboard={false}
    >
      <div className="stm-flex stm-items-center stm-iframe-tabs-container w-100 stm-pv-5 stm-ph-5">
        <Space size={5}>
          <Button
            shape={'circle'}
            size={'small'}
            type={'text'}
            title={'Reload this page'}
            icon={
              <span className="anticon">
                <i
                  className="ri-restart-line stm-heading-color"
                  style={{ fontSize: 18 }}
                />
              </span>
            }
            onClick={onRefresh}
          />
          <div className="stm-flex stm-iframe-url-tab stm-pa-1">
            <Input
              size={'small'}
              className={'w-100 stm-fs-text-1'}
              bordered={false}
              value={`${URL}?token=${token}`}
              readOnly={true}
            />
          </div>
        </Space>
      </div>
      <iframe
        src={`${URL}?token=${token}`}
        className={'stm-playlist-curriki-iframe'}
        id={'stm-playlist-curriki-iframe'}
        // sandbox={}
      />
    </Modal>
  );
};
