import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CustomUploadAdapterPlugin } from '../../../_shared/utils';
import ClassicEditor from '@stemuli/ckeditor5-build-classic';
import { isFunction } from 'lodash';
import { useRef } from 'react';

interface CKEditorExtendedProps {
  onBlur?: ((event: any, editor: any) => void) | undefined;
  config?: Record<string, any>;
  onChange?: (value: string) => void;
  initialValue?: string;
  onReady?: (editor: any) => void;
  editorKey?: string;
  otherProps?: Record<string, any>;
}
export const CKEditorExtended = (props: CKEditorExtendedProps) => {
  const {
    onChange,
    initialValue,
    config = {},
    onReady,
    onBlur,
    editorKey,
    otherProps = {},
  } = props;

  // Value is stored in a ref, to prevent reinitializing, since CKEditor data props also updates the editor
  const initialValueReference = useRef<string | undefined>(initialValue ?? '');

  return (
    <CKEditor
      {...(editorKey ? { key: editorKey } : {})}
      {...otherProps}
      // key={editorKey ?? UUID.generate()}
      config={{
        extraPlugins: [CustomUploadAdapterPlugin as any],
        ...config,
      }}
      editor={ClassicEditor}
      onReady={onReady}
      onBlur={onBlur}
      data={initialValueReference.current}
      onChange={(_, _editor) => {
        const data = _editor.getData();
        if (data && data.trim() !== '<p><br></p>') {
          if (onChange && isFunction(onChange)) {
            onChange(data);
            return;
          }
        }

        if (onChange && isFunction(onChange)) {
          onChange(undefined);
          return;
        }
      }}
    />
  );
};
