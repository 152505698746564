import UUID from 'uuidjs';

export const MOCK_LEARNER_GROUPS = [
  {
    color: [255, 0, 0],
    scale: {
      min: 0,
      max: 40,
    },
    school: '60a656f79c4d3d6ffe69b2ab',
    name: 'Does Not Meet',
    code: '304040303',
    students: [
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
    ],
  },
  {
    color: [255, 177, 25],
    scale: {
      min: 40,
      max: 60,
    },
    school: '60a656f79c4d3d6ffe69b2ab',
    name: 'Approaches',
    code: '304040302',
    students: [
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
    ],
  },
  {
    color: [31, 192, 166],
    scale: {
      min: 71,
      max: 100,
    },
    school: '60a656f79c4d3d6ffe69b2ab',
    name: 'Meets',
    code: '304040301',
    students: [
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
    ],
  },
  {
    color: [71, 101, 255],
    scale: {
      min: 71,
      max: 100,
    },
    school: '60a656f79c4d3d6ffe69b2ab',
    name: 'Mastery',
    code: '304040301',
    students: [
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
      {
        name: 'Johny Test',
        profilePicture: 'https://source.unsplash.com/random?people',
        _id: UUID.generate(),
      },
    ],
  },
];
