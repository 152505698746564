export const ordinal = (value: number | string): string => {
  // 1st, 2nd, 3rd, 4th, etc.
  const j = parseInt(value as string, 10) % 10;
  const k = parseInt(value as string, 10) % 100;
  switch (true) {
    case j === 1 && k !== 11:
      return value + 'st';
    case j === 2 && k !== 12:
      return value + 'nd';
    case j === 3 && k !== 13:
      return value + 'rd';
    default:
      return value + 'th';
  }
};
