import { ReactNode, ReactNodeArray } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { motion } from 'framer-motion';

interface ActivityContainerProps {
  children: ReactNode | ReactNodeArray;
}
export const ActivityContainer = (props: ActivityContainerProps) => {
  const { children } = props;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="stm-create-lesson-activity-container"
    >
      <ScrollBar>
        <div className="stm-pa-5">{children}</div>
      </ScrollBar>
    </motion.div>
  );
};
