import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_ASSIGNMENT = createActionType(
  'CREATE_ASSIGNMENT',
  'ASSIGNMENTS'
);

export const createAssignment = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_ASSIGNMENT.START,
  meta: {
    ...options,
    payload,
  },
});
