import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { MODIFY_ASSIGNMENT_SUBMISSION } from '../../../actions';

export const modifyAssignmentSubmission: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === MODIFY_ASSIGNMENT_SUBMISSION.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: POST,
          url: `${ServicesURLs.assigmentSubmissions}/modify`,
          key: key || 'modifyAssignmentSubmission',
          successMessage: 'Submission updated successfully',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: MODIFY_ASSIGNMENT_SUBMISSION.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
