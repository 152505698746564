import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_SCHOOL_CLASSES = createActionType(
  'GET_SCHOOL_CLASSES',
  'SCHOOL'
);

export const getSchoolClasses = (option?: ActionOption) => ({
  type: GET_SCHOOL_CLASSES.START,
  meta: {
    ...option,
  },
});
