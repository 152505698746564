import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_MEMBERS = createActionType('FIND_MEMBERS', 'MEMBERS');

export const findMembers = (options?: ActionOption) => ({
  type: FIND_MEMBERS.START,
  meta: {
    ...options,
  },
});
