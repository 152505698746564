import { omit } from 'lodash';
import { createSelector } from 'reselect';
import { RecordingState } from '../../redux/reducers/recording';

const recordings = (recording: RecordingState, key: string) =>
  recording?.recordings[key] || [];

export const selectRecordingList = createSelector(
  recordings,
  (recordings: Record<string, any>[]) =>
    recordings.map((recording) => {
      const videoUrl = recording?.recording?.url;
      const lessonVideo = recording?.lesson?.videos.map(
        (video: Record<string, any>) => {
          return {
            ...omit(video, ['classes']),
          };
        }
      );
      const videoIsMain = recording?.lesson?.videos.some(
        ({ url }: { url: string }) => url === videoUrl
      );
      const resourceIsMain = recording?.lesson?.resources.some(
        ({ url }: { url: string }) => url === videoUrl
      );

      return {
        _id: recording?._id,
        videoIsMain,
        resourceIsMain,
        videoUrl,
        lessonTitle: recording?.lesson?.title,
        lessonId: recording?.lesson?._id,
        lessonVideo,
        lessonResource: recording?.lesson?.resources,
        startDate: recording?.lesson?.startDate,
          ...recording
      };
    })
);
