import { Col, FormInstance, Row } from 'antd';
import { LinkFormItem } from '../../../../_shared/Links/Link';
import { CurrikiExternalActivity } from './Curriki';
import { useState } from 'react';

interface ActivityCurrikiProps {
  form?: FormInstance;
}

export const ActivityCurriki = (props: ActivityCurrikiProps) => {
  const { form } = props;

  const [currikiVisibility, setVisibility] = useState(false);

  const onVisibilityChange = (visibility: boolean) => {
    setVisibility(visibility);
  };

  const setCurrikiURL = (url: string) => {
    if (form) {
      form.setFields([
        { name: ['activity', 'activityUrl'], value: url, touched: true },
      ]);
      onVisibilityChange(false);
    }
  };

  return (
    <>
      <CurrikiExternalActivity
        onVisibilityChange={onVisibilityChange}
        visibility={currikiVisibility}
        setCurrikiURL={setCurrikiURL}
      />
      <Row gutter={[15, 30]}>
        <Col span={24}>
          <LinkFormItem
            formItemProps={{
              name: ['activity', 'activityUrl'],
              label: 'Curriki URL',
            }}
            inputProps={{
              onClick: () => onVisibilityChange(true),
              readOnly: true,
              placeholder: 'Click here to add a Curriki link',
            }}
          />
        </Col>
      </Row>
    </>
  );
};
