import PlayListActivityListItem from '../ActivityListItem';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'remixicon/fonts/remixicon.css';
import '../../../../../app/assets/styles/stm-theme.min.css';
import '../../../../../app/App.scss';
import { ActivityType } from 'src/_shared/constants';

interface ActivityListProps {
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
  sideBarItems: {
    title: string;
    description: string;
    icon: string;
    activityType: ActivityType;
  }[];
}

const ActivityList = (props: ActivityListProps) => {
  const { onActivityItemSelected, sideBarItems } = props;
  return (
    <div className={'stm-pa-5'} data-testid="activity-list">
      {(sideBarItems ?? []).map((item, i) => {
        return (
          <div data-testid={`playlist-activity-list-item-${i}`} key={i}>
            <PlayListActivityListItem
              item={item}
              onActivityItemSelected={onActivityItemSelected}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ActivityList;
