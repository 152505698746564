import { apiRequest, GET_DUE_ACTIVITIES } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const getDueActivities: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_DUE_ACTIVITIES.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.activities}/statistics`,
          // url: `${ServicesURLs.assignments}/${classId}`,
          key: key || 'getDueActivities',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: GET_DUE_ACTIVITIES.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
