import { FormInstance } from 'antd';
import { Question, QuestionType } from '../../../../_shared';
import { useStandard } from '../../../../../hooks';
import { uniqBy } from 'lodash';

interface ActivityQuestionProps {
  currentLesson?: LessonNameSpace.Lesson;
  form: FormInstance;
}

export const ActivityQuestion = (props: ActivityQuestionProps) => {
  const { form, currentLesson } = props;
  const { collations } = useStandard({ doFind: false, key: 'create-lesson' });
  return (
    <>
      <Question
        standards={uniqBy(
          (currentLesson?.standards ?? []).concat(collations ?? []),
          '_id'
        )}
        form={form}
        defaultQuestionType={QuestionType.SingleAnswer}
      />
    </>
  );
};
