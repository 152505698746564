import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { GET_SYLLABUS_CATEGORY } from '../../../actions';

export const getSyllabusCategory: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_SYLLABUS_CATEGORY.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.syllabusCategory}/${_id}`,
          key: key || 'getSyllabusCategory',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GET_SYLLABUS_CATEGORY.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
