import {
  createActivity,
  deleteActivity,
  findActivity,
  getActivity,
  updateActivity,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectActivityList } from '../../selectors/activity';
import { useEffect } from 'react';

export type ActivityProps = {
  key: string;
  userId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type ActivityType = {
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    _id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleFetch: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  handleDelete: (_id: string, options?: ActionOption) => void;
  activities: LessonNameSpace.Activity[];
  activityList: { value: string; label: string }[];
  currentActivity: LessonNameSpace.Activity;
};

export const useActivity = ({
  userId,
  params,
  key,
  doFind,
}: ActivityProps): ActivityType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const { currentActivity, activities, activityList } = useSelector(
    ({ activity }: RootState) => ({
      currentActivity: activity?.currentActivity[key],
      activityList: selectActivityList(activity, key) || [],
      activities: activity?.activities[key] || [],
    })
  );

  useEffect(() => {
    if (userId && doFind) {
      dispatch(
        findActivity({
          params,
          key,
        })
      );
    }
  }, [userId, doFind]);

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createActivity(payload, { ...defaultOptions, ...options }));
  };

  const handleUpdate = (
    id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(updateActivity(id, payload, { ...defaultOptions, ...options }));
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(deleteActivity(id, { ...defaultOptions, ...options }));
  };

  const handleFetch = (options?: ActionOption) => {
    dispatch(findActivity({ ...defaultOptions, ...options }));
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getActivity(id, { ...defaultOptions, ...options }));
  };

  return {
    handleCreate,
    handleUpdate,
    handleFetch,
    handleFetchOne,
    handleDelete,
    currentActivity,
    activityList,
    activities,
  };
};
