import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_STUDENT_QUESTION_SUBMISSION = createActionType(
  'UPDATE_STUDENT_QUESTION_SUBMISSION',
  'ASSIGNMENTS'
);

export const updateStudentQuestionSubmission = (
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: UPDATE_STUDENT_QUESTION_SUBMISSION.START,
  meta: {
    payload,
    ...option,
  },
});
