import { Col, Form, Input, Modal, Row, Select } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';

const { Option } = Select;

interface ReportCommentProps {
  visibility: boolean;
  onVisibilityChange: (visibility: boolean) => void;
}
export const ReportComment = (props: ReportCommentProps) => {
  const { visibility, onVisibilityChange } = props;
  const [form] = Form.useForm();
  const onFormSubmit = (values: Record<string, any>) => {
    console.log({ values });
  };

  return (
    <Modal
      title="Report Abuse"
      visible={visibility}
      onOk={() => form.submit()}
      onCancel={() => onVisibilityChange(false)}
      width={'60vw'}
      bodyStyle={{
        height: '60vh',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
      }}
      centered
      closable
      okText={'Report Abuse'}
      forceRender
    >
      <ScrollBar>
        <div className="stm-pa-5">
          <span className="stm-fs-text-1 stm-block stm-pv-5 stm-text-color">
            Flagged content is reviewed by the school admin to determine whether
            it violates Terms of Service or Community Guidelines. If you have a
            question or technical issue, please contact the STEMuli team
            directly here
          </span>
          <Form form={form} onFinish={onFormSubmit} layout={'vertical'}>
            <Row gutter={15}>
              <Col span={24}>
                <Form.Item
                  name={'issueType'}
                  label={'Issue Type'}
                  rules={[{ required: true, message: 'Missing issue type' }]}
                >
                  <Select placeholder={'Select an issue type'}>
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={'issueDetails'}
                  label={'Issue Details'}
                  rules={[{ required: true, message: 'Missing details' }]}
                >
                  <Input.TextArea placeholder={'Issue details...'} rows={10} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </ScrollBar>
    </Modal>
  );
};
