import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ATTENDANCE = createActionType(
  'FIND_ATTENDANCE',
  'ATTENDANCE'
);

export const findAttendances = (option?: ActionOption) => ({
  type: FIND_ATTENDANCE.START,
  meta: {
    ...option,
  },
});
