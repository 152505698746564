import { createSelector } from 'reselect';
import { MasteryState } from '../../redux/reducers/mastery';

const masteries = (mastery: MasteryState, key: string) =>
  mastery?.masteries[key] || [];

export const selectMasteryData = createSelector(masteries, (masteries) => {
  const key = masteries?.reduce((acc: any, curr: any) => {
    acc['key'] = Object.keys(curr.result);
    return acc;
  }, {});

  const data = masteries?.map((mastery: Record<string, any>) => {
    return {
      ...mastery,
      result: Object.values(mastery.result),
    };
  });
  console.log('masteries:::::', masteries);
  return {
    data,
    ...key,
  };
});
