import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const GET_ACTIVITY = createActionType('GET_ACTIVITY', 'ACTIVITY');

export const getActivity = (_id: string, option?: ActionOption) => ({
  type: GET_ACTIVITY.START,
  meta: {
    _id,
    ...option
  }
});
