import {
  first,
  get,
  has,
  kebabCase,
  last,
  lowerCase,
  reduce,
  set,
} from 'lodash';
import { Action } from '../../redux/types';
import cookie from 'js-cookie';
import { FormInstance, message, Tag } from 'antd';
import { ActivityType } from '../constants';

export interface CreateActionType {
  START: string;
  SUCCESS: string;
  ERROR: string;
  END: string;
}

export function createActionType(
  type: string,
  entity = 'app'
): CreateActionType {
  return {
    START: `@@${kebabCase(lowerCase(entity))}/${type}_START`,
    SUCCESS: `@@${kebabCase(lowerCase(entity))}/${type}_SUCCESS`,
    ERROR: `@@${kebabCase(lowerCase(entity))}/${type}_ERROR`,
    END: `@@${kebabCase(lowerCase(entity))}/${type}_END`,
  };
}

export function createActionString(type: string, entity = 'app'): string {
  return `@@${kebabCase(lowerCase(entity))}/${type}`;
}

export function getNewLoadingState(
  currentState: Record<string, any> = {},
  action: Action,
  value: any
) {
  const { key } = action;

  return Object.assign({}, currentState, {
    uiLoaders: { ...currentState.uiLoaders, [key]: value },
  });
}

export const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get('token');
  console.log('TOKENTOKEN', `${process.env.REACT_APP_NODE_ENV}_token`);
  return (
    tokenParam ||
    cookie.get(`${process.env.REACT_APP_NODE_ENV}_token`) ||
    cookie.get(`token`)
  );
};

export const arrayToById = (array: any[] = [], key = '_id') => {
  return array.reduce((accumulator, currentObject) => {
    accumulator[get(currentObject, key)] = currentObject;
    return accumulator;
  }, {});
};

export const randInt = (start = 0, end = 100) =>
  Math.floor(Math.random() * (end - start) + start + 1);

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const onBeforeImageUpload = (file: any, size = 2) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return isJpgOrPng;
  }
  const isLessThanSize = file.size / 1024 / 1024 < Number(size.toFixed(0));
  if (!isLessThanSize) {
    message.error(`File must smaller than ${size.toFixed(0)}MB!`);
    return isLessThanSize;
  }
  return true;
};

export const onBeforeFileUpload =
  (acceptedFiles?: string[]) =>
  (file: any, size = 10) => {
    const accepted = acceptedFiles ?? [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/vnd.ms-powerpoint',
      'application/pdf',
      'text/plain',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const isAcceptedFileTpe = accepted.includes(file.type);
    if (!isAcceptedFileTpe) {
      message.error('You can only upload docx, text, csv, excel or pdf files!');
      return isAcceptedFileTpe;
    }
    const isLessThanSize = file.size / 1024 / 1024 < Number(size.toFixed(0));
    if (!isLessThanSize) {
      message.error(`File must smaller than ${size.toFixed(0)}MB!`);
      return isLessThanSize;
    }
    return true;
  };

export const arrayToUnitGroupedByLesson = (array: any[] = []) => {
  return array.reduce((accumulator, currentObject) => {
    const { lessons, count } = currentObject;
    lessons.forEach(({ _id, lesson, month, week }: any) => {
      if (accumulator[_id.unit._id]) {
        accumulator[_id.unit._id].lessons.push(lesson);
        accumulator[_id.unit._id].lessonUnit.push(lesson.unit._id);
        accumulator[_id.unit._id].months.push(month);
        accumulator[_id.unit._id].weeks.push(week);
      } else {
        accumulator[_id.unit._id] = {
          lessonUnit: [lesson.unit._id],
          months: [month],
          weeks: [week],
          unit: {
            ..._id.unit,
          },
          month,
          week,
          count,
          lessons: [lesson],
        };
      }
    });
    return accumulator;
  }, {});
};

export const parseFilenameFromURL = (item: Record<string, any>) => {
  if (item?.url) {
    const url = new URL(item.url);
    const pathname = url.pathname;
    const filename = last(pathname.split('/'));
    const href = first(url.href?.split('?'));
    return { filename: item?.name ?? filename, href };
  }
};

export const tagRender = (props: Record<string, any>) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      closable={closable}
      onClose={onClose}
      color={'purple'}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {label}
    </Tag>
  );
};

export const isAutoCalculatedGradePoint = (
  activityType: ActivityType | undefined | string
) => {
  switch (activityType) {
    case ActivityType.ObbyGame:
    case ActivityType.RacingGame:
    case ActivityType.Question:
      return true;
    default:
      return false;
  }
};

export const onAutoCalculateGradePoint =
  (
    form: FormInstance | undefined,
    currentActivityType: ActivityType | undefined | string,
    fieldKey = 'gradePoints'
  ) =>
  (delta: Record<string, any>, record: Record<string, any>) => {
    const autoCalculateGradePoint = isAutoCalculatedGradePoint(
      currentActivityType as ActivityType
    );

    const hasQuestionChanged = has(delta, 'questions');

    if (autoCalculateGradePoint && hasQuestionChanged) {
      const questions = get(record, 'questions');
      const gradePoints = reduce(
        questions ?? [],
        (sum: number, question: Record<string, any>) => {
          return sum + Math.abs(parseFloat(get(question, 'points', 0)));
        },
        0
      );

      if (form) {
        form.setFieldsValue(set({}, fieldKey, gradePoints));
      }
    }
  };

export const prefixHTTPProtocol = (value: string): string => {
  const regex = /^(?:http|https:\/\/)/i;
  if (regex.test(value)) {
    return value;
  }

  return 'https://'.concat(value);
};

export const reorder = (
  list: any,
  startIndex: number,
  endIndex: number
): {
  result: Array<any>;
  sourceId: string;
  destinationId: string;
} => {
  const result = Array.from(list);
  const [removed]: any = result.splice(startIndex, 1);
  const [replaced]: any = list.slice(endIndex, 1);
  result.splice(endIndex, 0, removed);
  return {
    result,
    sourceId: removed?._id,
    destinationId: replaced?._id,
  };
};
