import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { UploadCloud } from '@stemuli/icons';
import { LinkFormItem } from '../../../_shared/Links/Link';
import { v4 as uuidv4 } from 'uuid';

interface UploadDropDownProps {
  visibility: boolean;
  fileData: Record<string, any>;
  setFilesData: Dispatch<SetStateAction<Record<string, any>>>;
  trigger?: string;
  submitType?: string;
  onSuccess?: (payload: any, options?: Record<string, any>) => void;
  lessonForm?: FormInstance;
}

const UploadDropDown = (props: UploadDropDownProps): ReactElement => {
  const {
    visibility,
    setFilesData,
    trigger = 'uppy-video-display',
    onSuccess,
    submitType,
    fileData,
    lessonForm,
  } = props;
  const [form] = Form.useForm();

  const isVideoUpload = trigger === 'uppy-video-display';

  const submitLink = (value: string) => {
    if (submitType) {
      lessonForm?.setFields([
        {
          name: submitType,
          value: [...fileData.uploadedFiles, { data: value.link }],
        },
      ]);
    }
    if (onSuccess) {
      onSuccess(lessonForm?.getFieldsValue(true), {
        onFinish: () => {
          setFilesData((prev) => ({
            file: [
              ...prev.file,
              {
                name: value.link,
                _id: uuidv4(),
                type: 'link',
                url: value.link,
              },
            ],
            url: [...prev.url, value.link],
            uploadedFiles: [...prev.uploadedFiles, { data: value.link }],
          }));
        },
      });
    } else {
      setFilesData((prev) => ({
        file: [
          ...prev.file,
          { name: value.link, _id: uuidv4(), type: 'link', url: value.link },
        ],
        url: [...prev.url, value.link],
        uploadedFiles: [...prev.uploadedFiles, { data: value.link }],
      }));
    }
    form.resetFields();
  };

  return (
    <>
      <div
        className={
          'stm-text-center stm-border-1 stm-ph-10 stm-pb-4 stm-pt-10 stm-b-radius-2'
        }
        style={{
          display: !visibility ? 'none' : 'block',
        }}
      >
        <Button
          type="primary"
          className={trigger}
          onClick={(e) => e.currentTarget.focus()}
          icon={
            <span className="anticon">
              <UploadCloud />
            </span>
          }
        >
          Upload {isVideoUpload ? 'video' : 'resource'} file
        </Button>
        <h2 className={'stm-pv-4'}>OR</h2>
        <Form onFinish={submitLink} form={form}>
          <Row>
            <Col lg={20}>
              <LinkFormItem
                formItemProps={{
                  name: 'link',
                }}
                inputProps={{
                  placeholder: `Paste or enter ${
                    isVideoUpload ? 'video' : 'resource'
                  } link URL`,
                  className:
                    'stm-b-radius-0-top-right-force stm-b-radius-0-bottom-right-force',
                  style: {
                    borderRight: 0,
                  },
                }}
              />
            </Col>
            <Col lg={4}>
              <Button
                className={
                  'stm-b-radius-0-top-left-force stm-b-radius-0-bottom-left-force w-100'
                }
                icon={
                  <span className="anticon">
                    <i className="ri-links-line" style={{ fontSize: 16 }} />
                  </span>
                }
                type="primary"
                onClick={form.submit}
              >
                Upload Link
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UploadDropDown;
