import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_ATTENDANCE = createActionType(
  'CREATE_ATTENDANCE',
  'ATTENDANCE'
);

export const createAttendance = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_ATTENDANCE.START,
  meta: {
    payload,
    ...options,
  },
});
