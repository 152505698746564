import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_PLAYLIST = createActionType('CREATE_PLAYLIST', 'PLAYLIST');

export const createPlaylist = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_PLAYLIST.START,
  meta: {
    ...options,
    payload,
  },
});
