import { findAttendance } from './findAttendance';
import { getAttendance } from './getAttendance';
import { createAttendance } from './createAttendance';
import { updateAttendance } from './updateAttendance';
import { getAttendanceClassPercentage } from './getAttendanceClassPercentage';
import { getAttendanceStudentPercentage } from './getAttendanceStudentPercentage';

export default [
  getAttendance,
  findAttendance,
  createAttendance,
  updateAttendance,
  getAttendanceClassPercentage,
  getAttendanceStudentPercentage,
];
