import { Action } from '../../types';
import {
  GET_ATTENDANCE_COUNT,
  GET_EXIT_TICKET_STANDARD_COUNT,
  GET_RED_FLAGS_COUNT,
  GET_SCHOOL_ATTENDANCE,
  GET_SCHOOL_RED_FLAGS,
  GET_GRADE_LEVELS,
  GET_SCHOOL_CLASSES,
} from '../../actions';
import { arrayToById } from '../../../_shared/utils';

export interface SchoolState {
  attendanceCount: Record<string, any[]>;
  attendanceCountById: Record<string, Record<string, string>>;
  redFlagsCount: Record<string, any[]>;
  redFlagsCountById: Record<string, Record<string, string>>;
  exitTicketStandardCount: Record<string, any[]>;
  exitTicketStandardCountById: Record<string, Record<string, string>>;

  gradeLevels: Record<string, any[]>;
  classes: Record<string, any[]>;
  classesById: Record<string, Record<string, string>>;

  attendance: Record<string, any[]>;
  attendanceById: Record<string, Record<string, string>>;
  redFlags: Record<string, any[]>;
  redFlagsById: Record<string, Record<string, string>>;
}

export const SchoolStateDefaultState: SchoolState = {
  attendanceCount: {},
  attendanceCountById: {},
  redFlagsCount: {},
  redFlagsCountById: {},
  exitTicketStandardCount: {},
  exitTicketStandardCountById: {},

  gradeLevels: {},
  classes: {},
  classesById: {},

  attendance: {},
  attendanceById: {},
  redFlags: {},
  redFlagsById: {},
};

const schoolReducer = (state = SchoolStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_ATTENDANCE_COUNT.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        attendanceCount: {
          ...state.attendanceCount,
          [action.key]: payload,
        },
        attendanceCountById: {
          ...state.attendanceCountById,
          [action.key]: byIdFind,
        },
      });
    }
    case GET_RED_FLAGS_COUNT.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        redFlagsCount: {
          ...state.redFlagsCount,
          [action.key]: payload,
        },
        redFlagsCountById: {
          ...state.redFlagsCountById,
          [action.key]: byIdFind,
        },
      });
    }
    case GET_SCHOOL_ATTENDANCE.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        attendance: {
          ...state.attendance,
          [action.key]: payload,
        },
        attendanceById: {
          ...state.attendanceById,
          [action.key]: byIdFind,
        },
      });
    }
    case GET_SCHOOL_RED_FLAGS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        redFlags: {
          ...state.redFlags,
          [action.key]: payload,
        },
        redFlagsById: {
          ...state.redFlagsById,
          [action.key]: byIdFind,
        },
      });
    }
    case GET_EXIT_TICKET_STANDARD_COUNT.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        exitTicketStandardCountCount: {
          ...state.exitTicketStandardCount,
          [action.key]: payload,
        },
        exitTicketStandardCountCountById: {
          ...state.exitTicketStandardCountById,
          [action.key]: byIdFind,
        },
      });
    }
    case GET_GRADE_LEVELS.SUCCESS: {
      return Object.assign({}, state, {
        gradeLevels: {
          ...state.gradeLevels,
          [action.key]: payload?.grades || [],
        },
      });
    }
    case GET_SCHOOL_CLASSES.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        classes: {
          ...state.classes,
          [action.key]: payload || [],
        },
        classesById: {
          ...state.classesById,
          [action.key]: byIdFind,
        },
      });
    }

    default:
      return state;
  }
};

export default schoolReducer;
