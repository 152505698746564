import { initializeEntry } from '../../pages/route';
import teacherPages from '../../pages/teacher/route.config.json';
import principalPages from '../../pages/principal/route.config.json';
import { useContext } from 'react';
import { AuthContext } from '../../contexts';

export const useRoutes = () => {
  const { auth } = useContext(AuthContext);

  switch (auth?.userType) {
    case 'Teacher':
      return initializeEntry(teacherPages, './teacher/');
    case 'Staff':
      return initializeEntry(principalPages, './principal/');
    default:
      window.location.assign(
        `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}` as string
      );
      return { private: [], public: [] };
  }
};
