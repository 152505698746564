import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {POST, Services, ServicesURLs} from '../../../../_shared/constants';
import {CREATE_LESSON} from '../../../actions/lesson';
import {apiRequest} from '../../../actions/app';

export const createLesson: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === CREATE_LESSON.START) {
          const {key, ...rest} = action.meta;
          dispatch(
            apiRequest(
              {
                service: Services.class,
                method: POST,
                url: ServicesURLs.lessons,
                key: key || 'createLesson',
                ...rest,
                onSuccess: (data: any) => {
                  dispatch({type: CREATE_LESSON.SUCCESS, payload: data, key});
                }
              }
            )
          );
        }
      };
