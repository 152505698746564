import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_EXIT_TICKET_STANDARD_COUNT = createActionType(
  'GET_EXIT_TICKET_STANDARD_COUNT',
  'SCHOOL'
);

export const getExitTicketStandardCount = (
  schoolID: string,
  option?: ActionOption
) => ({
  type: GET_EXIT_TICKET_STANDARD_COUNT.START,
  meta: {
    schoolID,
    ...option,
  },
});
