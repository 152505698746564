import UUID from 'uuidjs';
import { QuestionType } from '../../components/_shared';
import { ActivityType } from '../constants';

export const MOCK_ACTIVITIES = [
  {
    activity: {
      _id: UUID.generate(),
      title: 'Activity Title 1',
      type: ActivityType.Question,
    },
    playlist: {
      gradePoint: 0,
      learnerGroups: ['Meets'],
      gamePoint: 0,
      dueDate: 0,
      syllabusCategory: UUID.generate(),
      classes: [UUID.generate()],
      standards: [UUID.generate(), UUID.generate()],
    },
    questions: [
      {
        question: 'What is noun',
        questionType: QuestionType.LongAnswer,
        longAnswer: 'Lorem ipsum dolor sit amet',
        standards: [UUID.generate()],
      },
      {
        question: 'What is noun',
        questionType: QuestionType.ShortAnswer,
        shortAnswer: 'Lorem ipsum dolor sit amet',
        standards: [UUID.generate()],
      },
      {
        question: 'What is verb',
        questionType: QuestionType.MultipleAnswers,
        options: [
          { text: 'A figure of speech', isCorrect: true },
          { text: 'A part of speech', isCorrect: true },
        ],
        standards: [UUID.generate()],
      },
      {
        question: 'What is Pronoun',
        questionType: QuestionType.SingleAnswer,
        texts: [{ text: 'A figure of speech' }, { option: 'A part of speech' }],
        singleChoiceAnswer: 1,
        standards: [UUID.generate()],
      },
    ],
  },
  {
    activity: {
      _id: UUID.generate(),
      title: 'Activity Title 2',
      type: ActivityType.Question,
    },
    playlist: {
      gradePoint: 15,
      learnerGroups: ['Meets'],
      gamePoint: 3,
      dueDate: 0,
      syllabusCategory: undefined,
      classes: [UUID.generate(), UUID.generate()],
      standards: [UUID.generate(), UUID.generate()],
    },
  },
];

export const MOCK_EMPTY_ACTIVITIES = {
  activity: {
    title: undefined,
    type: ActivityType.Question,
  },
  playlist: {
    gradePoint: undefined,
    learnerGroups: undefined,
    gamePoint: undefined,
    scoreType: undefined,
    extraPoints: undefined,
    weight: undefined,
    dueDate: undefined,
    syllabusCategory: undefined,
    classes: [],
    standards: [],
  },
  questions: [],
};

export const MOCK_EMPTY_EXIT_TICKET = {
  questions: [
    {
      question: undefined,
      questionType: QuestionType.SingleAnswer,
      options: [
        {
          text: undefined,
          correctAnswer: false,
        },
      ],
    },
  ],
};
