import { createSelector } from 'reselect';
import { PlaylistState } from '../../redux/reducers/playlist';

const playlists = (playlist: PlaylistState, key: string) =>
  playlist?.playlists[key] || [];

export const selectPlayLists = createSelector(
  playlists,
  (playlists: LessonNameSpace.Playlist[]) => {
    return playlists?.map(({ _id, lesson }) => ({ _id, lesson }));
  }
);
