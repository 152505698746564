import { Action } from '../../types';
import { FIND_MEMBERS, GET_MEMBERS } from '../../actions';
import { arrayToById } from '../../../_shared/utils';

export interface MembersState {
  members: Record<string, Record<string, any>[]>;
  membersById: Record<string, any>;
  currentMemberProfile: Record<string, any>;
}

export const MembersDefaultState: MembersState = {
  members: {},
  membersById: {},
  currentMemberProfile: {},
};

const masteryReducer = (state = MembersDefaultState, action: Action) => {
  const { payload, key } = action;
  switch (action.type) {
    case FIND_MEMBERS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        members: {
          ...state.members,
          [key]: Object.values(byIdFind),
        },
        membersById: {
          ...state.members,
          [key]: byIdFind,
        },
      });
    }
    case GET_MEMBERS.SUCCESS: {
      return Object.assign({}, state, {
        currentMemberProfile: {
          ...state.currentMemberProfile,
          [key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default masteryReducer;
