import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_ANNOUNCEMENT = createActionType(
  'UPDATE_ANNOUNCEMENT',
  'ANNOUNCEMENTS'
);

export const updateAnnouncement = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_ANNOUNCEMENT.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
