import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_STANDARD = createActionType('FIND_STANDARD', 'STANDARD');

export const findStandard = (options?: ActionOption) => ({
  type: FIND_STANDARD.START,
  meta: {
    ...options,
  },
});
