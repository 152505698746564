import { createActionType } from '../../../../_shared/utils';

export const UPDATE_DRAG_AND_DROP = createActionType(
  'UPDATE_DRAG_AND_DROP',
  'UNIT'
);

export const updateDragAndDrop = (
  payload: Record<string, any>,
  classId?: string
) => ({
  type: UPDATE_DRAG_AND_DROP.START,
  meta: {
    classId,
    payload,
  },
});
