// Replacing Moment in Ant-Design
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import 'antd/lib/date-picker/style/index';
import 'antd/lib/calendar/style';

export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
export const Calendar = generateCalendar<Date>(dateFnsGenerateConfig);

export const PaginationNextAndPreviousButtons = (
  _: any,
  type: any,
  originalElement: any
) => {
  if (type === 'prev') {
    return <a className={'stm-ph-5'}>Previous</a>;
  }
  if (type === 'next') {
    return <a className={'stm-ph-5'}>Next</a>;
  }
  return originalElement;
};
