import { apiRequest, GET_STUDENT_LEARNER_GROUPS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const getStudentLearnerGroup: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_STUDENT_LEARNER_GROUPS.START) {
      const { key, classId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.studentLearnerGroups}/${classId}/classes`,
          key: key || 'getStudentLearnerGroup',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GET_STUDENT_LEARNER_GROUPS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
