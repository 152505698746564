import './activity-form.scss';
import { Button, Form, FormProps } from 'antd';
import { ReactNode, ReactNodeArray } from 'react';
import { ActivityFormHeader } from './ActivityFormHeader';
import { useUIState } from '../../../../../hooks';
// import {onAutoCalculateGradePoint} from '../../../../../_shared/utils';
import { ActivityType } from '../../../../../_shared/constants';
import { AnimatePresence } from 'framer-motion';

export interface ActivityFormProps {
  formProps: FormProps;
  activityType: string;
  currentActivity: Record<string, any>;
  onRemove: () => void;
  children: ReactNode | ReactNodeArray;
  classList: Record<string, any>[];
  syllabusCategoryList: Record<string, any>[];
  learnerGroupList: { label: string; value: string }[];
  currentLesson?: LessonNameSpace.Lesson;
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
}

export const ActivityForm = (props: ActivityFormProps) => {
  const {
    formProps,
    currentActivity,
    children,
    activityType,
    classList,
    syllabusCategoryList,
    learnerGroupList,
    currentLesson,
    onRemove,
    onActivityItemSelected,
  } = props;

  const {
    uiLoaders: { '@@CREATE_PLAYLIST_ACTIVITY_UI_KEY': creatingActivity },
  } = useUIState();

  return (
    <AnimatePresence>
      <div className="stm-create-lesson-activity-form-container stm-pa-5">
        <Form
          scrollToFirstError
          data-testid={'activity-form-test-id'}
          {...formProps}
          layout={'vertical'}
          // onValuesChange={onAutoCalculateGradePoint(
          //   formProps.form,
          //   activityType,
          //   'activity.gradePoints'
          // )}
        >
          <ActivityFormHeader
            activityType={activityType}
            form={formProps.form}
            currentActivity={currentActivity}
            classList={classList}
            syllabusCategoryList={syllabusCategoryList}
            learnerGroupList={learnerGroupList}
            currentLesson={currentLesson}
            onRemove={onRemove}
            onActivityItemSelected={onActivityItemSelected}
          />
          <Form.Item noStyle>{children}</Form.Item>
          <Form.Item noStyle>
            <div className={'stm-create-lesson-activity-form-container-footer'}>
              <Button
                htmlType={'submit'}
                type={'primary'}
                block
                loading={creatingActivity}
                disabled={creatingActivity}
                data-testid={'save-activity-button'}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </AnimatePresence>
  );
};
