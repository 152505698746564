import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { GET_EXIT_TICKET_STANDARD_COUNT } from '../../../actions';

export const getExitTicketStandardCount: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_EXIT_TICKET_STANDARD_COUNT.START) {
      const { key, schoolID, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.school}/${schoolID}/exit-ticket-standard-counts`,
          key: key || 'getExitTicketStandardCount',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GET_EXIT_TICKET_STANDARD_COUNT.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
