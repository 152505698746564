import { createActionString } from '../../../../_shared/utils';

export const COLLATE_SELECTED_STANDARD = createActionString(
  'COLLATE_SELECTED_STANDARD',
  'STANDARD'
);

export const collateSelectedStandards = (
  payload: Record<string, any> | Record<string, any>[],
  key: string
) => ({
  type: COLLATE_SELECTED_STANDARD,
  payload,
  key,
});
