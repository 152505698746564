import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_ASSIGNMENT = createActionType(
  'UPDATE_ASSIGNMENT',
  'ASSIGNMENTS'
);

export const updateAssignment = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_ASSIGNMENT.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
