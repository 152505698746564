import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const DELETE_ACTIVITY = createActionType('DELETE_ACTIVITY', 'ACTIVITY');

export const deleteActivity = (_id: string, options?: ActionOption) => ({
  type: DELETE_ACTIVITY.START,
  meta: {
    _id,
    ...options
  }
});
