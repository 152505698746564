import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_OVERVIEW_STATS = createActionType(
  'GET_OVERVIEW_STATS',
  'OVERVIEW'
);

export const getOverviewStats = (options?: ActionOption) => ({
  type: GET_OVERVIEW_STATS.START,
  meta: {
    ...options,
  },
});
