import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_GRADE = createActionType('UPDATE_GRADE', 'GRADE');

export const updateGrade = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_GRADE.START,
  meta: {
    _id,
    payload,
    ...options,
  },
});
