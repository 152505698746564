import { Action } from '../../types';
import {
  COLLATE_SELECTED_STANDARD,
  CREATE_STANDARD,
  DELETE_STANDARD,
  FIND_STANDARD,
  GET_STANDARD,
  UPDATE_STANDARD,
} from '../../actions';
import { arrayToById } from '../../../_shared/utils';

export interface StandardState {
  standards: Record<string, LessonNameSpace.Standard[]> | any;
  standardsById: Record<string, Record<string, LessonNameSpace.Standard>> | any;
  currentStandard: LessonNameSpace.Standard | any;
  collation: Record<string, LessonNameSpace.Standard[]> | any;
}

export const StandardStateDefaultState: StandardState = {
  standards: {},
  standardsById: {},
  currentStandard: {},
  collation: [],
};

const standardReducer = (state = StandardStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_STANDARD.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        standardsById: {
          ...state.standardsById,
          [action.key]: byIdFind,
        },
        standards: {
          ...state.standards,
          [action.key]: Object.values(byIdFind),
        },
        currentStandard: {
          ...state.currentStandard,
          [action.key]: {},
        },
      });
    }
    case GET_STANDARD.SUCCESS:
    case CREATE_STANDARD.SUCCESS:
    case UPDATE_STANDARD.SUCCESS: {
      const byIdCreate = {
        ...state.standardsById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        lessonsById: {
          ...state.standardsById,
          [action.key]: byIdCreate,
        },
        units: {
          ...state.standards,
          [action.key]: Object.values(byIdCreate),
        },
        currentUnit: {
          ...state.currentStandard,
          [action.key]: payload,
        },
      });
    }
    case DELETE_STANDARD.SUCCESS: {
      const byId = { ...state.standardsById[action.key] };
      delete byId[payload._id];
      return Object.assign({}, state, {
        unitsById: {
          ...state.standardsById,
          [action.key]: byId,
        },
        units: {
          ...state.standards,
          [action.key]: Object.values(byId),
        },
        currentUnit: null,
      });
    }
    case COLLATE_SELECTED_STANDARD: {
      return {
        ...state,
        collation: {
          ...state.collation,
          [action.key]: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default standardReducer;
