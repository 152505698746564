import { deleteAnnouncement } from './deleteAnnouncement';
import { createAnnouncement } from './createAnnouncement';
import { updateAnnouncement } from './updateAnnouncement';
import { getAnnouncement } from './getAnnouncement';
import { findAnnouncements } from './findAnnouncements';
import { findAnnouncementComments } from './findComments';
import { postAnnouncementComments } from './postComment';
import { findUserAnnouncements } from './findUserAnnouncements';

export default [
  deleteAnnouncement,
  findAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  getAnnouncement,
  postAnnouncementComments,
  findAnnouncementComments,
  findUserAnnouncements,
];
