import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_ANNOUNCEMENT = createActionType(
  'DELETE_ANNOUNCEMENT',
  'ANNOUNCEMENTS'
);

export const deleteAnnouncement = (_id: string, options?: ActionOption) => ({
  type: DELETE_ANNOUNCEMENT.START,
  meta: {
    _id,
    ...options,
  },
});
