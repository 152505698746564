import { createSelector } from 'reselect';
import { StandardState } from '../../redux/reducers/standard';

const standards = (standard: StandardState, key: string) =>
  standard?.standards[key] || [];

export const selectStandardList = createSelector(standards, (standards) => {
  return standards.map(({ _id: value, code: label }: any) => ({
    value,
    label,
  }));
});
