import { pick } from 'lodash';
import { createSelector } from 'reselect';
import { NotificationState } from '../../redux/reducers/notification';

const notifications = (notification: NotificationState, key: string) =>
  notification?.notifications[key] || [];

export const selectNotificationsData = createSelector(
  notifications,
  (notifications) => {
    return notifications.map(
      ({ _id, data, type, createdAt, viewed, notificationLastReadAt }) => {
        const selectedData = {
          _id,
          notificationType: type,
          createdAt,
          viewed,
          notificationLastReadAt,
        };
        if (type === 'submission') {
          return {
            ...selectedData,
            user: pick(data.user, [
              'avatar',
              'firstName',
              'lastName',
              'userType',
            ]),
            ...pick(data, ['title', 'type', 'gradePoints']),
          };
        }
        if (type === 'lesson-start') {
          const newData = data?.map((d: Record<string, any>) => {
            return {
              ...pick(d, ['title', 'learningObjective', 'publishedDate']),
              ...pick(d.unit, ['publishedDate']),
            };
          });
          return {
            ...selectedData,
            data: newData,
          };
        }
        return {
          ...selectedData,
          data,
        };
      }
    );
  }
);
