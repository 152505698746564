import { useLocation, useNavigate } from '@reach/router';
import { parse } from 'query-string';
import { Button, FormInstance } from 'antd';
import CreateLessonForm from '../../../../components/lesson/lesson-form';
import { useLesson, useLocalStorage, useUIState } from '../../../../hooks';
import './create.scss';
import { debounce, get, has, isEmpty, isFunction, isUndefined } from 'lodash';
import { addMinutes, differenceInMinutes, formatDistanceToNow } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { ConditionalRenderer } from '../../../../components/_shared';

interface CreateLessonProps {
  currentLesson?: LessonNameSpace.Lesson;
  location?: any;
  onCreateExitVisibilityChange: (visibility: boolean) => void;
  setStandards: (standards: string[]) => void;
  exitTicketItems: Record<string, any>;
  onCreateOrEditPlaylist: (visibility: boolean) => void;
  form: FormInstance;
}

let debounced: any;
export const LESSONS_PAGE_KEY = '@@LESSONS_PAGE_KEY';
export const LESSON_CLASS_LOCAL_STORAGE_KEY =
  '@@LESSON_CLASS_LOCAL_STORAGE_KEY';

const CreateLesson = (props: CreateLessonProps) => {
  const {
    currentLesson,
    onCreateExitVisibilityChange,
    onCreateOrEditPlaylist,
    setStandards,
    exitTicketItems,
    form,
  } = props;
  const location = useLocation();

  const storage = useLocalStorage(LESSON_CLASS_LOCAL_STORAGE_KEY, [], {
    update: LESSON_CLASS_LOCAL_STORAGE_KEY.concat('/UPDATE'),
  });

  const navigate = useNavigate();

  const {
    uiLoaders: { ['@@LESSON/AUTO-UPDATE']: autoSaving, ...uiLoaders },
  } = useUIState();

  const { handleCreate, handleUpdate } = useLesson({ key: LESSONS_PAGE_KEY });

  const onLessonFormSubmit = (
    payload: any,
    options: Record<string, any> = {
      params: {},
      uiKey: null,
      autoSave: false,
      onFinish: null,
    }
  ) => {
    const params = Object.assign({}, options?.params ?? {}, {
      population: JSON.stringify([
        'announcement',
        'standards',
        {
          path: 'exitTicket',
          populate: ['questions'],
        },
        {
          path: 'playlist',
          populate: [
            {
              path: 'activities',
              populate: [
                { path: 'learnerGroups' },
                // { path: 'syllabusCategory' },
                { path: 'questions' },
              ],
            },
          ],
        },
      ]),
    });
    const queryString = parse(location.search);
    if (payload?.announcement) {
      payload.announcement.audience = ['Student'];
      payload.announcement.classes = payload.classes.map(
        (singleClass: any) => singleClass._id
      );
    }

    if (!currentLesson) {
      handleCreate(payload, {
        uiKey: options?.uiKey ?? `createLesson`,
        params,
        onFinish: (data: Record<string, any>) => {
          storage.clear();
          navigate(`/lessons/${data._id}/edit${location?.search}`);
        },
      });
    } else {
      handleUpdate(
        currentLesson?._id,
        payload,
        Object.assign(
          {},
          {
            uiKey: options?.uiKey ?? `updateLesson_${currentLesson?._id}`,
            params,
            onFinish: () => {
              storage.clear();
              if (queryString.unitId && !options?.autoSave) {
                navigate(
                  `/classes/${queryString.classId}/syllabus/?unitId=${queryString.unitId}&_page=${queryString._page}`
                );
              }
              if (queryString.unitId && options.onFinish) options.onFinish();
            },
          },
          options?.APIOptions
        )
      );
    }
  };

  const onFormValuesChange = (
    _: Record<string, any>,
    values: Record<string, any>
  ) => {
    if (debounced && debounced.cancel && isFunction(debounced.cancel)) {
      debounced.cancel();
    }
    debounced = debounce(
      (_: Record<string, any>, values: Record<string, any>) => {
        if (values.classes) {
          storage.update(() =>
            (values?.classes ?? []).filter(
              (o: Record<string, any>) => !isUndefined(o)
            )
          );
        }

        if (has(currentLesson, '_id')) {
          const payload = form.getFieldsValue();
          if(payload?.annoucement) {
            payload.announcement.audience = ['Student'];
          }
          payload.classes = payload.classes.map(
            (singleClass: Record<string, any>) => {
              const newClass = { ...singleClass };
              if (
                !isEmpty(newClass?.lessonTime) &&
                newClass?.lessonTime[1] &&
                newClass?.lessonTime[0]
              ) {
                const timeDifferenceInMinutes = differenceInMinutes(
                  newClass?.lessonTime[1],
                  newClass?.lessonTime[0]
                );

                const dateTime = new Date(newClass.lessonTime[0]);
                const dateDate = new Date(newClass.startDate);

                newClass.startDate = new Date(
                  `${
                    dateDate.getMonth() + 1
                  }-${dateDate.getDate()}-${dateDate.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:00`
                );

                newClass.endDate = addMinutes(
                  newClass.startDate,
                  timeDifferenceInMinutes
                );
              }

              return newClass;
            }
          );

          onLessonFormSubmit(payload, {
            uiKey: '@@LESSON/AUTO-UPDATE',
            autoSave: true,
            APIOptions: {
              useSound: false,
            },
          });
        }
      },
      1000
    );
    return debounced(_, values);
  };

  return (
    <>
      <div className="stm-page-container">
        <div className="stm-page-header-width stm-flex stm-items-center stm-justify-between stm-pt-10 stm-pb-5">
          <div className="stm-page-header-back-action">
            <Button
              type={'link'}
              icon={
                <span className="anticon">
                  <i className="ri-arrow-left-line" />
                </span>
              }
              className={'stm-page-header-back-action-btn'}
              onClick={() => navigate(-1)}
            >
              Go back
            </Button>
          </div>
          <ConditionalRenderer condition={!isEmpty(currentLesson?._id)}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="stm-flex stm-items-center"
            >
              <AnimatePresence>
                {has(currentLesson, 'updatedAt') && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: 'easeIn' }}
                    className={
                      'stm-text-secondary-color-force stm-flex stm-items-center'
                    }
                    key={'@@LESSON/AUTO-UPDATE:SAVE-TIME'}
                    // layout
                  >
                    <span className={'stm-mr-3'}>
                      {autoSaving ? 'Saving' : 'Last saved:'}{' '}
                    </span>
                    {autoSaving && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: 'easeIn' }}
                        className="stm-flex stm-items-center stm-mr-3"
                        key={'@@LESSON/AUTO-UPDATE:LOADER'}
                      >
                        <span
                          className={'anticon anticon-loading anticon-spin'}
                        >
                          <i className="ri-loader-5-line" />
                        </span>
                      </motion.div>
                    )}
                    {!autoSaving && (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        {formatDistanceToNow(
                          new Date(get(currentLesson, 'updatedAt', Date.now())),
                          { addSuffix: true }
                        )}
                      </motion.span>
                    )}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.div>
          </ConditionalRenderer>
        </div>
        <div className="stm-page-container-inner">
          <CreateLessonForm
            exitTicketItems={exitTicketItems}
            setStandards={setStandards}
            currentLesson={currentLesson}
            onSubmit={onLessonFormSubmit}
            onCreateExitVisibilityChange={onCreateExitVisibilityChange}
            onCreateOrEditPlaylist={onCreateOrEditPlaylist}
            formLoading={
              uiLoaders['createLesson'] ||
              uiLoaders[`updateLesson_${currentLesson?._id}`]
            }
            form={form}
            onFormValuesChange={onFormValuesChange}
          />
        </div>
      </div>
    </>
  );
};

export default CreateLesson;
