import {Col, Form, Radio, Space} from 'antd';
import {ActivityType} from '../../../../../../_shared/constants';

interface GameTypeSelectionProps {
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
  activityType: ActivityType;
}
export const GameTypeSelection = (props: GameTypeSelectionProps) => {
  const { onActivityItemSelected, activityType } = props;
  // Changes Activity type in parent component
  const handleGameTypeSelection = (e: any) => {
    const { value } = e.target;
    onActivityItemSelected(value, false);
  };
  return (
    <div data-testid={'activity-game-selection'}>
      <Col span={24}>
        <Form.Item noStyle name="gameType" valuePropName={'checked'}>
          <Radio.Group
            style={{ color: 'var(--accent)' }}
            onChange={handleGameTypeSelection}
            value={activityType}
          >
            <Radio value={ActivityType.RacingGame}>
              <Space size={10} align={'center'} className={'stm-accent'}>
                <span className={'stm-fw-3 stm-heading-color-force'}>
                  Racing Game
                </span>
              </Space>
            </Radio>
            <Radio value={ActivityType.ObbyGame}>
              <span className={'stm-accent stm-fw-3 stm-heading-color-force'}>
                Obby Game
              </span>
            </Radio>
            {/*<Radio value={ActivityType.PlotActivity}>*/}
            {/*  <span className={'stm-accent stm-fw-3 stm-heading-color-force'}>Plot Activity</span>*/}
            {/*</Radio>*/}
          </Radio.Group>
        </Form.Item>
      </Col>
    </div>
  );
};
