import { Action } from '../../types';
import { FIND_RECORDING, GET_RECORDING } from '../../actions';
import { arrayToById } from '../../../_shared/utils';

export interface RecordingState {
  recordings: Record<string, any[]>;
  recordingsById: Record<string, Record<string, string>>;
  currentRecording: Record<string, any>;
}

export const RecordingStateDefaultState: RecordingState = {
  recordings: {},
  recordingsById: {},
  currentRecording: {},
};

const recordingReducer = (
  state = RecordingStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_RECORDING.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        recordings: {
          ...state.recordings,
          [action.key]: payload,
          // [action.key]: Object.values(byIdFind),
        },
        recordingsById: {
          ...state.recordingsById,
          [action.key]: byIdFind,
        },
        currentRecording: {
          ...state.currentRecording,
          [action.key]: {},
        },
      });
    }
    case GET_RECORDING.SUCCESS: {
      const byIdCreate = {
        ...state.recordingsById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        recordings: {
          ...state.recordings,
          [action.key]: Object.values(byIdCreate),
        },
        recordingsById: {
          ...state.recordingsById,
          [action.key]: byIdCreate,
        },
        currentRecording: {
          ...state.currentRecording,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default recordingReducer;
