import UUIDClass from 'uuidjs';

//These are only temporary, Until the API id done
export const MOCK_PRINCIPAL_ATTENDANCE_CLASSES = [
  {
    title: 'Math 101',
    _id: UUIDClass.generate(),
  },
  {
    title: 'Math 102',
    _id: UUIDClass.generate(),
  },
  {
    title: 'Math 201',
    _id: UUIDClass.generate(),
  },
  {
    title: 'Math 202',
    _id: UUIDClass.generate(),
  },
];

export const MOCK_PRINCIPAL_ATTENDANCE_TEACHERS = [
  {
    name: 'Adams Green',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Taylor French',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Brian Flinch',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Desiree Smith',
    _id: UUIDClass.generate(),
  },
];

export const MOCK_PRINCIPAL_ATTENDANCE_STUDENTS = [
  {
    name: 'Adams Green',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Taylor French',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Brian Flinch',
    _id: UUIDClass.generate(),
  },
  {
    name: 'Desiree Smith',
    _id: UUIDClass.generate(),
  },
];
