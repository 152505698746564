import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_ASSIGNMENT_SUBMISSION = createActionType(
  'CREATE_ASSIGNMENT_SUBMISSION',
  'ASSIGNMENTS'
);

export const createAssignmentSubmission = (
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: CREATE_ASSIGNMENT_SUBMISSION.START,
  meta: {
    ...option,
    payload,
  },
});
