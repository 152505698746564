import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_STANDARD = createActionType('CREATE_STANDARD', 'STANDARD');

export const createStandard = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_STANDARD.START,
  meta: {
    ...options,
    payload,
  },
});
