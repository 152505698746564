import { add, differenceInDays, sub } from 'date-fns';
import UUIDClass from 'uuidjs';
import { randInt } from '../utils';

const startDate = sub(new Date(), { days: 28 });
const endDate = new Date();

export const MOCK_CLASS_ATTENDANCE = {
  class: UUIDClass.generate(),
  dateRange: { startDate, endDate },
  students: Array.from({ length: 30 }, () => ({
    _id: UUIDClass.generate(),
    key: UUIDClass.generate(),
    name: 'Wayne Rooney',
    profilePicture: 'https://source.unsplash.com/random?people',
    abscents: randInt(0, 5),
    tardies: randInt(0, 15),
    record: Array.from(
      { length: Math.abs(differenceInDays(startDate, endDate)) },
      (_v, i: number) => ({
        code: randInt(-1, 1),
        date: add(startDate, { days: i }),
      })
    ),
  })),
};

export const MOCK_CLASS_STATS_ATTENDANCE = [
  {
    section: 'Math 101',
    _id: UUIDClass.generate(),
    average: 50,
  },
  {
    section: 'Math 201',
    _id: UUIDClass.generate(),
    average: 30,
  },
  {
    section: 'Math 301',
    _id: UUIDClass.generate(),
    average: 20,
  },
  {
    section: 'Math 401',
    _id: UUIDClass.generate(),
    average: 47,
  },
  {
    section: 'Math 402',
    _id: UUIDClass.generate(),
    average: 68,
  },
  {
    section: 'Math 403',
    _id: UUIDClass.generate(),
    average: 79,
  },
  {
    section: 'Math 505',
    _id: UUIDClass.generate(),
    average: 52,
  },
];
