import { createActionType } from 'src/_shared/utils';
import { ActionOption } from 'src/redux/types';

export const GET_ATTENDANCE_STUDENT_PERCENTAGE = createActionType(
  'GET_ATTENDANCE_STUDENT_PERCENTAGE',
  'ATTENDANCE'
);

export const getAttendanceStudentPercentage = (
  _id: string,
  option?: ActionOption
) => ({
  type: GET_ATTENDANCE_STUDENT_PERCENTAGE.START,
  meta: {
    _id,
    ...option,
  },
});
