import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { CREATE_ANNOUNCEMENT } from '../../../actions';

export const createAnnouncement: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === CREATE_ANNOUNCEMENT.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: POST,
          url: ServicesURLs.announcements,
          key: key || 'createAnnouncement',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: CREATE_ANNOUNCEMENT.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
