import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { collateSelectedStandards, findStandard } from '../../redux/actions';
import { ActionOption, RootState } from '../../redux/types';
import { selectStandardList } from '../../selectors';

export type UseStandardProps = {
  key: string;
  auth?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseStandardType = {
  standards: LessonNameSpace.Standard[];
  collations: LessonNameSpace.Standard[];
  standardList: { label: string; value: string }[];
  standardsById: Record<string, any>;
  currentStandard: LessonNameSpace.Standard;
  collateStandards: (standards: Array<LessonNameSpace.Standard>) => void;
};

export const useStandard = ({
  params,
  key,
  doFind = true,
}: UseStandardProps): UseStandardType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
  };

  const {
    currentStandard,
    standards,
    standardList,
    standardsById,
    collations,
  } = useSelector(({ standard }: RootState) => ({
    currentStandard: standard?.currentStandard[key],
    standardList: selectStandardList(standard, key),
    standards: standard?.standards[key],
    standardsById: standard?.standardsById[key],
    collations: standard?.collation[key],
  }));

  useEffect(() => {
    if (doFind) {
      dispatch(findStandard({ ...defaultOptions, params }));
    }
  }, [doFind]);

  const collateStandards = (standards: Array<LessonNameSpace.Standard>) => {
    dispatch(collateSelectedStandards(standards, key));
  };

  return {
    standards,
    collations,
    standardList,
    currentStandard,
    standardsById,
    collateStandards,
  };
};
