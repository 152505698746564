import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {POST, Services, ServicesURLs} from '../../../../_shared/constants';
import {apiRequest} from '../../../actions/app';
import {CREATE_QUESTION} from '../../../actions/question/createQuestion';

export const createQuestion: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === CREATE_QUESTION.START) {
          const {key, ...rest} = action.meta;
          dispatch(
            apiRequest(
              {
                service: Services.class,
                method: POST,
                url: ServicesURLs.questions,
                key: key || 'createQuestion',
                ...rest,
                onSuccess: (data: any) => {
                  dispatch({type: CREATE_QUESTION.SUCCESS, payload: data, key});
                }
              }
            )
          );
        }
      };
