import { get } from 'lodash';
import { createSelector } from 'reselect';
import { MembersState } from '../../redux/reducers/members';

const members = (members: MembersState, key: string) =>
  members?.members[key] || [];

const currentMember = (members: MembersState, key: string) =>
  members?.currentMemberProfile[key] || [];

export const selectMembersData = createSelector(members, (members) => {
  return members.map(
    ({
      _id,
      firstName,
      lastName,
      email,
      mobile,
      grade,
      sisId,
      graduationYear,
      userType,
    }) => {
      return {
        _id,
        firstName,
        lastName,
        email,
        mobile,
        grade,
        sisId,
        graduationYear,
        userType,
      };
    }
  );
});

export const selectCurrentMember = createSelector(
  currentMember,
  (currentMember) => {
    return {
      _id: currentMember._id,
      firstName: get(currentMember, ['user', 'firstName']),
      lastName: get(currentMember, ['user', 'lastName']),
      email: get(currentMember, ['user', 'email']),
      mobile: get(currentMember, ['user', 'mobile']),
      grade: get(currentMember, ['user', 'grade']),
      avatar: get(currentMember, ['user', 'avatar']),
    };
  }
);
