import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { PUT, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { UPDATE_ASSIGNMENT } from '../../../actions';

export const updateAssignment: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === UPDATE_ASSIGNMENT.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: PUT,
          url: `${ServicesURLs.assignments}/${_id}`,
          key: key || 'updateAssignment',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: UPDATE_ASSIGNMENT.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
