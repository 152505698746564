import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_UNIT = createActionType('UPDATE_UNIT', 'UNIT');

export const updateUnit = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_UNIT.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
