import { Services } from '../constants';

export const getBaseUrl = (url: string, service: Services) => {
  let baseUrl = `${process.env.REACT_APP_CLASS_API_PATH}${url}`;
  switch (service) {
    case Services.auth:
      baseUrl = `${process.env.REACT_APP_AUTH_API_PATH}${url}`;
      break;
    case Services.communication:
      baseUrl = `${process.env.REACT_APP_COMMUNICATION_API_PATH}${url}`;
      break;
  }
  return baseUrl;
};
