import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_USER_ANNOUNCEMENTS = createActionType(
  'FIND_USER_ANNOUNCEMENTS',
  'ANNOUNCEMENTS'
);

export const findUserAnnouncements = (_id: string, option?: ActionOption) => ({
  type: FIND_USER_ANNOUNCEMENTS.START,
  meta: {
    _id,
    ...option,
  },
});
