import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_WEEK_AT_A_GLANCE = createActionType(
  'GET_WEEK_AT_A_GLANCE',
  'OVERVIEW'
);

export const getWeekAtAGlance = (
  daysInWeek: Date[],
  week: Date | string,
  options?: ActionOption
) => ({
  type: GET_WEEK_AT_A_GLANCE.START,
  meta: {
    ...options,
    daysInWeek,
    week,
  },
});
