import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import {
  CREATE_UNIT,
  DELETE_UNIT,
  DELETE_UNIT_LESSON,
  FIND_UNIT,
  GET_UNIT,
  GROUP_LESSON_BY_UNIT,
  SET_CURRENT_UNIT,
  UPDATE_UNIT,
} from '../../actions';

export interface UnitState {
  units: Record<string, LessonNameSpace.Unit[]> | any;
  unitsById: Record<string, Record<string, LessonNameSpace.Unit>> | any;
  currentUnit: LessonNameSpace.Unit | any;
  groupedLesson: LessonNameSpace.Lesson | any;
}

export const UnitStateDefaultState: UnitState = {
  units: {},
  unitsById: {},
  currentUnit: {},
  groupedLesson: {},
};

const unitReducer = (state = UnitStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_UNIT.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        unitsById: {
          ...state.unitsById,
          [action.key]: byIdFind,
        },
        units: {
          ...state.units,
          [action.key]: Object.values(byIdFind),
        },
        currentUnit: {
          ...state.currentUnit,
          [action.key]: {},
        },
      });
    }
    case SET_CURRENT_UNIT: {
      return {
        ...state,
        currentUnit: {
          ...state.currentUnit,
          [action.key]: action.payload,
        },
      };
    }
    case GET_UNIT.SUCCESS:
    case CREATE_UNIT.SUCCESS:
    case UPDATE_UNIT.SUCCESS: {
      let groupedLesson = [...(state.groupedLesson[action.key] ?? [])];
      const groupedLessonIndex = groupedLesson.findIndex(
        (o) => action.payload?._id && o?._id === action.payload?._id
      );
      if (groupedLessonIndex !== -1) {
        groupedLesson[groupedLessonIndex] = Object.assign(
          {},
          groupedLesson[groupedLessonIndex],
          action.payload
        );
      } else {
        groupedLesson = [action.payload, ...groupedLesson];
      }
      const byIdCreate = {
        ...state.unitsById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        lessonsById: {
          ...state.unitsById,
          [action.key]: byIdCreate,
        },
        units: {
          ...state.units,
          [action.key]: Object.values(byIdCreate),
        },
        currentUnit: {
          ...state.currentUnit,
          [action.key]: payload,
        },
        groupedLesson: {
          ...state.groupedLesson,
          [action.key]: groupedLesson,
        },
      });
    }
    case DELETE_UNIT_LESSON.SUCCESS: {
      const groupedLesson = [...(state.groupedLesson[action.key] ?? [])];
      const unitIndex = groupedLesson.findIndex(
        (value) => value._id === action.meta.unitId
      );
      if (unitIndex !== -1) {
        const unit = groupedLesson[unitIndex];
        const lessons: LessonNameSpace.Lesson[] = unit?.lessons ?? [];
        unit['lessons'] = lessons?.filter(
          (lesson: LessonNameSpace.Lesson) => lesson._id !== payload._id
        );
        groupedLesson[unitIndex] = unit;
      }
      return {
        ...state,
        groupedLesson: { ...state.groupedLesson, [action.key]: groupedLesson },
      };
    }
    case DELETE_UNIT.SUCCESS: {
      const byId = { ...state.unitsById[action.key] };
      delete byId[payload._id];
      const groupedLesson = [...(state.groupedLesson[action.key] ?? [])];
      const groupedLessonIndex = groupedLesson.findIndex(
        (o) => action.payload?._id && o?._id === action.payload?._id
      );
      if (groupedLessonIndex !== -1) {
        groupedLesson.splice(groupedLessonIndex, 1);
      }

      return Object.assign({}, state, {
        unitsById: {
          ...state.unitsById,
          [action.key]: byId,
        },
        units: {
          ...state.units,
          [action.key]: Object.values(byId),
        },
        groupedLesson: {
          ...state.groupedLesson,
          [action.key]: groupedLesson,
        },
      });
    }
    case GROUP_LESSON_BY_UNIT.SUCCESS: {
      return Object.assign({}, state, {
        groupedLesson: {
          ...state.groupedLesson,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default unitReducer;
