import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_SYLLABUS_CATEGORY = createActionType(
  'FIND_SYLLABUS_CATEGORY',
  'SYLLABUS_CATEGORY'
);

export const findSyllabusCategory = (options?: ActionOption) => ({
  type: FIND_SYLLABUS_CATEGORY.START,
  meta: {
    ...options,
  },
});
