import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GOOGLE_IMPORT_GET_COURSES = createActionType(
  'GOOGLE_IMPORT_GET_COURSES',
  'GOOGLE IMPORT'
);

export const getGoogleCourses = (option?: ActionOption) => ({
  type: GOOGLE_IMPORT_GET_COURSES.START,
  meta: {
    ...option,
  },
});
