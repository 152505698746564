import { SVGProps } from 'react';

export const NearPodLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" {...props}>
      <path
        d="M22.379 20.242l1.492-3.023c1.008-2.059 2.984-3.227 5.805-3.227h15.648c.723-.039 1.492.16 2.094.606.445.32.687.847.687 1.41 0 .402-.12.847-.28 1.21H29.757c-1.13 0-1.977.079-2.903 1.813l-.605 1.211L14.918 43.79h-3.95l11.41-23.547M10.805 49.477c-.442-.325-.684-.848-.684-1.41 0-.446.121-.848.324-1.25h4.758c.04 0 .078.038.121.038h20.442c1.129 0 1.976-.082 2.902-1.816l.605-1.207 5.97-12.379.038-.121.645-1.371 1.937-4.074h3.953l-9.8 20.281c-.082.121-.121.285-.243.406l-.078.2C40.684 48.831 38.711 50 35.887 50H12.902c-.765.121-1.492-.082-2.097-.523"
        fill="var(--heading-color)"
      />
      <path
        d="M46.21 52.137c-.081.12-.163.281-.241.402l-.082.203c-1.008 2.055-2.985 3.227-5.805 3.227H24.434a3.297 3.297 0 01-2.094-.606c-.445-.324-.688-.847-.688-1.41 0-.445.121-.848.325-1.25h4.757c.04 0 .082.04.121.04H40.16c1.008 0 1.817-.161 2.703-1.774l.606-1.211 11.37-23.668h3.95L46.211 52.137M59.555 22.824H34.637c-1.29 0-2.137.281-2.942 1.653l-7.14 14.796-2.297 4.637h-3.871l10.16-21.047c1.008-2.058 2.984-3.226 5.808-3.226h22.743c.726-.04 1.492.16 2.097.605.442.324.684.848.684 1.41-.04.364-.16.809-.324 1.172"
        fill="var(--heading-color)"
      />
    </svg>
  );
};
