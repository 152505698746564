import { Divider, FormInstance, Row } from 'antd';
import { GameForm } from './GameForm';
import { ActivityType } from '../../../../../_shared/constants';
import { ConditionalRenderer } from '../../../../_shared';
import { GameTypeSelection } from './GameTypeSelection';

interface ActivityGameProps {
  currentLesson?: LessonNameSpace.Lesson;
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
  activityType: ActivityType;
  form?: FormInstance;
  showGameTypeSelection?: boolean;
}

export const ActivityGame = (props: ActivityGameProps) => {
  const {
    currentLesson,
    activityType,
    onActivityItemSelected,
    showGameTypeSelection = false,
    form,
  } = props;

  return (
    <>
      <Row gutter={[15, 0]}>
        <ConditionalRenderer condition={showGameTypeSelection}>
          <GameTypeSelection
            onActivityItemSelected={onActivityItemSelected}
            activityType={activityType}
          />
          <Divider
            type={'horizontal'}
            style={{
              width: '100%',
              borderColor: 'var(--border)',
            }}
          />
        </ConditionalRenderer>
        <GameForm
          currentLesson={currentLesson}
          activityType={activityType}
          form={form}
        />
      </Row>
    </>
  );
};
