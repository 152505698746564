import {
  Button,
  Form,
  FormInstance,
  FormItemProps,
  InputNumber,
  Select,
  SelectProps,
} from 'antd';
import { useEffect, useState } from 'react';

interface DueDateSelectProps extends FormItemProps {
  form?: FormInstance;
  namePath: string[] | number | string;
  selectProps?: SelectProps<any>;
}
export const DueDateSelect = (props: DueDateSelectProps) => {
  const { form, namePath, selectProps, ...formItemProps } = props;
  const [dueDates, setDefaultDueDates] = useState([
    { label: 'Lesson date', value: 0 },
    { label: 'Day after lesson', value: 1 },
    { label: '2 days after lesson', value: 2 },
    { label: '3 days after lesson', value: 3 },
    { label: '4 days after lesson', value: 4 },
    { label: '5 days after lesson', value: 5 },
  ]);

  const [customDueDate, setCustomDueDate] = useState(0);

  const onDueDateItemAdded = (initialDueDate?: number) => {
    const newDueDate = {
      label: `${initialDueDate ?? customDueDate} days after lesson`,
      value: initialDueDate ?? customDueDate,
    };

    setDefaultDueDates((prev) => {
      const isExisting = prev.find((o) => o.value === newDueDate.value);
      if (isExisting) return prev;
      return prev
        .concat(newDueDate)
        .sort((a, b) => (b.value > a.value ? -1 : 1));
    });
  };

  const initialDueDate = form?.getFieldValue(namePath);

  useEffect(() => {
    if (initialDueDate) {
      onDueDateItemAdded(Number(initialDueDate));
    }
  }, [initialDueDate, namePath]);

  return (
    <Form.Item {...formItemProps} data-testid={'due-date-form-item'}>
      <Select
        {...selectProps}
        placeholder="Select Due Date"
        data-testid={'due-date-add-select'}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div className={'stm-flex stm-pa-2 stm-items-center'}>
              <InputNumber
                style={{ flex: 'auto' }}
                value={customDueDate}
                size={'small'}
                data-testid={'due-date-add-input'}
                onChange={(value) => setCustomDueDate(value)}
              />
              <div className="stm-pl-3">
                <Button
                  type={'primary'}
                  size={'small'}
                  data-testid={'due-date-add-btn'}
                  onClick={() => onDueDateItemAdded()}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        )}
        options={dueDates}
      />
    </Form.Item>
  );
};
