import { ActivityQuestion } from '../ActivityQuestion';
import { ActivityGame } from '../ActivityGame';
import { FormInstance } from 'antd';
import { ActivityNearPod } from '../ActivityNearPod';
import { ActivityCurriki } from '../ActivityCurriki';
import { ActivityType } from '../../../../../_shared/constants';
import { ActivityExternalLink } from '../ActivityExternalLink';

export const ActivityTypeToName = Object.freeze({
  [ActivityType.Question]: 'Question',
  [ActivityType.ObbyGame]: 'Obby Game',
  [ActivityType.RacingGame]: 'Racing Game',
  [ActivityType.PlotActivity]: 'Plot Activity',
  [ActivityType.Project]: 'Project',
  [ActivityType.Curriki]: 'Curriki',
  [ActivityType.NearPod]: 'NearPod',
  [ActivityType.ExternalLink]: 'External Link',
});

export const ActivityTypeToSelect = Object.freeze({
  [ActivityType.Question]: 'Question',
  [ActivityType.ObbyGame]: 'Obby Game',
  [ActivityType.RacingGame]: 'Racing Game',
  [ActivityType.PlotActivity]: 'Plot Activity',
  [ActivityType.Project]: 'Project',
  [ActivityType.Curriki]: 'Curriki',
  [ActivityType.NearPod]: 'NearPod',
  [ActivityType.ExternalLink]: 'External Link',
});

export interface ActiveTypeEnumeratorProps {
  activityType: ActivityType;
  form: FormInstance;
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
  currentLesson?: LessonNameSpace.Lesson;
}
export const ActiveTypeEnumerator = (props: ActiveTypeEnumeratorProps) => {
  const { activityType, form, onActivityItemSelected, currentLesson } = props;

  switch (activityType) {
    case ActivityType.ObbyGame:
    case ActivityType.RacingGame:
    case ActivityType.PlotActivity:
      return (
        <ActivityGame
          onActivityItemSelected={onActivityItemSelected}
          activityType={activityType}
          form={form}
          currentLesson={currentLesson}
        />
      );
    case ActivityType.Question:
      return <ActivityQuestion currentLesson={currentLesson} form={form} />;
    case ActivityType.NearPod: {
      return <ActivityNearPod form={form} />;
    }
    case ActivityType.Curriki: {
      return <ActivityCurriki form={form} />;
    }
    case ActivityType.ExternalLink:
      return <ActivityExternalLink />;
    default:
      return <ActivityQuestion currentLesson={currentLesson} form={form} />;
  }
};
