import { createContext, ReactNode, ReactNodeArray } from 'react';
import { useAuth } from '../../hooks';
import { PROVIDER_KEYS } from '../../_shared/constants';
import { getToken } from '../../_shared/utils';

export type AuthProviderStateType = {
  auth: AuthNameSpace.User | undefined;
  getLoggedIn?: (user: any) => void;
  attributes: {
    readonly: boolean;
  };
};
const initialState: AuthProviderStateType = {
  auth: undefined,
  attributes: {
    readonly: false,
  },
};

interface AuthProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const token = getToken();
  const { auth, getLoggedIn } = useAuth({
    doFind: true,
    key: PROVIDER_KEYS.Auth,
  });

  // console.log(`AuthProvider token 2 `, token);

  if (!token || !auth) {
    window.location.assign(
      `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}`
    );
    return <h3>Redirecting...</h3>;
  }

  const contextValue = {
    attributes: {
      readonly:
        auth?.roles?.includes('Principal') && !auth?.roles?.includes('Teacher'),
    },
    auth,
    getLoggedIn,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
