import {apiRequest, FIND_LESSON} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';

export const findLesson: Middleware<unknown, RootState> =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action);
        const {type} = action;
        if (type === FIND_LESSON.START) {
          const {key, ...rest} = action.meta;
          dispatch(
            apiRequest(
              {
                service: Services.class,
                method: GET,
                url: ServicesURLs.lessons,
                key: key || 'findLesson',
                ...rest,
                onSuccess: (data: any) => {
                  dispatch({type: FIND_LESSON.SUCCESS, payload: data, key});
                }
              }
            )
          );
        }
      };
