import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const DELETE_QUESTION = createActionType('DELETE_QUESTION', 'QUESTION');

export const deleteQuestion = (_id: string, options? : ActionOption) => ({
  type: DELETE_QUESTION.START,
  meta: {
    _id,
    ...options
  }
});
