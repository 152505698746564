import { apiRequest, GET_WEEK_AT_A_GLANCE } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const getWeekAtAGlance: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_WEEK_AT_A_GLANCE.START) {
      const { key, daysInWeek, week, ...rest } = action.meta;

      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.lessons}/calendar`,
          key: key || 'getWeekAtAGlance',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GET_WEEK_AT_A_GLANCE.SUCCESS,
              payload: data,
              key,
              meta: {
                daysInWeek,
                week,
              },
            });
          },
        })
      );
    }
  };
