import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_STUDENT_LEARNER_GROUPS = createActionType(
  'GET_STUDENT_LEARNER_GROUPS',
  'LEARNER_GROUPS'
);

export const getStudentLearnerGroup = (
  classId: string,
  options: ActionOption
) => ({
  type: GET_STUDENT_LEARNER_GROUPS.START,
  meta: {
    classId,
    ...options,
  },
});

export default getStudentLearnerGroup;
