export const POST = 'post';
export const PUT = 'put';
export const GET = 'get';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const FILE_UPLOAD_URL = `${process.env.REACT_APP_BASEURL}/api/v1/files`;

export enum APP {
  mainKey = 'main',
}

export enum ActivityType {
  Curriki = 'curriki',
  NearPod = 'nearpod',
  ExternalLink = 'external-link',
  ObbyGame = 'obby-game',
  PlotActivity = 'plot-activity',
  Project = 'project',
  RacingGame = 'racing-game',
  Question = 'question-set',
}

export enum Services {
  auth = 'auth',
  class = 'class',
  communication = 'communication',
  classroom = 'classroom',
  api = 'api',
}

export const THEME_STORAGE_KEY = '@@stm-storage/theme';
export const THEME_STORAGE_SET_KEY = '@@stm-storage/theme-set';
export const AUTH_SERVICE = Services.auth;
export const CLASS_SERVICE = Services.class;
export const COMMUNICATION_SERVICE = Services.communication;

export const BADGE_COLOR_LIST = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];

export enum WEEK_AT_A_GLANCE_COLORS {
  A = 'rgba(156,156,157,0.7)',
  B = 'rgba(243,209,155,0.7)',
  C = 'rgba(156,156,157,0.7)',
  D = 'rgba(243,209,155,0.7)',
  E = 'rgba(156,156,157,0.7)',
  F = 'rgba(243,209,155,0.7)',
  G = 'rgba(156,156,157,0.7)',
  H = 'rgba(243,209,155,0.7)',
  I = 'rgba(156,156,157,0.7)',
  J = 'rgba(202,153,188,0.7)',
  K = 'rgba(186,184,245,0.7)',
  L = 'rgba(202,153,188,0.7)',
  M = 'rgba(186,184,245,0.7)',
  N = 'rgba(202,153,188,0.7)',
  O = 'rgba(186,184,245,0.7)',
  P = 'rgba(202,153,188,0.7)',
  Q = 'rgba(186,184,245,0.7)',
  R = 'rgba(202,153,188,0.7)',
  S = 'rgba(186,184,245,0.7)',
  T = 'rgba(193,234,253,0.7)',
  U = 'rgba(186,184,245,0.7)',
  V = 'rgba(193,234,253,0.7)',
  W = 'rgba(193,234,253,0.7)',
  X = 'rgba(193,234,253,0.7)',
  Y = 'rgba(156,156,157,0.7)',
  Z = 'rgba(193,234,253,0.7)',
}

export const ColorList = [
  '#3e82ff',
  '#c1eafd',
  '#f56a00',
  '#7265e6',
  '#ffbf00',
  '#00a2ae',
  '#9c9c9d',
  '#f3d19b',
  '#ca99bc',
  '#bab8f5',
  '#7b68ed',
];
export enum COLOR_LIST_ALPHA {
  A = '#3e82ff',
  B = '#c1eafd',
  C = '#f56a00',
  D = '#7265e6',
  E = '#ffbf00',
  F = '#00a2ae',
  G = '#9c9c9d',
  H = '#f3d19b',
  I = '#ca99bc',
  J = '#bab8f5',
  K = '#7b68ed',
  L = '#3e82ff',
  M = '#f3d19b',
  N = '#7265e6',
  O = '#ca99bc',
  P = '#f56a00',
  Q = '#ca99bc',
  R = '#f3d19b',
  S = '#f3d19b',
  T = '#9c9c9d',
  U = '#ffbf00',
  V = '#f3d19b',
  W = '#7265e6',
  X = '#00a2ae',
  Y = '#ca99bc',
  Z = '#c1eafd',
}

export const SCORE_TYPE_OPTIONS = [
  {
    label: 'Points',
    value: 'POINTS',
  },
  {
    label: 'Percent',
    value: 'PERCENT',
  },
  {
    label: 'Letter (A - F)',
    value: 'GRADESCALE',
  },
  {
    label: 'Collected Only',
    value: 'COLLECTED',
  },
];

export enum ServicesURLs {
  getLoggedInUser = '/users/me',
  users = '/users',
  classMembers = '/class-members',
  lessons = '/lessons',
  todayLesson = '/lessons/today',
  assignmentsList = '/class-activities/statistics',
  assignments = '/lessons/assignment',
  assigmentSubmissions = '/student-activity-submissions',
  units = '/units',
  standards = '/standards',
  activities = '/class-activities',
  learnerGroups = '/learner-groups',
  studentLearnerGroups = '/student-class-learner-groups',
  playlist = '/playlists',
  questions = '/questions',
  files = '/files',
  announcements = '/announcements',
  attendance = '/attendances',
  comments = '/comments',
  syllabusCategory = '/syllabus-categories',
  classes = '/classes',
  recordings = '/recordings',
  grades = '/final-activity-grades',
  studentSubmission = '/student-question-submissions',
  googleClassRoom = '/google-classroom',
  school = '/schools',
  accounts = '/accounts',
}

export const PLAYLIST_SIDEBAR_ITEMS = [
  {
    title: 'Question Set Activity',
    description: 'Add multiple choice, open answer and more',
    icon: 'Questions',
    activityType: ActivityType.Question,
  },
  {
    title: 'Game Activity',
    description: 'Choose from Car, Obby',
    icon: 'Rocket',
    activityType: ActivityType.RacingGame,
  },
  {
    title: 'Curriki Activity',
    description: 'Attach a Curriki resource',
    icon: 'Nearpod',
    activityType: ActivityType.Curriki,
  },
  {
    title: 'Nearpod Activity',
    description: 'Attach a nearpod resource',
    icon: 'Nearpod',
    activityType: ActivityType.NearPod,
  },
  {
    title: 'External Link Activity',
    description: 'Attach an external link from some of your favorite tools',
    icon: 'Link',
    activityType: ActivityType.ExternalLink,
  },
];

export const PROVIDER_KEYS = Object.freeze({
  Standards: '@@STANDARDS_CONTEXT_PROVIDER',
  Class: '@@CLASS_CONTEXT_PROVIDER',
  Auth: '@@AUTH_CONTEXT_PROVIDER',
});

export const PRELOAD_PARTICLES_OPTIONS_PARTICLES_LIGHT: Record<string, any> = {
  background: {
    color: {
      value: 'var(--background-primary)',
    },
  },
  fpsLimit: 60,
  interactivity: {
    detectsOn: 'canvas',
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.8,
        size: 40,
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 5,
      },
    },
  },
  detectRetina: true,
  particles: {
    color: {
      value: 'rgba(0, 0, 0, 0.7)',
    },
    links: {
      color: 'rgba(0, 0, 0, 0.7)',
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 0.5,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: 'circle',
    },
    size: {
      random: true,
      value: 2.5,
    },
  },
};

export const PRELOAD_PARTICLES_OPTIONS_PARTICLES_DARK: Record<string, any> =
  Object.assign({}, PRELOAD_PARTICLES_OPTIONS_PARTICLES_LIGHT, {
    particles: {
      color: {
        value: '#ffffff',
      },
      links: {
        color: '#ffffff',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: 'none',
        enable: true,
        outMode: 'bounce',
        random: false,
        speed: 0.5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          value_area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: 'circle',
      },
      size: {
        random: true,
        value: 2.5,
      },
    },
  });

export const ATTENDANCE_OPTIONS = [
  { label: 'Present', value: 'P' },
  { label: 'Absent', value: 'A' },
  { label: 'Tardy', value: 'T' },
  // { label: 'Tardy (Excused)', value: 'TE' },
  // { label: 'Parent (Excused)', value: 'PE' },
  // { label: 'School (Excused)', value: 'SE' },
  { label: 'Excused Absence', value: 'EA' },
];
