import './activity-list-item.scss';
import {
  Image,
  LightBulb,
  Questions,
  Rocket,
  VideoCamera,
} from '@stemuli/icons';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { ActivityType } from 'src/_shared/constants';
import { NearPodLogo } from '../../../../../app/assets/images/NearPodLogo';

interface PlayListActivityListItemProps {
  item: {
    title: string;
    description: string;
    icon: string;
    activityType: ActivityType;
  };
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
}

const PlayListActivityListItem = (props: PlayListActivityListItemProps) => {
  const { item, onActivityItemSelected } = props;

  const icons: Record<string, ReactNode> = {
    Rocket: <Rocket color="var(--heading-color)" />,
    VideoCamera: <VideoCamera color="var(--heading-color)" />,
    LightBulb: <LightBulb color="var(--heading-color)" />,
    Link: (
      <span className={'anticon'}>
        <i
          className="ri-links-fill stm-heading-color"
          style={{ fontSize: 28 }}
        />
      </span>
    ),
    Image: <Image height="28px" width="28px" color="var(--heading-color)" />,
    Nearpod: (
      <NearPodLogo height="38px" width="38px" fill={'none'} stroke={'none'} />
    ),
  };

  return (
    <motion.div
      whileHover={{ y: -5 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className="stm-activity-list-item"
      onClick={() => onActivityItemSelected(item.activityType)}
    >
      {icons[item.icon] ?? <Questions color="var(--heading-color)" />}
      <div className="stm-ml-6">
        <h6>{item.title}</h6>
        <p>{item.description}</p>
      </div>
    </motion.div>
  );
};

export default PlayListActivityListItem;
