import {
  findActivityByClass,
  findExitTicketSubmissions,
  getActivity,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';

export type ExitTicketProps = {
  key: string;
  classId: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type ExitTicketType = {
  exitTicketSubmissions: ClassesNamespace.AssignmentSubmission[];
  handleFetch: (options?: ActionOption) => void;
  handleExitTicketSubmission: (_id: string, options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  exitTickets: LessonNameSpace.Activity[];
  currentExitTicket: LessonNameSpace.Activity;
};

export const useExitTicket = ({
  params,
  classId,
  key,
  doFind,
}: ExitTicketProps): ExitTicketType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params: {
      ...params,
      isExitTicket: true,
    },
  };

  const { currentExitTicket, exitTickets, exitTicketSubmissions } = useSelector(
    ({ activity }: RootState) => ({
      currentExitTicket: activity?.currentActivity[key],
      exitTickets: activity?.activities[key] || [],
      exitTicketSubmissions: activity?.exitTicketSubmissions[key] || [],
    })
  );

  useEffect(() => {
    if (doFind && classId) {
      dispatch(
        findActivityByClass(classId, {
          params,
          key,
        })
      );
    }
  }, [doFind, classId]);

  const handleFetch = (options?: ActionOption) => {
    dispatch(
      findActivityByClass(classId, {
        ...defaultOptions,
        ...options,
        params: { ...defaultOptions.params, ...options?.params },
      })
    );
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(
      getActivity(id, {
        ...defaultOptions,
        ...options,
        params: { ...defaultOptions.params, ...options?.params },
      })
    );
  };

  const handleExitTicketSubmission = (
    activityId: string,
    options?: ActionOption
  ) => {
    dispatch(
      findExitTicketSubmissions(activityId, {
        ...defaultOptions,
        ...options,
        params: options?.params,
      })
    );
  };

  return {
    handleFetch,
    handleFetchOne,
    handleExitTicketSubmission,
    currentExitTicket,
    exitTickets,
    exitTicketSubmissions,
  };
};
