import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_DUE_ACTIVITIES = createActionType(
  'GET_DUE_ACTIVITIES',
  'OVERVIEW'
);

export const getDueActivities = (option?: ActionOption) => ({
  type: GET_DUE_ACTIVITIES.START,
  meta: {
    ...option,
  },
});
