import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_SCHOOL_ATTENDANCE = createActionType(
  'GET_SCHOOL_ATTENDANCE',
  'SCHOOL'
);

export const getSchoolAttendance = (
  schoolID: string,
  option?: ActionOption
) => ({
  type: GET_SCHOOL_ATTENDANCE.START,
  meta: {
    schoolID,
    ...option,
  },
});
