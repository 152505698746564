import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_GRADE = createActionType('FIND_GRADE', 'GRADE');

export const findGrade = (classId: string, options?: ActionOption) => ({
  type: FIND_GRADE.START,
  meta: {
    classId,
    ...options,
  },
});
