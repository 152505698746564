import {Action} from '../../types';
import {CREATE_QUESTION, DELETE_QUESTION, FIND_QUESTION, GET_QUESTION, UPDATE_QUESTION} from '../../actions';
import {arrayToById} from '../../../_shared/utils';

export interface QuestionState {
  questions: Record<string, LessonNameSpace.Question[]> | any;
  questionsById: Record<string, Record<string, LessonNameSpace.Question>> | any;
  currentQuestion: LessonNameSpace.Question | any
}

export const QuestionStateDefaultState: QuestionState = {
  questions: {},
  questionsById: {},
  currentQuestion: {}
};

const questionReducer = (state = QuestionStateDefaultState, action: Action) => {
  const {payload} = action;
  switch (action.type) {
    case FIND_QUESTION.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        questionsById: {
          ...state.questionsById,
          [action.key]: byIdFind
        },
        questions: {
          ...state.questions,
          [action.key]: Object.values(byIdFind)
        },
        currentQuestion: {
          ...state.currentQuestion,
          [action.key]: {}
        }
      });
    }
    case GET_QUESTION.SUCCESS:
    case CREATE_QUESTION.SUCCESS:
    case UPDATE_QUESTION.SUCCESS: {
      const byIdCreate = {...state.questionsById[action.key], [payload._id]: payload};
      return Object.assign({}, state, {
        questionsById: {
          ...state.questionsById,
          [action.key]: byIdCreate,
        },
        questions: {
          ...state.questions,
          [action.key]: Object.values(byIdCreate)
        },
        currentQuestion: {
          ...state.currentQuestion,
          [action.key]: payload
        }
      });
    }
    case DELETE_QUESTION.SUCCESS: {
      const byId = {...state.questionsById[action.key]};
      delete byId[payload._id];
      return Object.assign({}, state, {
        questionsById: {
          ...state.questionsById,
          [action.key]: byId,
        },
        questions: {
          ...state.questions,
          [action.key]: Object.values(byId)
        },
        currentQuestion: null
      });
    }
    default:
      return state;
  }
};

export default questionReducer;
