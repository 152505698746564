import { Button, Form, Modal } from 'antd';
import {
  first,
  get,
  has,
  isEmpty,
  isPlainObject,
  isString,
  last,
  map,
  omit,
  pick,
  unionBy,
} from 'lodash';
import { ActivityTypeList } from '../ActivityTypeList';
import './create-playlist.scss';
import { useEffect, useState } from 'react';
import { Activity } from '../Activity';
import { ActivityMenu } from '../ActivityMenu';
import { MOCK_EMPTY_ACTIVITIES } from 'src/_shared/mock';
import {
  useClassMember,
  useLearnerGroup,
  useLocalStorage,
  usePlaylist,
} from 'src/hooks';
import { useSyllabusCategory } from 'src/hooks/useSyllabusCategory';
import { add, differenceInDays } from 'date-fns';
import { transformQuestion } from '../../../_shared';
import {
  LESSON_CLASS_LOCAL_STORAGE_KEY,
  LESSONS_PAGE_KEY,
} from 'src/pages/teacher/lessons/create';
import { ActivityType, PROVIDER_KEYS } from 'src/_shared/constants';
import { prefixHTTPProtocol } from '../../../../_shared/utils';

const onToggleActivityWidth = (activities: any[]) => {
  const activityContainer = document.querySelector(
    'stm-create-lesson-activity'
  );
  const activityMenu = document.querySelector(
    '.stm-create-lesson-activity-menu'
  );

  if (activityContainer) {
    if (isEmpty(activities)) {
      activityContainer.classList.remove('stm-create-lesson-activity-shifted');
    }
    if (!isEmpty(activities)) {
      activityContainer.classList.add('stm-create-lesson-activity-shifted');
    }
  }

  if (activityMenu) {
    if (isEmpty(activities)) {
      activityMenu.classList.add('stm-create-lesson-activity-menu-collapsed');
    }
    if (!isEmpty(activities)) {
      activityMenu.classList.remove(
        'stm-create-lesson-activity-menu-collapsed'
      );
    }
  }
};

interface CreatePlaylistProps {
  visibility: boolean;
  onVisibilityChange: (visibility: boolean) => void;
  currentLesson?: LessonNameSpace.Lesson;
  onSavePlaylist: (payload: any) => void;
  reorderLoading: boolean | undefined;
}

export const LESSON_PLAYLIST_KEY = '@@LESSON_PLAYLIST_KEY';
const CreatePlaylist = (props: CreatePlaylistProps) => {
  const {
    visibility,
    onVisibilityChange,
    currentLesson,
    onSavePlaylist,
    reorderLoading,
  } = props;

  const [form] = Form.useForm();

  const storage = useLocalStorage(LESSON_CLASS_LOCAL_STORAGE_KEY, [], {
    update: LESSON_CLASS_LOCAL_STORAGE_KEY.concat('/UPDATE'),
  });

  const { classList } = useClassMember({
    key: PROVIDER_KEYS.Class,
    doFind: false,
  });

  const { syllabusCategoryList } = useSyllabusCategory({
    key: 'categoryList',
    doFind: true,
    params: {
      // all: true,
    },
  });

  const { learnerGroupList } = useLearnerGroup({
    key: 'learnerGroupList',
    doFind: true,
    params: {
      all: true,
    },
  });

  const { handleCreate: onCreatePlaylist, handleDeletePlaylist } = usePlaylist({
    key: LESSON_PLAYLIST_KEY,
    doFind: true,
    params: {
      population: JSON.stringify(['questions', 'activities']),
    },
    alternateKey: `${LESSONS_PAGE_KEY}/${get(currentLesson, '_id')}`,
  });

  const CREATE_PLAYLIST_ACTIVITY_UI_KEY = '@@CREATE_PLAYLIST_ACTIVITY_UI_KEY';

  const playlist = get(currentLesson, 'playlist', [])[0];
  const persistedLessonClasses = storage.get() ?? [];
  const lessonClasses = unionBy(
    get(currentLesson, 'classes', []),
    persistedLessonClasses,
    'class'
  );
  const activities = get(playlist, 'activities', []);

  const [currentActivityType, setCurrentActivityType] =
    useState<ActivityType | null>(null);

  const [currentActivity, setCurrentActivity] = useState<Record<string, any>>(
    {}
  );

  const onActivityInitialValues = (activity: Record<string, any>) => {
    const activityRecord = pick(activity, [
      'syllabusCategory',
      'gradePoints',
      'gamePoints',
      'scoreType',
      'extraPoints',
      'weight',
      'type',
      'title',
      'description',
      'activityUrl',
      'isGraded',
    ]);
    const activityRecordKeys = Object.keys(activityRecord);

    const classes = map(get(activity, 'classes'), (o) => get(o, 'class'));
    const classesInitialValues = {
      touched: true,
      name: ['activity', 'classes'],
      value: classes,
    };
    const learnerGroups = {
      touched: true,
      name: ['activity', 'learnerGroups'],
      value: map(get(activity, 'learnerGroups', []), (o) => {
        if (isPlainObject(o)) return get(o, '_id');
        if (isString(o)) return o;
      }),
    };
    const standards = {
      touched: true,
      name: ['activity', 'standards'],
      value: map(get(activity, 'standards', []), (o) => {
        if (isPlainObject(o)) return get(o, '_id');
        if (isString(o)) return o;
      }),
    };

    const isGraded = {
      name: ['activity', 'isGraded'],
      value: get(activity, 'gradedActivity') ? true : false,
      touched: true,
    };

    const questionsInitialValues = {
      name: 'questions',
      value: transformQuestion.fromRequest(get(activity, ['questions'])),
      touched: true,
    };
    const getDueDate = () => {
      // Gets the first class in the activity class initial values
      const activityClass = get(activity, 'classes.0');
      // Gets the original class details of the class.
      const startDate = get(
        currentLesson?.classes?.find((o) => o?.class === activityClass?.class),
        'startDate'
      );

      if (activityClass?.dueDate && startDate) {
        // Gets the difference of the original class startDate and the activity class dueDate.
        return differenceInDays(
          new Date(activityClass.dueDate),
          new Date(startDate)
        );
      }
      return undefined;
    };

    const activityInitialValues = activityRecordKeys.map((key: string) => {
      return {
        name: ['activity', key],
        value: get(activityRecord, key),
        touched: true,
      };
    });

    form.setFields([
      ...activityInitialValues,
      questionsInitialValues,
      classesInitialValues,
      learnerGroups,
      standards,
      isGraded,
      { name: ['activity', 'dueDate'], value: getDueDate(), touched: true },
    ]);
  };

  const onActivityTypeSelected = (
    activityType: ActivityType,
    resetForm = true
  ) => {
    setCurrentActivityType(activityType);
    setCurrentActivity({});
    if (resetForm) {
      form.resetFields();
    }
  };

  const onCurrentActivityChange = (activity: Record<string, any>) => {
    form.resetFields();
    setCurrentActivity(activity);
    setCurrentActivityType(get(activity, 'type'));
    onActivityInitialValues(activity);
  };

  const onFormSubmitFinished = (data: any) => {
    if (!isEmpty(data) && has(data, '_id')) {
      onToggleActivityWidth(data);
      const existingActivity = (data?.activities || []).find(
        (activity: Record<string, any>) =>
          currentActivity?._id && activity?._id === currentActivity?._id
      );

      if (existingActivity) {
        setCurrentActivity(existingActivity);
        onActivityInitialValues(existingActivity);
        return;
      }
      onActivityInitialValues(last(get(data, 'activities', [])) ?? {});
      setCurrentActivity(last(get(data, 'activities', [])) ?? {});
      return;
    }
    setCurrentActivity({});
  };

  const onFormSubmit = (values: Record<string, any>) => {
    const getDueDate = () => {
      return (lessonClasses ?? []).map((classItem: Record<string, any>) => {
        const startDate = get(classItem, 'startDate');
        if (startDate) {
          // Gets the difference of the original class startDate and the activity class dueDate.
          return {
            dueDate: add(new Date(startDate), {
              days: parseInt(get(values, 'activity.dueDate', 0)),
            }),
            class: get(classItem, 'class'),
          };
        }
        return {
          dueDate: add(new Date(), {
            days: parseInt(get(values, 'activity.dueDate', 0)),
          }),
          class: get(classItem, 'class'),
        };
      });
    };

    const newCurrentActivity = Object.assign({}, values, {
      activity: {
        ...currentActivity.activity,
        ...values.activity,
        type: currentActivityType,
        classes: getDueDate(),
        ...(values?.activity?.activityUrl
          ? { activityUrl: prefixHTTPProtocol(values.activity.activityUrl) }
          : {}),
      },
      lesson: get(currentLesson, '_id'),
      playlist: {
        ...omit(values.playlist, ['dueDate']),
      },
      questions: transformQuestion.toRequest(get(values, 'questions', [])),
    });

    if (has(playlist, '_id')) {
      newCurrentActivity.playlist._id = get(playlist, '_id');
    }

    if (has(currentActivity, '_id')) {
      newCurrentActivity.activity._id = get(currentActivity, '_id');
    }

    onCreatePlaylist(newCurrentActivity, {
      onFinish: (data) => {
        onFormSubmitFinished(data);
      },
      params: {
        population: JSON.stringify([
          { path: 'activities', populate: ['questions'] },
        ]),
      },
      uiKey: CREATE_PLAYLIST_ACTIVITY_UI_KEY,
    });
  };

  const onRemovePlaylistActivity = () => {
    if (has(currentActivity, '_id')) {
      handleDeletePlaylist(get(playlist, '_id'), get(currentActivity, '_id'), {
        onFinish: (data) => {
          onFormSubmitFinished(data);
        },
        params: {
          population: JSON.stringify([
            { path: 'activities', populate: ['questions'] },
          ]),
        },
        uiKey: `@@LESSON_PLAYLIST_DELETE_ACTIVITY/${get(
          currentActivity,
          '_id'
        )}`,
      });
      return;
    }
    onActivityInitialValues(first(activities) ?? {});
    setCurrentActivity(first(activities) ?? {});
  };

  useEffect(() => {
    if (!isEmpty(activities)) {
      onToggleActivityWidth(activities);
    }
  }, [activities]);

  const onFormModifier = () => {
    const initialActivity = activities[0];
    if (!isEmpty(initialActivity) && isEmpty(currentActivity)) {
      onActivityInitialValues(initialActivity);
      setCurrentActivity(initialActivity);
      setCurrentActivityType(
        get(initialActivity, 'type', ActivityType.Question) as ActivityType
      );
    }
  };

  useEffect(() => {
    onFormModifier();
  }, [playlist]);

  return (
    <Modal
      title="Create Playlist"
      visible={visibility}
      onCancel={() => onVisibilityChange(false)}
      width={'98vw'}
      bodyStyle={{
        height: 'calc(98vh - 120px)',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
      }}
      centered
      closable
      footer={
        <div className="stm-flex stm-items-center w-100 stm-justify-end">
          <Button onClick={() => onVisibilityChange(false)}>Close</Button>
        </div>
      }
      destroyOnClose
    >
      <div className={'stm-lesson-create-playlist'}>
        <ActivityTypeList onActivityTypeSelected={onActivityTypeSelected} />
        <Activity
          onActivityItemSelected={onActivityTypeSelected}
          activityType={currentActivityType}
          onCreateActivity={onFormSubmit}
          currentActivity={currentActivity}
          activities={activities}
          classList={classList}
          syllabusCategoryList={syllabusCategoryList}
          learnerGroupList={learnerGroupList}
          form={form}
          initialValues={MOCK_EMPTY_ACTIVITIES}
          currentLesson={currentLesson}
          onRemovePlaylistActivity={onRemovePlaylistActivity}
        />
        <ActivityMenu
          activities={activities}
          currentActivity={currentActivity}
          onCurrentActivityChange={onCurrentActivityChange}
          onSavePlaylist={onSavePlaylist}
          reorderLoading={reorderLoading}
        />
      </div>
    </Modal>
  );
};

export default CreatePlaylist;
