import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const UPDATE_LESSON = createActionType('UPDATE_LESSON', 'LESSON');

export const updateLesson = (_id: string, payload: Record<string, any>, options?: ActionOption) => ({
  type: UPDATE_LESSON.START,
  meta: {
    ...options,
    _id,
    payload,
  }
});
