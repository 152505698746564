import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_GRADE = createActionType('DELETE_GRADE', 'GRADE');

export const deleteGrade = (_id: string, options?: ActionOption) => ({
  type: DELETE_GRADE.START,
  meta: {
    _id,
    ...options,
  },
});
