import { createSelector } from 'reselect';
import { LearnerGroupState } from '../../redux/reducers/learnerGroup';

const learnerGroups = (learnerGroup: LearnerGroupState, key: string) =>
  learnerGroup?.learnerGroups[key] || [];

export const selectLearnerGroupList = createSelector(
  learnerGroups,
  (group: LessonNameSpace.LearnerGroup[]) => {
    return group.map(({ _id: value, name: label }) => ({ value, label }));
  }
);
