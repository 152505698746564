import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const MODIFY_ASSIGNMENT_SUBMISSION = createActionType(
  'MODIFY_ASSIGNMENT_SUBMISSION',
  'ASSIGNMENTS'
);

export const modifyAssignmentSubmission = (
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: MODIFY_ASSIGNMENT_SUBMISSION.START,
  meta: {
    payload,
    ...option,
  },
});
