import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_RECORDING = createActionType('GET_RECORDING', 'RECORDING');

export const getRecording = (_id: string, option?: ActionOption) => ({
  type: GET_RECORDING.START,
  meta: {
    _id,
    ...option,
  },
});
