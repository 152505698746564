import { findActivity } from './findActivity';
import { createActivity } from './createActivity';
import { getActivity } from './getActivity';
import { deleteActivity } from './deleteActivity';
import { updateActivity } from './updateActivity';
import { findActivityByClass } from './findActivityByClass';
import { findExitTicketSubmissions } from './findExitTicketSubmissions';

export default [
  findActivity,
  findActivityByClass,
  createActivity,
  getActivity,
  deleteActivity,
  updateActivity,
  findExitTicketSubmissions,
];
