import { Button, Form, FormInstance, Input, Modal, Select } from 'antd';

interface CreateUnitFormProps {
  showCreateUnit: boolean;
  setShowCreateUnit: (bool: boolean) => void;
  loading: boolean | undefined;
  onCreateUnit: (payload: Record<string, any>) => void;
  classList: Record<string, any>[];
  form: FormInstance;
}

export const CreateUnitForm = (props: CreateUnitFormProps) => {
  const {
    showCreateUnit,
    setShowCreateUnit,
    loading,
    onCreateUnit,
    classList,
    form,
  } = props;

  return (
    <Modal
      title={'Create Unit'}
      visible={showCreateUnit}
      onCancel={() => {
        setShowCreateUnit(false);
      }}
      bodyStyle={{
        padding: 0,
      }}
      // centered
      closable
      footer={null}
    >
      <div className="stm-pa-5">
        <Form
          layout={'vertical'}
          name="@@syllabus-add-unit"
          onFinish={onCreateUnit}
          form={form}
        >
          <Form.Item
            name="title"
            label="Unit title"
            rules={[{ required: true, message: 'Missing unit title' }]}
          >
            <Input placeholder={'Enter unit title'} />
          </Form.Item>
          <Form.Item
            name="class"
            label="Unit Class"
            rules={[{ required: true, message: 'Missing class' }]}
          >
            <Select placeholder="Select unit class" allowClear>
              {classList.map((singleClass: any) => (
                <Select.Option key={singleClass._id} value={singleClass._id}>
                  {singleClass.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type={'primary'}
              onClick={() => form.submit()}
              loading={loading}
              size="small"
            >
              Create Unit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
