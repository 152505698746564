import './form-bordered-button.scss';
import { Button, ButtonProps } from 'antd';

interface FormBorderedButtonProps {
  onButtonClick: () => void;
  buttonProps: ButtonProps;
  description: string;
}
export const FormBorderedButton = (props: FormBorderedButtonProps) => {
  const { buttonProps, onButtonClick, description } = props;
  return (
    <div className="stm-create-lesson-form-bordered-btn-container stm-mb-5">
      <Button type={'primary'} {...buttonProps} onClick={onButtonClick} />
      <span className="stm-create-less-form-bordered-btn-description stm-mt-5">
        {description}
      </span>
    </div>
  );
};
