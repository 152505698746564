import { ActionOption, RootState } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAssignment,
  deleteAssignment,
  findAssignments,
  getAssignment,
  setCurrentAssignment,
  updateAssignment,
} from '../../redux/actions';
import { get as LodashGet } from 'lodash';
import { useEffect } from 'react';

export type UseAssignmentsParameters = {
  initialKey?: string;
  classId: string;
  initialOptions?: Record<string, any>;
};

export interface UseAssignments {
  readonly setCurrent: (
    assignment: ClassesNamespace.Assignments | object,
    key: string
  ) => void;
  readonly create: (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly update: (
    _id: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly findAll: (
    classId: string | undefined,
    key: string,
    options?: ActionOption
  ) => void;
  readonly findOne: (_id: string, key: string, options?: ActionOption) => void;
  readonly deleteOne: (
    _id: string,
    key: string,
    options?: ActionOption
  ) => void;
  readonly get: (key: string) => {
    readonly assignmentList: ClassesNamespace.Assignments[];
    readonly assignmentsById: Record<string, ClassesNamespace.Assignments>;
    readonly current?: ClassesNamespace.Assignments | null;
  };
  readonly metadata: (key: string) => {
    loading: boolean;
    pagination: Record<string, any>;
  };
}
export const useAssignments = (
  props?: UseAssignmentsParameters
): UseAssignments => {
  const { initialKey, initialOptions, classId } = props ?? {};
  const dispatch = useDispatch();

  const {
    currentAssignments,
    assignments,
    assignmentsById,
    uiLoaders,
    pagination,
  } = useSelector(({ assignments, ui }: RootState) => ({
    currentAssignments: assignments?.currentAssignments,
    assignments: assignments?.assignments,
    assignmentsById: assignments?.assignmentById,
    uiLoaders: ui?.uiLoaders,
    pagination: ui?.pagination,
  }));

  const create = (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(createAssignment(payload, { ...options, key }));
  };

  const update = (
    id: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(updateAssignment(id, payload, { ...options, key }));
  };

  const deleteOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(deleteAssignment(id, { ...options, key }));
  };

  const findAll = (
    classId: string | undefined,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(findAssignments(classId, { ...options, key }));
  };

  const findOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(getAssignment(id, { ...options, key }));
  };

  const setCurrent = (
    assignment: ClassesNamespace.Assignments | object,
    key: string
  ) => {
    dispatch(setCurrentAssignment(assignment, key));
  };

  const get = (key: string) => {
    return {
      current: LodashGet<
        Record<string, ClassesNamespace.Assignments>,
        string,
        ClassesNamespace.Assignments | null | undefined
      >(currentAssignments, key, null),
      assignmentList: LodashGet<
        Record<string, ClassesNamespace.Assignments[]>,
        string,
        ClassesNamespace.Assignments[]
      >(assignments, key, []),
      assignmentsById: LodashGet<
        Record<string, Record<string, ClassesNamespace.Assignments>>,
        string,
        Record<string, ClassesNamespace.Assignments>
      >(assignmentsById, key, {}),
    };
  };
  const metadata = (key: string) => ({
    loading: LodashGet(uiLoaders, key, false),
    pagination: LodashGet(pagination, key, {}),
  });

  useEffect(() => {
    if (initialKey) {
      console.log('initialKEYS:::::::::::', initialKey);
      findAll(
        initialOptions?.classId,
        initialKey,
        Object.assign({}, initialOptions)
      );
    }
  }, [classId]);

  return {
    findOne,
    findAll,
    get,
    deleteOne,
    update,
    create,
    metadata,
    setCurrent,
  };
};
