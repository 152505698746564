import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GOOGLE_ACCESS_PROMPT = createActionType(
  'GOOGLE_ACCESS_PROMPT',
  'GOOGLE IMPORT'
);

export const googleAccessPrompt = (
  redirectUrl: string,
  option?: ActionOption
) => ({
  type: GOOGLE_ACCESS_PROMPT.START,
  meta: {
    payload: { redirectUrl },
    ...option,
  },
});
