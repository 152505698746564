import { useLocation } from '@reach/router';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { isEmpty } from 'lodash';
import { parse } from 'query-string';
import { useState } from 'react';

const { Option } = Select;

interface FormLessonTitleSectionProps {
  units: any[];
  setShowCreateUnit: (bool: boolean) => void;
}

export const FormLessonTitleSection = (props: FormLessonTitleSectionProps) => {
  const { units, setShowCreateUnit } = props;
  const location = useLocation();
  const queryString = parse(location?.search);

  const [isUnitDropdownVisible, setUnitDropdownVisibility] = useState(false);

  return (
    <Row gutter={[32, 16]}>
      <Col sm={24} md={24} lg={16} xl={16} xxl={16}>
        <Form.Item
          name="title"
          label={
            <span className="stm-create-lesson-form-section-title">
              Lesson Title
            </span>
          }
          rules={[{ required: true, message: 'Please enter lesson title' }]}
        >
          <Input placeholder="Please enter lesson title" />
        </Form.Item>
      </Col>
      <Col sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Form.Item
          name="unit"
          label={
            <span className="stm-create-lesson-form-section-title">Unit</span>
          }
          rules={[{ required: true, message: 'Please select a unit' }]}
        >
          <Select
            disabled={!isEmpty(queryString?.unitId)}
            style={{ width: '100%' }}
            placeholder="Please select a unit"
            open={isUnitDropdownVisible}
            aria-expanded={isUnitDropdownVisible}
            onDropdownVisibleChange={setUnitDropdownVisibility}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div className="stm-flex stm-justify-end stm-pa-2">
                  <Button
                    type="primary"
                    onClick={() => setShowCreateUnit(true)}
                  >
                    Add New Unit
                  </Button>
                </div>
              </div>
            )}
            data-testid="select"
          >
            {units.map((unit) => (
              <Option key={unit.value} value={unit.value} data-testid="option1">
                {unit.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};
