import ScrollBar from 'react-perfect-scrollbar';
import { Button, List, Tooltip } from 'antd';
import { UploadResourceIcon } from '../UploadResourceIcon';
import { AnimatePresence, motion } from 'framer-motion';

type UploadVideoOrResourceListType = {
  fileData: Array<Record<string, any>>;
  handleRemoveFile: (url: string, id: string, data?: string) => void;
  submitType?: string;
};

const UploadVideoOrResourceList = (props: UploadVideoOrResourceListType) => {
  const { fileData, handleRemoveFile } = props;
  console.log('fileData:::::::', fileData);
  return (
    <AnimatePresence>
      {!!fileData?.length && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={'lesson-list-div'}
          layout
          data-testid={'resource-list'}
        >
          <ScrollBar style={{ height: '100%' }}>
            <List
              size="large"
              className={'lesson-list'}
              dataSource={fileData}
              renderItem={(item: Record<string, any>) => (
                <List.Item>
                  <div
                    className={
                      'stm-flex stm-items-center stm-justify-between w-100'
                    }
                    data-testid={'resource-item'}
                  >
                    <div className={'stm-flex stm-items-center'}>
                      <UploadResourceIcon
                        fileType={
                          item.mimeType ||
                          item.type ||
                          (item.data ? 'link' : undefined)
                        }
                      />
                      <Tooltip title={'Download'}>
                        <a
                          href={item.url}
                          target={'_blank'}
                          rel={'noreferrer'}
                          className={'stm-pl-3'}
                          data-testid={'resource-download-link'}
                        >
                          {item.title ?? item.name ?? item.url ?? item.data}
                        </a>
                      </Tooltip>
                    </div>
                    <Tooltip title={'Remove file'}>
                      <Button
                        shape={'circle'}
                        type={'text'}
                        onClick={() =>
                          handleRemoveFile(item.url, item._id, item.data)
                        }
                        data-testid={'resource-remove-button'}
                        icon={
                          <span className="anticon">
                            <i className="ri-close-line" />
                          </span>
                        }
                      />
                    </Tooltip>
                  </div>
                </List.Item>
              )}
            />
          </ScrollBar>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UploadVideoOrResourceList;
