import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_STANDARD = createActionType('DELETE_STANDARD', 'STANDARD');

export const deleteStandard = (_id: string, options?: ActionOption) => ({
  type: DELETE_STANDARD.START,
  meta: {
    _id,
    ...options,
  },
});
