import {
  GOOGLE_IMPORT_GET_AUTH,
  GOOGLE_IMPORT_GET_COURSE_WORKS,
  GOOGLE_IMPORT_GET_COURSES,
} from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import { get } from 'lodash';

export interface GoogleImportState {
  courses: Record<string, ClassesNamespace.GoogleCourseObject[]>;
  coursesById: Record<
    string,
    Record<string, ClassesNamespace.GoogleCourseObject>
  >;
  courseWorks: Record<string, ClassesNamespace.GoogleCourseWorkObject[]>;
  courseWorksById: Record<
    string,
    Record<string, ClassesNamespace.GoogleCourseWorkObject>
  >;
  topics: Record<string, ClassesNamespace.GoogleTopicObject[]>;
  topicsById: Record<
    string,
    Record<string, ClassesNamespace.GoogleTopicObject>
  >;
  auth: ClassesNamespace.GoogleAuthObject | null;
}

export const GoogleImportStateDefaultState: GoogleImportState = {
  courses: {},
  courseWorks: {},
  topics: {},
  topicsById: {},
  coursesById: {},
  courseWorksById: {},
  auth: null,
};

const googleImportReducer = (
  state = GoogleImportStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case GOOGLE_IMPORT_GET_AUTH.SUCCESS: {
      return {
        ...state,
        auth: payload,
      };
    }
    case GOOGLE_IMPORT_GET_COURSES.SUCCESS: {
      const byIdFind = arrayToById(payload ?? [], 'id');
      return Object.assign({}, state, {
        courses: {
          ...state.courses,
          [action.key]: payload ?? [],
        },
        coursesById: {
          ...state.coursesById,
          [action.key]: byIdFind,
        },
      });
    }
    case GOOGLE_IMPORT_GET_COURSE_WORKS.SUCCESS: {
      const byCourseWorkIdFind = arrayToById(
        get(payload, 'courseWorks', []),
        'id'
      );
      const byTopicFind = arrayToById(get(payload, 'topics', []), 'topicId');
      return Object.assign({}, state, {
        courseWorks: {
          ...state.courseWorks,
          [action.key]: get(payload, 'courseWorks', []),
        },
        topics: {
          ...state.topics,
          [action.key]: get(payload, 'topics', []),
        },
        courseWorksById: {
          ...state.courseWorksById,
          [action.key]: byCourseWorkIdFind,
        },
        topicsById: {
          ...state.topicsById,
          [action.key]: byTopicFind,
        },
      });
    }
    default:
      return state;
  }
};

export default googleImportReducer;
