import { createActionString } from '../../../../_shared/utils';

export const SET_CURRENT_GRADE = createActionString(
  'SET_CURRENT_GRADE',
  'GRADE'
);

export const setCurrentGrade = (payload: Record<string, any>, key: string) => ({
  type: SET_CURRENT_GRADE,
  payload,
  key,
});
