import { createActionString } from '../../../../_shared/utils';

export const SET_CURRENT_ANNOUNCEMENT = createActionString(
  'SET_CURRENT_ANNOUNCEMENT',
  'ANNOUNCEMENTS'
);

export const setCurrentAnnouncement = (
  announcement: ClassesNamespace.Announcements | object,
  key: string
) => ({
  type: SET_CURRENT_ANNOUNCEMENT,
  payload: announcement,
  key,
});
