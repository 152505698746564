import { FIND_LEARNER_GROUPS, GET_STUDENT_LEARNER_GROUPS } from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';

export interface LearnerGroupState {
  learnerGroups: Record<string, LessonNameSpace.LearnerGroup[]>;
  learnerGroupsById: Record<
    string,
    Record<string, LessonNameSpace.LearnerGroup>
  >;
  studentLearnerGroups: Record<string, LessonNameSpace.StudentLearnerGroup[]>;
}

export const LearnerGroupStateDefaultState: LearnerGroupState = {
  learnerGroups: {},
  learnerGroupsById: {},
  studentLearnerGroups: {},
};

const learnerGroupReducer = (
  state = LearnerGroupStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_LEARNER_GROUPS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        learnerGroupsById: {
          ...state.learnerGroupsById,
          [action.key]: byIdFind,
        },
        learnerGroups: {
          ...state.learnerGroups,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    case GET_STUDENT_LEARNER_GROUPS.SUCCESS: {
      console.log('GET_STUDENT_LEARNER_GROUPS :::::: ', payload);
      return Object.assign({}, state, {
        studentLearnerGroups: {
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default learnerGroupReducer;
