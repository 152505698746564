import { AnimatePresence, motion } from 'framer-motion';
import './preload.scss';
import { ReactNode, ReactNodeArray, useEffect, useState } from 'react';
import Particles from 'react-tsparticles';
import {
  PRELOAD_PARTICLES_OPTIONS_PARTICLES_DARK,
  PRELOAD_PARTICLES_OPTIONS_PARTICLES_LIGHT,
  PROVIDER_KEYS,
} from '../../../_shared/constants';
import { useTheme, useUIState } from '../../../hooks';
import { delay } from 'lodash';

interface PreloadProps {
  children: ReactNode | ReactNodeArray;
}

export const StemuliPreload = (props: PreloadProps) => {
  const { children } = props;
  const theme = useTheme();
  const [customLoading, setCustomLoading] = useState(true);

  const {
    uiLoaders: {
      [PROVIDER_KEYS.Class]: loadingClasses,
      [PROVIDER_KEYS.Standards]: loadingStandards,
      [PROVIDER_KEYS.Auth]: loadingUser,
    },
  } = useUIState();

  // const TIMEOUT = process.env.NODE_ENV === 'development' ? 1000 : 5000;
  const onExpireLoader = () => {
    delay(() => {
      setCustomLoading(false);
    }, 5000);
  };

  useEffect(() => {
    theme.loadAndObserve();
  }, []);

  useEffect(() => {
    if (!loadingUser && !loadingClasses && !loadingStandards) {
      onExpireLoader();
    }
  }, []);

  if (customLoading) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          exit={{ opacity: 0 }}
          className={'stm-layout-preload'}
        >
          {theme.value === 'dark' && (
            <Particles
              id="tsparticles"
              options={PRELOAD_PARTICLES_OPTIONS_PARTICLES_DARK}
            />
          )}
          {theme.value === 'light' && (
            <Particles
              id="tsparticles"
              options={PRELOAD_PARTICLES_OPTIONS_PARTICLES_LIGHT}
            />
          )}
          <div
            className={
              'stm-layout-preload-svg-container stm-flex stm-items-center stm-justify-center w-100 h-100'
            }
          >
            <svg viewBox="0 0 1280 720" className={'stm-layout-preload-svg'}>
              <text textAnchor="middle" x="50%" y="50%">
                Stemuli
              </text>
            </svg>

            <div className="stm-layout-preload-loader">
              <div className="stm-flex stm-flex-col stm-justify-center">
                <span className={'anticon anticon-loading anticon-spin'}>
                  <i className="ri-loader-5-line" style={{ fontSize: 80 }} />
                </span>
                <span className="stm-pt-5 stm-fs-text-1">
                  Getting you ready...
                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.25 }}
    >
      {children}
    </motion.div>
  );
};
