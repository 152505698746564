import { Fragment, ReactNode, ReactNodeArray } from 'react';
import { notification } from 'antd';
import { Detector } from 'react-detect-offline';

interface NetworkOverlayProps {
  children: ReactNode | ReactNodeArray;
}

export const NetworkOverlay = (props: NetworkOverlayProps) => {
  const { children } = props;

  const openNotConnectionNotification = () => {
    notification.open({
      message: (
        <span
          className={
            'stm-fs-text-1 stm-fw-2 stm-heading-color stm-flex stm-items-center'
          }
        >
          You're currently offline.{' '}
          <a
            className={'stm-pl-5'}
            style={{ margin: 0 }}
            onClick={() => window.location.reload()}
          >
            Refresh
          </a>
        </span>
      ),
      duration: 60 * 60 * 24,
      placement: 'topLeft',
      icon: (
        <span className={'anticon'}>
          <i
            className="ri-wifi-off-line stm-warning-color"
            style={{ fontSize: 20 }}
          />
        </span>
      ),
      // closeIcon: null,
      key: '@@STM-INTERNET_NOTIFICATION',
    });
  };

  const openConnectionRestoredNotification = () => {
    notification.open({
      message: (
        <span className={'stm-fs-text-1 stm-fw-3 stm-heading-color'}>
          Your internet connection was restored.
        </span>
      ),
      duration: 10,
      placement: 'topLeft',
      icon: (
        <span className={'anticon'}>
          <i
            className="ri-wifi-line stm-success-color"
            style={{ fontSize: 20 }}
          />
        </span>
      ),
      key: '@@STM-INTERNET_NOTIFICATION',
    });
  };

  const onNetWorkChange = (online: boolean) => {
    if (!online) {
      openNotConnectionNotification();
      return;
    }
    openConnectionRestoredNotification();
  };

  return (
    <Detector
      onChange={onNetWorkChange}
      render={() => <Fragment>{children}</Fragment>}
    />
  );
};
