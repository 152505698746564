import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Tooltip,
} from 'antd';
import { motion } from 'framer-motion';
import UUID from 'uuidjs';

export interface QuestionOptionsProps {
  questionType: string;
  name: string | number;
  optionsLength?: {
    min?: number;
    max?: number;
  };
}
export const QuestionOptions = (props: QuestionOptionsProps) => {
  const { questionType, name, optionsLength: _optionsLength } = props;
  const optionsLength = Object.assign(
    {},
    _optionsLength,
    questionType === 'TrueFalse' && { min: 2, max: 2 }
  );

  return (
    <Form.Item name={[name, 'singleChoiceAnswer']} noStyle>
      <Radio.Group style={{ width: '100%' }}>
        <Form.List name={[name, 'options']}>
          {(fields, { add, remove }) => (
            <>
              {!!fields.length && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={
                    'stm-create-lesson-activity-question-option-header stm-pl-7 stm-pb-5'
                  }
                >
                  Options
                </motion.div>
              )}

              {(fields ?? []).map(
                ({ key, name, ...restField }, index) => {
                  const fieldKey = name;
                  const optionIndex = String.fromCharCode(97 + index);
                  return (
                    <motion.div
                      key={key + index}
                      initial={{ opacity: 0, x: index !== 0 ? -100 : 0 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 100 }}
                      transition={{ duration: 0.25 }}
                      className={'stm-pl-7'}
                      data-testid={'question-option'}
                    >
                      <Row gutter={[15, 15]}>
                        <Col span={1}>
                          <Form.Item noStyle>
                            <Space align={'center'}>
                              <span
                                className={
                                  'stm-create-lesson-activity-question-option-index'
                                }
                                data-testid={'question-option-index-alpha'}
                              >
                                {optionIndex}.
                              </span>
                            </Space>
                          </Form.Item>
                        </Col>
                        <Col span={13}>
                          <Form.Item
                            {...restField}
                            name={[name, 'text']}
                            fieldKey={[fieldKey, 'text']}
                            rules={[
                              { required: true, message: 'Missing option' },
                            ]}
                          >
                            <Input
                              style={{ width: '100%' }}
                              readOnly={questionType === 'TrueFalse'}
                              placeholder={'Enter option...'}
                              data-testid={'question-option-input'}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          {(questionType === 'SingleAnswer' ||
                            questionType === 'TrueFalse') && (
                            <Form.Item>
                              <Radio
                                data-testid={'question-option-radio'}
                                value={index}
                              >
                                Correct Answer
                              </Radio>
                            </Form.Item>
                          )}

                          {questionType === 'MultipleAnswers' && (
                            <Form.Item
                              {...restField}
                              name={[name, 'isCorrect']}
                              fieldKey={[fieldKey, 'isCorrect']}
                              valuePropName={'checked'}
                            >
                              <Checkbox
                                data-testid={'question-option-checkbox'}
                              >
                                Correct answer
                              </Checkbox>
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={2}>
                          <Form.Item>
                            <Tooltip title={'Remove option'} placement={'left'}>
                              <motion.div
                                whileTap={{ scale: 0.85 }}
                                key={'@@remove/' + key + index}
                                className="stm-flex stm-justify-end stm-align-center"
                              >
                                <Button
                                  type={'link'}
                                  shape={'circle'}
                                  size={'large'}
                                  icon={
                                    <span className="anticon">
                                      <i className="ri-close-circle-line" />
                                    </span>
                                  }
                                  data-testid={'question-option-remove'}
                                  onClick={() => remove(name)}
                                  disabled={
                                    fields?.length <= (optionsLength?.min ?? 1)
                                  }
                                />
                              </motion.div>
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                    </motion.div>
                  );
                }
              )}
              <Form.Item key={UUID.genV4().toString()}>
                <motion.div
                  className={'stm-pl-7'}
                  transition={{ duration: 0.1 }}
                >
                  <Button
                    type="link"
                    size={'large'}
                    onClick={() => add()}
                    disabled={fields?.length >= (optionsLength?.max ?? 256)}
                    data-testid={'question-option-add'}
                    icon={
                      <span className="anticon">
                        <i className="ri-add-line" />
                      </span>
                    }
                  >
                    Add an option
                  </Button>
                </motion.div>
              </Form.Item>
            </>
          )}
        </Form.List>{' '}
      </Radio.Group>
    </Form.Item>
  );
};
