import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const FIND_QUESTION = createActionType('FIND_QUESTION', 'QUESTION');

export const findQuestion = (options?: ActionOption) => ({
  type: FIND_QUESTION.START,
  meta: {
    ...options,
  }
});
