import {Action} from '../../types';
import {CREATE_PLAYLIST, FIND_PLAYLIST, UPDATE_PLAYLIST} from '../../actions/playlist';
import {arrayToById} from '../../../_shared/utils';

export interface PlaylistState {
  playlists: Record<string, LessonNameSpace.Playlist[]> | any;
  playlistsById: Record<string, Record<string, LessonNameSpace.Playlist>> | any;
  currentPlaylist: LessonNameSpace.Playlist | any
}

export const PlaylistStateDefaultState: PlaylistState = {
  playlists: {},
  playlistsById: {},
  currentPlaylist: {}
};

const playlistReducer = (state = PlaylistStateDefaultState, action: Action) => {
  const {payload} = action;
  switch (action.type) {
    case FIND_PLAYLIST.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        playlistsById: {
          ...state.playlistsById,
          [action.key]: byIdFind
        },
        playlists: {
          ...state.playlists,
          [action.key]: Object.values(byIdFind)
        },
        currentPlaylist: {
          ...state.currentPlaylist,
          [action.key]: {}
        }
      });
    }
    case CREATE_PLAYLIST.SUCCESS:
    case UPDATE_PLAYLIST.SUCCESS: {
      const byIdCreate = {...state.playlistsById[action.key], [payload._id]: payload};
      return Object.assign({}, state, {
        playlistsById: {
          ...state.playlistsById,
          [action.key]: byIdCreate,
        },
        playlists: {
          ...state.playlists,
          [action.key]: Object.values(byIdCreate)
        },
        currentPlaylist: {
          ...state.currentPlaylist,
          [action.key]: payload
        }
      });
    }
    default:
      return state;
  }
};

export default playlistReducer;
