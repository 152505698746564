import { Col, FormInstance, Row } from 'antd';
import { LinkFormItem } from '../../../../_shared/Links/Link';
import { NearPodExternalActivity } from './NearPod';
import { useState } from 'react';

interface ActivityNearPodProps {
  form?: FormInstance;
}

export const ActivityNearPod = (props: ActivityNearPodProps) => {
  const { form } = props;

  const [nearpodVisibility, setNearpodVisibility] = useState(false);

  const onNearPodVisibilityChange = (visibility: boolean) => {
    setNearpodVisibility(visibility);
  };

  const setNearpodURL = (url: string) => {
    if (form) {
      form.setFields([
        { name: ['activity', 'activityUrl'], value: url, touched: true },
      ]);
      onNearPodVisibilityChange(false);
    }
  };

  return (
    <>
      <NearPodExternalActivity
        onVisibilityChange={onNearPodVisibilityChange}
        visibility={nearpodVisibility}
        setNearpodURL={setNearpodURL}
      />
      <Row gutter={[15, 30]}>
        <Col span={24}>
          <LinkFormItem
            formItemProps={{
              name: ['activity', 'activityUrl'],
              label: 'Nearpod URL',
            }}
            inputProps={{
              onClick: () => onNearPodVisibilityChange(true),
              readOnly: true,
              placeholder: 'Click here to add a Nearpod link',
            }}
          />
        </Col>
      </Row>
    </>
  );
};
