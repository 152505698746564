import { isEmpty, isFunction } from 'lodash';
import {
  API_REQUEST,
  setUIPagination,
  startUILoading,
  stopUILoading,
  updateUIError,
} from '../../../actions';
import { createAPIRequest, getBaseUrl } from '../../../../_shared/utils';
import { Dispatch, Middleware } from 'redux';
import { batch } from 'react-redux';
import { RootState } from '../../../types';
import { message } from 'antd';
import errorSound from 'src/app/assets/sounds/error/alert_error.wav';
import successSound from 'src/app/assets/sounds/success/celebration.wav';
import loadingSound from 'src/app/assets/sounds/loading/ui_loading.wav';

export const alertSuccess = (options: {
  message: string;
  key: string;
  sound?: boolean;
}) => {
  message.success({ content: options.message, key: options.key, duration: 6 });

  if (options.sound) {
    const audio = new Audio(successSound);
    audio.play().catch((reason: unknown) => {
      console.error(reason);
    });
  }
};

export const alertLoading = () => {
  const audio = new Audio(loadingSound);
  audio.loop = true;
  audio.play().catch((reason: unknown) => {
    console.error(reason);
  });
  return audio;
};

export const alertError = (options: {
  message: string;
  key: string;
  sound?: boolean;
}) => {
  message.error({ content: options.message, key: options.key, duration: 4 });

  if (options.sound) {
    const audio = new Audio(errorSound);
    audio.play().catch((reason: unknown) => {
      console.error(reason);
    });
  }
};

export const handleErrors = (
  error: any,
  dispatch: Dispatch,
  key: string,
  errorMessage: string,
  sound?: boolean
) => {
  if (!errorMessage) {
    dispatch(updateUIError(key, error?.data?._meta?.error?.message));
    alertError({
      message:
        error?.data?._meta?.error?.message ??
        'There was an error, please try again',
      key,
    });
  } else {
    alertError({ message: errorMessage, key, sound });
    dispatch(updateUIError(key, errorMessage));
  }
};

export const apiRequest: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === API_REQUEST.START) {
      const {
        service,
        method,
        url,
        key,
        uiKey,
        payload,
        onError,
        successMessage,
        params,
        onSuccess,
        errorMessage,
        onFinish,
        noSuccessMessage = false,
        // noErrorMessage,
        metadata = false,
        onAfterError,
        useSound = method !== 'get',
      } = action.meta;
      const baseUrl = getBaseUrl(url, service);
      const config: any = { method, url: baseUrl };
      if (payload && (!isEmpty(payload) || payload instanceof FormData)) {
        config.data = payload;
      }
      if (params && !isEmpty(params)) {
        config.params = params;
      }

      const requestKey = uiKey || key;
      batch(() => {
        dispatch(updateUIError(requestKey, null));
        dispatch(startUILoading(requestKey));
      });
      createAPIRequest(config)
        .then((response: any) => {
          const { data } = response;
          const meta = response?._meta || null;
          batch(() => {
            if (meta && meta.pagination) {
              dispatch(setUIPagination(requestKey, meta.pagination));
            }
            if (onSuccess) {
              if (typeof onSuccess === 'function') {
                if (metadata) {
                  onSuccess(response);
                } else {
                  onSuccess(data);
                }
              } else {
                if (metadata) {
                  dispatch({ type: onSuccess, payload: response });
                } else {
                  dispatch({ type: onSuccess, payload: data });
                }
              }
              if (onFinish && typeof onFinish === 'function') {
                onFinish(data);
              }
            }
            dispatch(stopUILoading(requestKey));

            const notificationMessage = successMessage || meta?.message;
            if (!noSuccessMessage && notificationMessage) {
              // dispatch success message

              alertSuccess({
                message: notificationMessage,
                key,
                sound: useSound,
              });
            }
          });
        })
        .catch((e: any) => {
          console.error(e);
          batch(() => {
            if (onError) {
              if (isFunction(onError)) {
                onError(e);
              } else {
                handleErrors(e, dispatch, requestKey, errorMessage, useSound);
                dispatch({ type: onError, payload: e?.data?._meta });
              }
            } else {
              const error =
                (e && e.data && e.data.meta && e.data.meta.error) ||
                (e && e.error) ||
                e;
              handleErrors(error, dispatch, requestKey, errorMessage, useSound);
            }
            dispatch(stopUILoading(requestKey));
            if (isFunction(onAfterError)) {
              onAfterError(e);
            }
          });
        });
    }
    return next(action);
  };

export default [apiRequest];
