import {findQuestion} from './findQuestion';
import {createQuestion} from './createQuestion';
import {updateQuestion} from './updateQuestion';
import {getQuestion} from './getQuestion';
import {deleteQuestion} from './deleteQuestion';


export default [
  findQuestion,
  createQuestion,
  updateQuestion,
  getQuestion,
  deleteQuestion
];
