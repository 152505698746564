import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { GET_GRADE_LEVELS } from '../../../actions';

export const getGradeLevels: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_GRADE_LEVELS.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.auth,
          method: GET,
          url: `${ServicesURLs.accounts}/${_id}`,
          key: key || 'getGradeLevels',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: GET_GRADE_LEVELS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
