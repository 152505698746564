import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import {
  CREATE_SYLLABUS_CATEGORY,
  DELETE_SYLLABUS_CATEGORY,
  FIND_SYLLABUS_CATEGORY,
  GET_SYLLABUS_CATEGORY,
  UPDATE_SYLLABUS_CATEGORY,
} from '../../actions';

export interface SyllabusCategoryState {
  syllabusCategories: Record<string, ClassesNamespace.SyllabusCategory[]> | any;
  syllabusCategoriesById:
    | Record<string, Record<string, ClassesNamespace.SyllabusCategory>>
    | any;
  currentSyllabusCategory: ClassesNamespace.SyllabusCategory | any;
}

export const SyllabusCategoryStateDefaultState: SyllabusCategoryState = {
  syllabusCategories: {},
  syllabusCategoriesById: {},
  currentSyllabusCategory: {},
};

const syllabusCategoryReducer = (
  state = SyllabusCategoryStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_SYLLABUS_CATEGORY.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        syllabusCategoriesById: {
          ...state.syllabusCategoriesById,
          [action.key]: byIdFind,
        },
        syllabusCategories: {
          ...state.syllabusCategories,
          [action.key]: Object.values(byIdFind),
        },
        currentSyllabusCategory: {
          ...state.currentSyllabusCategory,
          [action.key]: {},
        },
      });
    }
    case GET_SYLLABUS_CATEGORY.SUCCESS:
    case CREATE_SYLLABUS_CATEGORY.SUCCESS:
    case UPDATE_SYLLABUS_CATEGORY.SUCCESS: {
      const byIdCreate = {
        ...state.syllabusCategoriesById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        lessonsById: {
          ...state.syllabusCategoriesById,
          [action.key]: byIdCreate,
        },
        syllabusCategories: {
          ...state.syllabusCategories,
          [action.key]: Object.values(byIdCreate),
        },
        currentSyllabusCategory: {
          ...state.currentSyllabusCategory,
          [action.key]: payload,
        },
      });
    }
    case DELETE_SYLLABUS_CATEGORY.SUCCESS: {
      const byId = { ...state.syllabusCategoriesById[action.key] };
      delete byId[payload._id];
      return Object.assign({}, state, {
        syllabusCategoriesById: {
          ...state.syllabusCategoriesById,
          [action.key]: byId,
        },
        syllabusCategories: {
          ...state.syllabusCategories,
          [action.key]: Object.values(byId),
        },
        currentSyllabusCategory: null,
      });
    }
    default:
      return state;
  }
};

export default syllabusCategoryReducer;
