import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GOOGLE_IMPORT_SYNC_COURSE_WORK = createActionType(
  'GOOGLE_IMPORT_SYNC_COURSE_WORK',
  'GOOGLE IMPORT'
);

export const syncGoogleCourseWork = (
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: GOOGLE_IMPORT_SYNC_COURSE_WORK.START,
  meta: {
    payload,
    ...option,
  },
});
