import { ActionOption, RootState } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnnouncement,
  deleteAnnouncement,
  findAnnouncementComments,
  findAnnouncements,
  getAnnouncement,
  postAnnouncementComments,
  updateAnnouncement,
  findUserAnnouncements,
} from '../../redux/actions';
import { get as LodashGet } from 'lodash';
import { useEffect } from 'react';
import { setCurrentAnnouncement } from '../../redux/actions/announcements/setCurrentAnnouncement';

export type UseAnnouncementsProps = {
  initialKey?: string;
  classId?: string;
  initialOptions?: Record<string, any>;
};

export interface UseAnnouncementType {
  readonly setCurrent: (
    announcement: ClassesNamespace.Announcements | object,
    key: string
  ) => void;
  readonly create: (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly update: (
    _id: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly findAll: (key: string, options?: ActionOption) => void;
  readonly findOne: (_id: string, key: string, options?: ActionOption) => void;
  readonly findAllForUser: (
    _id: string,
    key: string,
    options?: ActionOption
  ) => void;
  readonly deleteOne: (
    _id: string,
    key: string,
    options?: ActionOption
  ) => void;
  readonly get: (key: string) => {
    readonly announcementList: ClassesNamespace.Announcements[];
    readonly announcementsById: Record<string, ClassesNamespace.Announcements>;
    readonly current?: ClassesNamespace.Announcements | null;
    readonly commentsList?: ClassesNamespace.AnnouncementComments[];
  };
  readonly metadata: (key: string) => {
    loading: boolean;
    pagination: Record<string, any>;
  };

  readonly postComment: (
    announcementId: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly getComments: (
    announcementId: string,
    key: string,
    options?: ActionOption
  ) => void;
}

export const useAnnouncements = (
  props?: UseAnnouncementsProps
): UseAnnouncementType => {
  const { initialKey, initialOptions, classId } = props ?? {};
  const dispatch = useDispatch();

  console.log('initialOptions :>>>>>>>>>>>>: ', initialOptions);

  const {
    currentAnnouncements,
    announcements,
    announcementsById,
    uiLoaders,
    pagination,
    comments,
  } = useSelector(({ announcements, ui }: RootState) => ({
    currentAnnouncements: announcements?.currentAnnouncements,
    announcements: announcements?.announcements,
    comments: announcements?.comments,
    announcementsById: announcements?.announcementById,
    uiLoaders: ui?.uiLoaders,
    pagination: ui?.pagination,
  }));

  const create = (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(createAnnouncement(payload, { ...options, key }));
  };

  const update = (
    id: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(updateAnnouncement(id, payload, { ...options, key }));
  };

  const deleteOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(deleteAnnouncement(id, { ...options, key }));
  };

  const findAll = (key: string, options?: ActionOption) => {
    dispatch(findAnnouncements({ ...options, key }));
  };

  const findAllForUser = (id: string, key: string, options?: ActionOption) => {
    dispatch(findUserAnnouncements(id, { ...options, key }));
  };

  const findOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(getAnnouncement(id, { ...options, key }));
  };

  const setCurrent = (
    announcement: ClassesNamespace.Announcements | object,
    key: string
  ) => {
    dispatch(setCurrentAnnouncement(announcement, key));
  };

  const get = (key: string) => {
    return {
      current: LodashGet<
        Record<string, ClassesNamespace.Announcements>,
        string,
        ClassesNamespace.Announcements | null | undefined
      >(currentAnnouncements, key, null),
      announcementList: LodashGet<
        Record<string, ClassesNamespace.Announcements[]>,
        string,
        ClassesNamespace.Announcements[]
      >(announcements, key, []),
      commentsList: LodashGet<
        Record<string, ClassesNamespace.AnnouncementComments[]>,
        string,
        ClassesNamespace.AnnouncementComments[]
      >(comments, key, []),
      announcementsById: LodashGet<
        Record<string, Record<string, ClassesNamespace.Announcements>>,
        string,
        Record<string, ClassesNamespace.Announcements>
      >(announcementsById, key, {}),
    };
  };
  const metadata = (key: string) => ({
    loading: LodashGet(uiLoaders, key, false),
    pagination: LodashGet(pagination, key, {}),
  });

  const postComment = (
    announcementId: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(
      postAnnouncementComments(announcementId, payload, { ...options, key })
    );
  };

  const getComments = (
    announcementId: string,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(findAnnouncementComments(announcementId, { ...options, key }));
  };

  useEffect(() => {
    if (initialKey) {
      findAll(initialKey, Object.assign({}, initialOptions));
    }
  }, [classId]);

  return {
    findOne,
    findAll,
    get,
    deleteOne,
    update,
    create,
    metadata,
    setCurrent,
    postComment,
    getComments,
    findAllForUser,
  };
};
