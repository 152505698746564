import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_SYLLABUS_CATEGORY = createActionType(
  'UPDATE_SYLLABUS_CATEGORY',
  'SYLLABUS_CATEGORY'
);

export const updateSyllabusCategory = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_SYLLABUS_CATEGORY.START,
  meta: {
    ...options,
    _id,
    payload,
  },
});
