import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from 'antd';
import {get, has} from 'lodash';
import {motion} from 'framer-motion';
import {ActivityTypeToName} from '../../ActivityType';
import {useEffect, useState} from 'react';
import {ConditionalRenderer, DueDateSelect} from 'src/components/_shared';
import {useUIState} from 'src/hooks';
import {CustomUploadAdapterPlugin} from 'src/_shared/utils';
import {ActivityType, SCORE_TYPE_OPTIONS} from 'src/_shared/constants';
import {GameTypeSelection} from '../../ActivityGame/GameTypeSelection';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@stemuli/ckeditor5-build-classic';

const { Option } = Select;

export interface ActivityFormHeaderProps {
  activityType?: string;
  form?: FormInstance;
  currentActivity: Record<string, any>;
  classList: Record<string, any>[];
  syllabusCategoryList: Record<string, any>[];
  learnerGroupList: { label: string; value: string }[];
  currentLesson?: LessonNameSpace.Lesson;
  onRemove: () => void;
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
}

export const ActivityFormHeader = (props: ActivityFormHeaderProps) => {
  const {
    activityType,
    form,
    currentActivity,
    syllabusCategoryList,
    learnerGroupList,
    onRemove,
    onActivityItemSelected,
  } = props;

  const [isGradedActivity, setIsGradedActivity] = useState<boolean>(true);
  const [isLessonStart, setIsLessonStart] = useState<boolean>(false);

  const onGradedActivityChange = (value: boolean) => {
    setIsGradedActivity(value);
  };

  useEffect(() => {
    const gradedActivity = form?.getFieldValue(['activity', 'isGraded']);
    onGradedActivityChange(gradedActivity || false);
  }, [currentActivity]);

  const {
    uiLoaders: {
      [`@@LESSON_PLAYLIST_DELETE_ACTIVITY/${get(currentActivity, '_id')}`]:
        deletingActivity,
    },
  } = useUIState();

  // const autoCalculateGradePoint = isAutoCalculatedGradePoint(
  //   activityType as ActivityType
  // );

  const isGameActivity =
    [
      ActivityType.PlotActivity,
      ActivityType.ObbyGame,
      ActivityType.RacingGame,
    ].indexOf(activityType as ActivityType) !== -1;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} data-testid={'activity-form-header-test-id'}>
      <Form.Item noStyle>
        <Row
          className="stm-create-lesson-activity-form-container-header stm-mb-7"
          gutter={[0, 15]}
        >
          <Col span={8}>
            <span className="stm-create-lesson-activity-type">
              {get(ActivityTypeToName, [activityType ?? 'Question'])}
            </span>
          </Col>
          <Col span={16}>
            <div className="flex w-full justify-end">
              <Tooltip
                title={`${
                  has(currentActivity, '_id') ? 'Delete' : 'Remove'
                } activity`}
                placement={'leftBottom'}
              >
                <motion.div whileTap={{ scale: 0.95 }} className="ml-3">
                  <Popconfirm
                    placement="bottomRight"
                    title={`Are you sure to ${
                      has(currentActivity, '_id') ? 'delete' : 'remove'
                    } this activity?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={onRemove}
                  >
                    <Button
                      shape={'circle'}
                      type={'text'}
                      danger
                      loading={deletingActivity}
                      data-testid={'activity-form-header-delete-button-test-id'}
                      icon={
                        <span className="anticon">
                          <i className="ri-delete-bin-2-line" />
                        </span>
                      }
                    />
                  </Popconfirm>
                </motion.div>
              </Tooltip>
            </div>
          </Col>
          <ConditionalRenderer
            condition={!has(currentActivity, '_id') && isGameActivity}
          >
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              layout
            >
              <GameTypeSelection
                onActivityItemSelected={onActivityItemSelected}
                activityType={activityType as ActivityType}
              />
            </motion.div>
          </ConditionalRenderer>
        </Row>
      </Form.Item>
      <ConditionalRenderer
        condition={!has(currentActivity, '_id') && isGameActivity}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          layout
        >
          <Form.Item>
            <Divider
              type={'horizontal'}
              style={{
                width: '100%',
                borderColor: 'var(--border)',
                marginBottom: 0,
              }}
            />
          </Form.Item>
        </motion.div>
      </ConditionalRenderer>
      <motion.div>
        <Form.Item noStyle>
          <Row gutter={[15, 0]}>
            <Col span={24}>
              <Form.Item
                name={['activity', 'isGraded']}
                valuePropName="checked"
                style={{ margin: '-10px 0 10px 0' }}
              >
                <Checkbox
                  onChange={(e) => {
                    onGradedActivityChange(e.target.checked);
                  }}
                >
                  Is Graded
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={'Activity Title'}
                name={['activity', 'title']}
                rules={[{ required: true, message: 'Missing activity name' }]}
              >
                <Input placeholder={'Enter activity name'} />
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24}>
              <Form.Item
                label={'Learner Group'}
                name={['activity', 'learnerGroups']}
                initialValue={learnerGroupList.map(({ value }) => value)}
                rules={[{ required: true, message: 'Missing learner group' }]}
              >
                <Select
                  placeholder="Select learner group"
                  className="stm-form-item"
                  mode="multiple"
                  options={learnerGroupList}
                  showArrow
                />
              </Form.Item>
            </Col>
            <Row align={'middle'} className={'w-100'} gutter={[16, 0]}>
              <Col xl={12} lg={12} md={24} sm={24}>
                <DueDateSelect
                  form={form}
                  selectProps={{
                    onChange: (value) => setIsLessonStart(value === 0),
                  }}
                  label={'Due Date'}
                  name={['activity', 'dueDate']}
                  namePath={['activity', 'dueDate']}
                  rules={[{ required: true, message: 'Missing due date' }]}
                />
              </Col>
              {isLessonStart && (
                <Col xl={10} lg={10} md={24} sm={24} className={'stm-mt-6'}>
                  <Form.Item
                    name={['activity', 'isAssignment']}
                    valuePropName="checked"
                    style={{ margin: '-10px 0 10px 0' }}
                  >
                    <Checkbox>Is Assignment</Checkbox>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Row>
        </Form.Item>
        <Row gutter={[15, 0]}>
          <ConditionalRenderer condition={isGradedActivity}>
            <Col span={24}>
              <Form.Item
                label={'Syllabus Category'}
                name={['activity', 'syllabusCategory']}
              >
                <Select placeholder={'Select Activity Category'} allowClear>
                  {syllabusCategoryList.map((category) => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
              >
                <Form.Item
                  label={'Grade Points'}
                  name={['activity', 'gradePoints']}
                  rules={[{ required: true, message: 'Missing grade points' }]}
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                    placeholder={'Enter points...'}
                  />
                </Form.Item>
              </motion.div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
              >
                <Form.Item
                  label={'Score Type'}
                  name={['activity', 'scoreType']}
                  rules={[{ required: true, message: 'Missing score type' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={'Select type...'}
                    options={SCORE_TYPE_OPTIONS}
                  />
                </Form.Item>
              </motion.div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
              >
                <Form.Item
                  label={'Extra Points'}
                  name={['activity', 'extraPoints']}
                  // rules={[{ required: true, message: 'Missing extra points' }]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={'Enter points...'}
                  />
                </Form.Item>
              </motion.div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <Form.Item
                label={'Weight'}
                name={['activity', 'weight']}
                rules={[{ required: true, message: 'Missing weight' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={'Enter weight...'}
                />
              </Form.Item>
            </Col>
          </ConditionalRenderer>

          <Col span={24}>
            <Form.Item name={['activity', 'instruction']} label={'Instruction'}>
              <CKEditor
                config={{
                  extraPlugins: [CustomUploadAdapterPlugin as any],
                  placeholder: 'Provide an instruction for this activity...',
                }}
                editor={ClassicEditor}
                data={get(currentActivity, 'instruction')}
                onChange={(_, editor) => {
                  const value = editor.getData();
                  if (value && value !== '<p><br></p>') {
                    form?.setFields([
                      { name: ['activity', 'instruction'], value },
                    ]);
                    return;
                  }
                  form?.setFields([
                    { name: ['activity', 'instruction'], value: undefined },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ borderColor: 'var(--border)' }} />
      </motion.div>
    </motion.div>
  );
};
