import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ASSIGNMENT_SUBMISSIONS = createActionType(
  'FIND_ASSIGNMENT_SUBMISSIONS',
  'ASSIGNMENTS'
);

export const findAssignmentSubmissions = (
  assignmentId: string,
  option?: ActionOption
) => ({
  type: FIND_ASSIGNMENT_SUBMISSIONS.START,
  meta: {
    assignmentId,
    ...option,
  },
});
