import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ATTENDANCE_COUNT = createActionType(
  'GET_ATTENDANCE_COUNT',
  'SCHOOL'
);

export const getAttendanceCount = (
  schoolID: string,
  option?: ActionOption
) => ({
  type: GET_ATTENDANCE_COUNT.START,
  meta: {
    schoolID,
    ...option,
  },
});
