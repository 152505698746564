import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_GRADE = createActionType('GET_GRADE', 'GRADE');

export const getGrade = (
  studentId: string,
  classActivityId: string,
  option?: ActionOption
) => ({
  type: GET_GRADE.START,
  meta: {
    studentId,
    classActivityId,
    ...option,
  },
});
