import { createActionString } from '../../../../_shared/utils';

export const SET_CURRENT_ASSIGNMENT_SUBMISSION = createActionString(
  'SET_CURRENT_ASSIGNMENT_SUBMISSION',
  'ASSIGNMENTS'
);

export const setCurrentAssignmentSubmission = (
  assignment: ClassesNamespace.Assignments | object,
  key: string
) => ({
  type: SET_CURRENT_ASSIGNMENT_SUBMISSION,
  payload: assignment,
  key,
});
