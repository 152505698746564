import { combineReducers } from 'redux';
import app from './app';
import ui from './ui';
import lesson from './lesson';
import classMember from './classMember';
import unit from './unit';
import standard from './standard';
import activity from './activity';
import learnerGroup from './learnerGroup';
import playlist from './playlist';
import question from './question';
import announcements from './announcements';
import assignments from './assignments';
import syllabusCategory from './syllabusCategory';
import attendance from './attendance';
import overview from './overview';
import grades from './grades';
import mastery from './mastery';
import recordings from './recording';
import comments from './comments';
import googleImport from './googleImport';
import members from './members';
import notification from './notification';
import school from './school';

const appReducers = combineReducers({
  app,
  ui,
  classMember,
  lesson,
  unit,
  standard,
  activity,
  learnerGroup,
  playlist,
  question,
  announcements,
  assignments,
  syllabusCategory,
  attendance,
  overview,
  grades,
  mastery,
  comments,
  recordings,
  googleImport,
  members,
  notification,
  school,
});

export default appReducers;
