import { apiRequest, GET_MEMBERS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services } from '../../../../_shared/constants';

export const getMembers: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_MEMBERS.START) {
      const { key, userId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.auth,
          method: GET,
          url: `/users/${userId}/profile`,
          key: key || 'getMembers',
          noSuccessMessage: true,
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: GET_MEMBERS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
