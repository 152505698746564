import { getDueActivities } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';

export type UseOverViewDueActivities = {
  key: string;
  classId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseOverViewDueActivitiesReturnType = {
  findAll: (options?: ActionOption) => void;
  dueActivities: ClassesNamespace.Assignments[];
};

export const useOverViewDueActivities = ({
  classId,
  params,
  key,
  doFind,
}: UseOverViewDueActivities): UseOverViewDueActivitiesReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const { dueActivities } = useSelector(({ overview }: RootState) => ({
    dueActivities: overview?.dueActivities[key] ?? [],
  }));

  const findAll = (options?: ActionOption) => {
    dispatch(
      getDueActivities({
        ...defaultOptions,
        ...options,
      })
    );
  };

  useEffect(() => {
    if (doFind) {
      findAll(defaultOptions);
    }
  }, [classId]);

  return {
    findAll,
    dueActivities,
  };
};
