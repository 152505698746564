import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from 'src/redux/types';
import {
  getGoogleAuth,
  getGoogleCourses,
  getGoogleCourseWorks,
  googleAccessPrompt,
  syncGoogleCourseWork,
} from 'src/redux/actions';

export type GoogleImportProps = {
  key: string;
  options?: ActionOption;
};

export type GoogleImportType = {
  getCourses: (options?: ActionOption) => void;
  getAuth: (options?: ActionOption) => void;
  googleAccessPrompt: (redirectUrl: string, options?: ActionOption) => void;
  syncCourseWork: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  getCourseWorks: (courseId: string, options?: ActionOption) => void;
  courses: ClassesNamespace.GoogleCourseObject[];
  topics: ClassesNamespace.GoogleTopicObject[];
  topicsById: Record<string, ClassesNamespace.GoogleTopicObject>;
  courseWorks: ClassesNamespace.GoogleCourseWorkObject[];
  courseWorksById: Record<string, ClassesNamespace.GoogleCourseWorkObject>;
  auth: ClassesNamespace.GoogleAuthObject | null;
};

export const useGoogleImport = ({
  options,
  key,
}: GoogleImportProps): GoogleImportType => {
  const dispatch = useDispatch();

  const { courses, courseWorks, auth, courseWorksById, topics, topicsById } =
    useSelector(({ googleImport }: RootState) => ({
      courses: googleImport?.courses[key],
      courseWorks: googleImport?.courseWorks[key] || [],
      courseWorksById: googleImport?.courseWorksById[key] || [],
      topicsById: googleImport?.topicsById[key] || [],
      topics: googleImport?.topics[key] || [],
      auth: googleImport.auth,
    }));

  const defaultOptions: ActionOption = {
    key,
    ...options,
  };

  const getCourses = (options?: ActionOption) => {
    dispatch(getGoogleCourses({ ...defaultOptions, ...options }));
  };

  const getAuth = (options?: ActionOption) => {
    dispatch(getGoogleAuth({ ...defaultOptions, ...options }));
  };

  const getCourseWorks = (courseId: string, options?: ActionOption) => {
    dispatch(getGoogleCourseWorks(courseId, { ...defaultOptions, ...options }));
  };

  const syncCourseWork = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(syncGoogleCourseWork(payload, { ...defaultOptions, ...options }));
  };
  const _googleAccessPrompt = (redirectUrl: string, options?: ActionOption) => {
    dispatch(
      googleAccessPrompt(redirectUrl, { ...defaultOptions, ...options })
    );
  };

  return {
    syncCourseWork,
    getCourses,
    getCourseWorks,
    googleAccessPrompt: _googleAccessPrompt,
    getAuth,
    courses,
    courseWorks,
    auth,
    courseWorksById,
    topics,
    topicsById,
  };
};
