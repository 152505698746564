import { Col, Form, FormInstance, Row, Select } from 'antd';
import { useContext } from 'react';
import { CKEditorExtended } from 'src/components/_shared/CKEditorExtended';
import { StandardsContext } from 'src/contexts';
import { useStandard } from 'src/hooks';
import { tagRender } from 'src/_shared/utils';

interface FormStandardsSectionProps {
  form: FormInstance;
}
export const FormStandardsSection = (props: FormStandardsSectionProps) => {
  const { form } = props;

  const { standardList, standardsById } = useContext(StandardsContext);
  const { collateStandards } = useStandard({
    doFind: false,
    key: 'create-lesson',
  });

  const onStandardsChange = (values: string[]) => {
    if (values && Array.isArray(values)) {
      const standards = values.map((value) => standardsById[value]);
      collateStandards(standards);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name="standards"
          label={
            <span className="stm-create-lesson-form-section-title padded-top">
              Standards
            </span>
          }
          rules={[{ required: false, message: 'Please select standards' }]}
        >
          <Select
            options={standardList}
            tagRender={tagRender}
            mode={'multiple'}
            optionFilterProp="label"
            showArrow
            showSearch
            onChange={(value) => onStandardsChange(value as string[])}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="" noStyle>
          <span className="stm-create-lesson-form-section-title padded-bottom stm-mt-5">
            Objective
          </span>
        </Form.Item>
      </Col>
      <Col span={24} className={'stm-create-lesson-form-objectives'}>
        <Form.Item
          name="learningObjective"
          rules={[
            { required: true, message: 'Please enter lesson objectives' },
          ]}
        >
          <CKEditorExtended
            initialValue={form.getFieldValue('learningObjective')}
            config={{ placeholder: 'Enter objectives for this lesson....' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
