import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_TODAY_LESSON = createActionType('GET_TODAY_LESSON', 'LESSON');

export const getTodayLesson = (options?: ActionOption) => ({
  type: GET_TODAY_LESSON.START,
  meta: {
    ...options,
  },
});
