import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_RED_FLAGS_COUNT = createActionType(
  'GET_RED_FLAGS_COUNT',
  'SCHOOL'
);

export const getRedFlagsCount = (schoolID: string, option?: ActionOption) => ({
  type: GET_RED_FLAGS_COUNT.START,
  meta: {
    schoolID,
    ...option,
  },
});
