import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { POST_ANNOUNCEMENT_COMMENTS } from '../../../actions';

export const postAnnouncementComments: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === POST_ANNOUNCEMENT_COMMENTS.START) {
      const { key, announcementId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: POST,
          url: `${ServicesURLs.comments}/announcement/${announcementId}`,
          key: key || 'postAnnouncementComments',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: POST_ANNOUNCEMENT_COMMENTS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
