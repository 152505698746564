import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_USER_CLASSES = createActionType(
  'GET_USER_CLASSES',
  'CLASS_MEMBERS'
);

export const getUserClasses = (userId: string, options?: ActionOption) => ({
  type: GET_USER_CLASSES.START,
  meta: {
    userId,
    ...options,
  },
});
