import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_ANNOUNCEMENT = createActionType(
  'CREATE_ANNOUNCEMENT',
  'ANNOUNCEMENTS'
);

export const createAnnouncement = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_ANNOUNCEMENT.START,
  meta: {
    ...options,
    payload,
  },
});
