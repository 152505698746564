import { Col, Row } from 'antd';
import { LinkFormItem } from '../../../../_shared/Links/Link';

export const ActivityExternalLink = () => {
  return (
    <>
      <Row gutter={[15, 30]}>
        <Col span={24}>
          <LinkFormItem
            formItemProps={{
              name: ['activity', 'activityUrl'],
              label: 'Link URL',
            }}
          />
        </Col>
      </Row>
    </>
  );
};
