import { Action } from '../../types';
import {
  CREATE_ATTENDANCE,
  FIND_ATTENDANCE,
  GET_ATTENDANCE,
  UPDATE_ATTENDANCE,
  GET_ATTENDANCE_CLASS_PERCENTAGE,
  GET_ATTENDANCE_STUDENT_PERCENTAGE,
} from '../../actions';

export interface AttendanceState {
  attendances: Record<string, ClassesNamespace.Attendance[]> | any;
  attendancesById:
    | Record<string, Record<string, ClassesNamespace.Attendance>>
    | any;
  currentAttendance: ClassesNamespace.Attendance | any;
}

export const AttendanceStateDefaultState: AttendanceState = {
  attendances: {},
  attendancesById: {},
  currentAttendance: {},
};

const attendanceReducer = (
  state = AttendanceStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_ATTENDANCE.SUCCESS: {
      const byIdFind = payload.reduce(
        (accumulator: any, currentObject: ClassesNamespace.Attendance) => {
          accumulator[
            `${currentObject?._id?.class}_${currentObject?._id?.student?._id}`
          ] = currentObject;
          return accumulator;
        },
        {}
      );
      return Object.assign({}, state, {
        attendancesById: {
          ...state.attendancesById,
          [action.key]: byIdFind,
        },
        attendances: {
          ...state.attendances,
          [action.key]: payload,
        },
        currentAttendance: {
          ...state.currentAttendance,
          [action.key]: {},
        },
      });
    }
    case GET_ATTENDANCE.SUCCESS: {
      const byIdCreate = {
        ...state.attendancesById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        attendancesById: {
          ...state.attendancesById,
          [action.key]: byIdCreate,
        },
        attendances: {
          ...state.attendances,
          [action.key]: Object.values(byIdCreate),
        },
        currentAttendance: {
          ...state.currentAttendance,
          [action.key]: payload,
        },
      });
    }
    case GET_ATTENDANCE_STUDENT_PERCENTAGE.SUCCESS:
    case GET_ATTENDANCE_CLASS_PERCENTAGE.SUCCESS: {
      return Object.assign({}, state, {
        attendances: {
          ...state.attendances,
          [action.key]: payload,
        },
      });
    }
    case UPDATE_ATTENDANCE.SUCCESS:
    case CREATE_ATTENDANCE.SUCCESS:
    default:
      return state;
  }
};
export default attendanceReducer;
