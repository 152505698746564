import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ACTIVITIES_BY_CLASS = createActionType(
  'FIND_ACTIVITIES_BY_CLASS',
  'ACTIVITY'
);

export const findActivityByClass = (
  classId: string,
  options?: ActionOption
) => ({
  type: FIND_ACTIVITIES_BY_CLASS.START,
  meta: {
    classId,
    ...options,
  },
});
