import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const CREATE_LESSON = createActionType('CREATE_LESSON', 'LESSON');

export const createLesson = (payload: Record<string, any>, options?: ActionOption) => ({
  type: CREATE_LESSON.START,
  meta: {
    ...options,
    payload,
  }
});
