import { omit } from 'lodash';
import { createSelector } from 'reselect';
import { AssignmentsState } from '../../redux/reducers/assignments';

const assignments = (assignments: AssignmentsState, key: string) =>
  assignments?.assignmentDetails[key] || [];

export const selectAssignmentDetails = createSelector(
  assignments,
  (assignments: Record<string, any>[]) => {
    console.log('selector:::::', assignments);
    return assignments.map((assignment: Record<string, any>) => {
      return {
        title: assignment?.title,
        questions: omit(assignment?.questions, [
          'account',
          'activity',
          'standards',
          'learnerGroups',
          'user',
        ]),
        submission: omit(assignment?.submission, [
          'class',
          'classActivity',
          'lesson',
        ]),
      };
    });
    return assignments;
  }
);
