import './activity-menu.scss';
import { Button, Menu, Popover, Tooltip } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';
import { motion } from 'framer-motion';
import { get, isEmpty } from 'lodash';
import { classname } from '../../../../_shared/utils';
import { ActivityTypeToName } from '../Activity/ActivityType';
import { ConditionalRenderer } from '../../../_shared';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { DragIndicator } from 'src/components/classes/icons/DragIndicator';
import { reorder } from 'src/_shared/utils';

interface ActivityMenuProps {
  activities: Record<string, any>[];
  currentActivity: Record<string, any>;
  onCurrentActivityChange: (activity: Record<string, any>) => void;
  onSavePlaylist: (payload: any) => void;
  reorderLoading: boolean | undefined;
}
export const ActivityMenu = (props: ActivityMenuProps) => {
  const {
    activities,
    onCurrentActivityChange,
    currentActivity,
    onSavePlaylist,
    reorderLoading,
  } = props;

  const onSelect = (activity: Record<string, any>) => {
    onCurrentActivityChange(activity);
  };

  const [menuActivities, setMenuActivities] = useState<any[]>([]);
  const [dragging, setDragging] = useState(false);
  // console.log('currentActivity', currentActivity, get(currentActivity, 'activity._id'))

  const showDragger = () => {
    setDragging(!dragging);
  };

  useEffect(() => {
    setMenuActivities(activities);
  }, [activities]);

  const reorderActivities = (result: any) => {
    if (!result.destination) {
      return;
    }

    const itemsReorder = reorder(
      menuActivities,
      result.source.index,
      result.destination.index
    );

    setMenuActivities(itemsReorder.result);
    onSavePlaylist(itemsReorder.result);
  };

  return (
    <motion.div
      className={classname({
        'stm-create-lesson-activity-menu': true,
        'stm-create-lesson-activity-menu-collapsed': isEmpty(activities),
        'stm-pt-5': true,
      })}
      style={{ height: 'calc(98vh - 120px)', overflow: 'hidden' }}
    >
      <ScrollBar>
        <div className="stm-create-lesson-activity-menu-header stm-flex stm-justify-between stm-items-center stm-pl-5 stm-pb-5">
          <span className={'stm-pl-1'} data-testid="component-title">
            Activities
          </span>
          <Tooltip
            title={dragging ? 'Finish Reorder' : 'Reorder Activities'}
            placement="topRight"
          >
            <Button
              type="link"
              className="stm-pl-1"
              style={{ padding: 0, margin: 0 }}
              onClick={() => showDragger()}
              data-testid="rearrange-activities-button"
              icon={
                <span className="anticon">
                  <i
                    className={`${
                      !dragging ? 'ri-arrow-up-down-line ' : 'ri-close-line'
                    }`}
                  ></i>
                </span>
              }
            />
          </Tooltip>
        </div>

        <ConditionalRenderer condition={!dragging}>
          <Menu
            mode="inline"
            style={{ width: '100%', border: 0 }}
            selectedKeys={[get(currentActivity, '_id')]}
            data-testid="menu-activity-list"
          >
            {activities.map((activity) => {
              return (
                <Menu.Item
                  key={get(activity, '_id')}
                  onClick={() => onSelect(activity)}
                  data-testid={`activity-menu-item-${get(activity, '_id')}`}
                >
                  <Popover
                    content={
                      <div
                        className={'stm-flex stm-flex-col stm-justify-center'}
                      >
                        <div className={'stm-flex stm-items-center'}>
                          <span className={'stm-pr-3'}>Activity Type:</span>
                          <span className={'stm-heading-color-force stm-fw-3'}>
                            {get(ActivityTypeToName, activity?.type)}
                          </span>
                        </div>
                        <ConditionalRenderer
                          condition={
                            activity?.questions &&
                            Array.isArray(activity.questions) &&
                            activity.questions?.length
                          }
                        >
                          <div className={'stm-flex stm-items-center stm-mt-3'}>
                            <span className={'stm-pr-3'}>Questions:</span>
                            <span
                              className={'stm-heading-color-force stm-fw-3'}
                            >
                              {get(activity, 'questions.length', 0)}
                            </span>
                          </div>
                        </ConditionalRenderer>
                      </div>
                    }
                    placement={'leftBottom'}
                  >
                    <span className={'stm-block'}>{activity?.title}</span>
                  </Popover>
                </Menu.Item>
              );
            })}
          </Menu>
        </ConditionalRenderer>
        <ConditionalRenderer condition={dragging}>
          <DragDropContext
            onDragEnd={reorderActivities}
            data-testid="draggable-menu-activity-list"
          >
            <Droppable
              droppableId="@@menu/droppable"
              isDropDisabled={reorderLoading}
            >
              {(provided) => {
                return (
                  <motion.div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    key="@@menu/droppable/item"
                    whileHover={{
                      cursor: reorderLoading ? 'revert' : undefined,
                    }}
                  >
                    {(menuActivities ?? []).map((activity, index) => {
                      return (
                        <div
                          key={`@@Droppable:container/${index}/${activity?._id}`}
                          data-testid={`draggable-item-${index}`}
                        >
                          <Draggable
                            key={`@@Draggable:${index}/${activity?._id}`}
                            draggableId={activity?._id}
                            index={index}
                            isDragDisabled={reorderLoading}
                          >
                            {(provided, snapshot) => (
                              <div
                                key={`@@Draggable:item/${index}/${activity?._id}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                                className={'stm-bg-transparent'}
                              >
                                <div className="stm-flex stm-mv-4">
                                  <span style={{ width: 20, marginRight: 5 }}>
                                    <DragIndicator
                                      color={
                                        snapshot.isDragging
                                          ? 'var(--accent)'
                                          : 'var(--disabled-color)'
                                      }
                                    />
                                  </span>
                                  <span className={'stm-block'}>
                                    {activity?.title}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                  </motion.div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </ConditionalRenderer>
      </ScrollBar>
    </motion.div>
  );
};
