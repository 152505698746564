import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_ATTENDANCE = createActionType(
  'UPDATE_ATTENDANCE',
  'ATTENDANCE'
);

export const updateAttendance = (
  _id: string,
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: UPDATE_ATTENDANCE.START,
  meta: {
    _id,
    payload,
    ...options,
  },
});
