import { findMembers, getMembers } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import {
  selectCurrentMember,
  selectMembersData,
} from '../../selectors/members';

export type useMembersPropType = {
  key: string;
  uiKey?: string;
  userId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type useMembersReturnType = {
  membersData: Record<string, any>[];
  currentMember: Record<string, any>;
  handleFindAllMembers: (options?: ActionOption) => void;
  handleGetMember: (userId: string, options?: ActionOption) => void;
};

export const useMembers = ({
  params,
  key,
  uiKey,
  userId,
  doFind = true,
}: useMembersPropType): useMembersReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    uiKey,
    params,
  };

  const { membersData, currentMember } = useSelector(
    ({ members }: RootState) => ({
      membersData: selectMembersData(members, key),
      currentMember: selectCurrentMember(members, key),
    })
  );

  useEffect(() => {
    if (doFind) {
      handleFindAllMembers();
    }
  }, [doFind]);

  const handleFindAllMembers = (options?: ActionOption) => {
    dispatch(
      findMembers({
        ...defaultOptions,
        ...options,
      })
    );
  };

  const handleGetMember = (userId: string, options?: ActionOption) => {
    dispatch(
      getMembers(userId, {
        ...defaultOptions,
        ...options,
      })
    );
  };

  useEffect(() => {
    if (doFind && userId) {
      handleGetMember(userId);
    }
  }, [doFind, userId]);

  return {
    membersData,
    currentMember,
    handleFindAllMembers,
    handleGetMember,
  };
};
