import { Col, Form, FormInstance, Row } from 'antd';
import { CKEditorExtended } from 'src/components/_shared/CKEditorExtended';
import { DatePicker } from 'src/_shared/utils/override';
import { isDate, isEmpty } from 'lodash';

interface FormLessonAnnouncementSectionProps {
  form: FormInstance;
}
export const FormLessonAnnouncementSection = (
  props: FormLessonAnnouncementSectionProps
) => {
  const { form } = props;

  return (
    <>
      <Form.Item noStyle>
        <span className="stm-create-lesson-form-section-title padded-bottom">
          Lesson Announcement{' '}
          <span className="stm-create-lesson-form-section-title-sub">
            (Optional)
          </span>
        </span>
      </Form.Item>

      <Row gutter={16}>
        <Form.Item noStyle>
          {/* <Col span={12}>
          <Form.Item
            name={['announcement', 'audience']}
            label="Share to"
            rules={[
              () => ({
                validator(_, value) {
                  const editortValue = form.getFieldValue([
                    'announcement',
                    'message',
                  ]);
                  if (
                    editortValue &&
                    editortValue !== '<p><br></p>' &&
                    isEmpty(value)
                  ) {
                    return Promise.reject(
                      new Error('Please select announcement audience.')
                    );
                  }
                  return Promise.resolve();
                },
              }),138011004027487
            ]}
          >
            <Select
              placeholder="Please select your audience"
              mode="multiple"
              value={form.getFieldValue(['announcement', 'audience'])}
            >
              <Option value="Parent">Parent</Option>
              <Option value="Student">Student</Option>
            </Select>
          </Form.Item>
        </Col> */}
          <Col sm={24} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              name={['announcement', 'publishDate']}
              label="Publish date"
              rules={[
                () => ({
                  validator(_, value) {
                    const editortValue = form.getFieldValue([
                      'announcement',
                      'description',
                    ]);

                    if (
                      !isEmpty(editortValue) &&
                      editortValue !== '<p><br></p>' &&
                      !isDate(value)
                    ) {
                      return Promise.reject(
                        new Error('Please select publish date.')
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                format="MM-dd-yyyy"
                style={{ width: '100%' }}
                onChange={() =>
                  form.validateFields(['announcement', 'publishDate'])
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={['announcement', 'description']}>
              <CKEditorExtended
                initialValue={form.getFieldValue([
                  'announcement',
                  'description',
                ])}
                config={{
                  placeholder: 'Enter announcement for this lesson....',
                }}
              />
            </Form.Item>
          </Col>
        </Form.Item>
      </Row>
    </>
  );
};
