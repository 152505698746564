import { createContext, ReactNode, ReactNodeArray, useEffect } from 'react';
import { useAuth, useClassMember } from '../../hooks';
import { PROVIDER_KEYS } from '../../_shared/constants';

export type ClassProviderStateType = {
  userClasses: LessonNameSpace.Class[];
  key: string;
};
const initialState: ClassProviderStateType = {
  userClasses: [],
  key: PROVIDER_KEYS.Class,
};

interface ClassProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const ClassContext = createContext(initialState);

export const ClassProvider = ({ children }: ClassProviderProps) => {
  const { auth } = useAuth();
  const { userClasses, getClassMembers } = useClassMember({
    userId: auth?._id,
    isPrincipal: auth?.roles?.includes('Principal'),
    key: PROVIDER_KEYS.Class,
    params: {
      all: true,
    },
    doFind: false,
  });

  useEffect(() => {
    if (auth?._id) {
      getClassMembers(
        {
          params: Object.assign(
            {},
            {
              user: auth?._id,
              population: JSON.stringify(['user', 'class']),
            }
          ),
        },
        auth?._id
      );
    }
  }, [auth?._id]);

  return (
    <ClassContext.Provider value={{ userClasses, key: PROVIDER_KEYS.Class }}>
      {children}
    </ClassContext.Provider>
  );
};
