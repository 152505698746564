import {
  createNotificationChannel,
  findNotifications,
  readNotifications,
  removeDeviceFromChannel,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import { selectNotificationsData } from '../../selectors';

export type useNotificationsPropType = {
  key: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type useNotificationsReturnType = {
  handleFindNotification: (options?: ActionOption) => void;
  handleReadNotification: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleRegisterDevice: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleRemoveDevice: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  notificationData: Record<string, any>[];
};

export const useNotifications = ({
  params,
  key,
  doFind = true,
}: useNotificationsPropType): useNotificationsReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };
  const { notificationData } = useSelector(({ notification }: RootState) => ({
    notificationData: selectNotificationsData(notification, key),
  }));

  useEffect(() => {
    if (doFind) {
      handleFindNotification();
    }
  }, [doFind]);

  const handleFindNotification = (options?: ActionOption) => {
    dispatch(
      findNotifications({
        ...defaultOptions,
        ...options,
      })
    );
  };
  const handleReadNotification = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      readNotifications(payload, {
        ...defaultOptions,
        ...options,
      })
    );
  };

  const handleRegisterDevice = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      createNotificationChannel(payload, {
        ...defaultOptions,
        ...options,
      })
    );
  };

  const handleRemoveDevice = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      removeDeviceFromChannel(payload, {
        ...defaultOptions,
        ...options,
      })
    );
  };

  return {
    handleReadNotification,
    handleFindNotification,
    handleRegisterDevice,
    handleRemoveDevice,
    notificationData,
  };
};
