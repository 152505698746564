import app from './app';
import auth from './auth';
import lessons from './lesson';
import classMember from './classMember';
import unit from './unit';
import standard from './standard';
import learnerGroup from './learnerGroup';
import activity from './activity';
import playlist from './playlist';
import question from './question';
import announcements from './announcements';
import assignments from './assignments';
import syllabusCategory from './syllabusCategory';
import attendance from './attendance';
import overview from './overview';
import grades from './grades';
import mastery from './mastery';
import comments from './comments';
import recording from './recordings';
import googleImport from './googleImport';
import members from './members';
import notifications from './notifications';
import school from './school';

export default [
  ...app,
  ...auth,
  ...classMember,
  ...lessons,
  ...unit,
  ...standard,
  ...learnerGroup,
  ...activity,
  ...playlist,
  ...question,
  ...announcements,
  ...assignments,
  ...syllabusCategory,
  ...attendance,
  ...overview,
  ...grades,
  ...mastery,
  ...comments,
  ...recording,
  ...googleImport,
  ...members,
  ...notifications,
  ...school,
];
