import { getAttendanceCount } from './getAttendanceCount';
import { getRedFlagsCount } from './getRedFlagsCount';
import { getExitTicketStandardCount } from './getExitTicketStandardCount';
import { getSchoolAttendance } from './getSchoolAttendance';
import { getSchoolRedFlags } from './getSchoolRedFlags';
import { getGradeLevels } from './getGradeLevels';
import { getSchoolClasses } from './getSchoolClasses';

export default [
  getAttendanceCount,
  getRedFlagsCount,
  getExitTicketStandardCount,
  getSchoolAttendance,
  getSchoolRedFlags,
  getGradeLevels,
  getSchoolClasses,
];
