import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_PLAYLIST_ACTIVITY = createActionType(
  'DELETE_PLAYLIST_ACTIVITY',
  'PLAYLIST'
);

export const deletePlaylistActivity = (
  playlistId: string,
  activityId: string,
  options?: ActionOption
) => ({
  type: DELETE_PLAYLIST_ACTIVITY.START,
  meta: {
    ...options,
    playlistId,
    activityId,
  },
});
