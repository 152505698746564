import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const UPDATE_ACTIVITY = createActionType('UPDATE_ACTIVITY', 'ACTIVITY');

export const updateActivity = (_id: string, payload: Record<string, any>, options?: ActionOption) => ({
  type: UPDATE_ACTIVITY.START,
  meta: {
    ...options,
    _id,
    payload,
  }
});
