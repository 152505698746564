import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ASSIGNMENTS = createActionType(
  'FIND_ASSIGNMENTS',
  'ASSIGNMENTS'
);

export const findAssignments = (classId?: string, option?: ActionOption) => ({
  type: FIND_ASSIGNMENTS.START,
  meta: {
    classId,
    ...option,
  },
});
