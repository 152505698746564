import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_UNIT = createActionType('DELETE_UNIT', 'UNIT');

export const deleteUnit = (_id: string, options?: ActionOption) => ({
  type: DELETE_UNIT.START,
  meta: {
    _id,
    ...options,
  },
});
