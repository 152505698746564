import {
  CREATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  FIND_ANNOUNCEMENT_COMMENTS,
  FIND_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT,
  POST_ANNOUNCEMENT_COMMENTS,
  UPDATE_ANNOUNCEMENT,
  FIND_USER_ANNOUNCEMENTS,
} from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import { SET_CURRENT_ANNOUNCEMENT } from '../../actions/announcements/setCurrentAnnouncement';

export interface AnnouncementsState {
  announcements: Record<string, ClassesNamespace.Announcements[]>;
  announcementById: Record<
    string,
    Record<string, ClassesNamespace.Announcements>
  >;
  currentAnnouncements: Record<string, ClassesNamespace.Announcements>;

  comments: Record<string, ClassesNamespace.AnnouncementComments[]>;
  commentsById: Record<
    string,
    Record<string, ClassesNamespace.AnnouncementComments>
  >;
  currentComments: Record<string, ClassesNamespace.AnnouncementComments>;
}

export const AnnouncementsStateDefaultState: AnnouncementsState = {
  announcements: {},
  announcementById: {},
  currentAnnouncements: {},
  comments: {},
  commentsById: {},
  currentComments: {},
};

const announcementReducer = (
  state = AnnouncementsStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_USER_ANNOUNCEMENTS.SUCCESS:
    case FIND_ANNOUNCEMENTS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        announcementById: {
          ...state.announcementById,
          [action.key]: byIdFind,
        },
        announcements: {
          ...state.announcements,
          [action.key]: Object.values(byIdFind),
        },
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: {},
        },
      });
    }
    case FIND_ANNOUNCEMENT_COMMENTS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        commentsById: {
          ...state.commentsById,
          [action.key]: byIdFind,
        },
        comments: {
          ...state.comments,
          [action.key]: Object.values(byIdFind),
        },
        currentComments: {
          ...state.currentComments,
          [action.key]: {},
        },
      });
    }
    case POST_ANNOUNCEMENT_COMMENTS.SUCCESS: {
      const comments = state.comments[action.key];
      const newComments = [payload].concat(comments);

      const byId = arrayToById(newComments);

      return Object.assign({}, state, {
        commentsById: {
          ...state.commentsById,
          [action.key]: byId,
        },
        comments: {
          ...state.comments,
          [action.key]: newComments,
        },
        currentComments: {
          ...state.currentComments,
          [action.key]: payload,
        },
      });
    }

    case SET_CURRENT_ANNOUNCEMENT: {
      return {
        ...state,
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: payload,
        },
      };
    }
    case GET_ANNOUNCEMENT.SUCCESS:
    case UPDATE_ANNOUNCEMENT.SUCCESS: {
      const byIdCreate = {
        ...state.announcementById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        announcementById: {
          ...state.announcementById,
          [action.key]: byIdCreate,
        },
        announcements: {
          ...state.announcements,
          [action.key]: Object.values(byIdCreate),
        },
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: payload,
        },
      });
    }

    case CREATE_ANNOUNCEMENT.SUCCESS: {
      const announcements = state.announcements[action.key];
      const newAnnouncements = [payload].concat(announcements);

      const byId = arrayToById(newAnnouncements);

      return Object.assign({}, state, {
        announcementById: {
          ...state.announcementById,
          [action.key]: byId,
        },
        announcements: {
          ...state.announcements,
          [action.key]: newAnnouncements,
        },
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: payload,
        },
      });
    }

    case DELETE_ANNOUNCEMENT.SUCCESS: {
      const byId = { ...state.announcementById[action.key] };
      delete byId[payload._id as keyof ClassesNamespace.Announcements];
      return Object.assign({}, state, {
        announcementById: {
          ...state.announcementById,
          [action.key]: byId,
        },
        announcements: {
          ...state.announcements,
          [action.key]: Object.values(byId),
        },
        currentAnnouncements: null,
      });
    }
    default:
      return state;
  }
};

export default announcementReducer;
