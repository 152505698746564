import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_UNIT = createActionType('FIND_UNIT', 'UNIT');

export const findUnit = (options?: ActionOption) => ({
  type: FIND_UNIT.START,
  meta: {
    ...options,
  },
});
