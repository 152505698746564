import axios from 'axios';
// import cookie from 'js-cookie';
import { getToken } from './helpers';
// Default config options
const defaultOptions = {
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {},
};

// Update instance
const instance = axios.create(defaultOptions);
// Set the AUTH token for any request
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) config.headers['x-auth-token'] = `${token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response.data;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export const createAPIRequest = (config: Record<string, any>) => instance(config);

export default createAPIRequest;

export { instance as AxiosInstance };
