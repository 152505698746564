import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { GET_ASSIGNMENT_SUBMISSION } from '../../../actions';

export const getAssignmentSubmission: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_ASSIGNMENT_SUBMISSION.START) {
      const { key, submissionId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.assigmentSubmissions}/${submissionId}`,
          key: key || 'getAssignmentSubmission',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: GET_ASSIGNMENT_SUBMISSION.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
