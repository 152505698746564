import './activity-type-list.scss';
import ActivityList from './ActivityList';
import { ActivityType, PLAYLIST_SIDEBAR_ITEMS } from 'src/_shared/constants';
import ScrollBar from 'react-perfect-scrollbar';

interface ActivityTypeListProps {
  onActivityTypeSelected: (activity: ActivityType, resetForm?: boolean) => void;
}
export const ActivityTypeList = (props: ActivityTypeListProps) => {
  const { onActivityTypeSelected } = props;

  return (
    <div className="stm-activity-list-sidebar" data-testid="activity-type-list">
      <ScrollBar>
        <ActivityList
          onActivityItemSelected={onActivityTypeSelected}
          sideBarItems={PLAYLIST_SIDEBAR_ITEMS}
        />
      </ScrollBar>
    </div>
  );
};
