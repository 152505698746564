import { createActionType } from 'src/_shared/utils';
import { ActionOption } from '../../../types';

export const GET_SCHOOL_RED_FLAGS = createActionType(
  'GET_SCHOOL_RED_FLAGS',
  'SCHOOL'
);

export const getSchoolRedFlags = (schoolID: string, option?: ActionOption) => ({
  type: GET_SCHOOL_RED_FLAGS.START,
  meta: {
    schoolID,
    ...option,
  },
});
