import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const FIND_LESSON = createActionType('FIND_LESSON', 'LESSON');

export const findLesson = (options?: ActionOption) => ({
  type: FIND_LESSON.START,
  meta: {
    ...options
  }
});
