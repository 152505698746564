import {createActionType} from '../../../../_shared/utils';


export const GET_PLAYLIST = createActionType('GET_PLAYLIST', 'PLAYLIST');

export const getPlaylist = (_id: string, params: Record<string, any>, key?: string) => ({
  type: GET_PLAYLIST.START,
  meta: {
    key,
    _id,
    params
  }
});
