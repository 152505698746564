import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_NOTIFICATIONS_CHANNEL = createActionType(
  'CREATE_NOTIFICATIONS_CHANNEL',
  'NOTIFICATION'
);

export const createNotificationChannel = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_NOTIFICATIONS_CHANNEL.START,
  meta: {
    payload,
    ...options,
  },
});
