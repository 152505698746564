import {
  CREATE_ASSIGNMENT,
  CREATE_ASSIGNMENT_SUBMISSION,
  DELETE_ASSIGNMENT,
  FIND_ASSIGNMENT_SUBMISSIONS,
  FIND_ASSIGNMENTS,
  GET_ASSIGNMENT,
  GET_ASSIGNMENT_SUBMISSION,
  MODIFY_ASSIGNMENT_SUBMISSION,
  SET_CURRENT_ASSIGNMENT,
  SET_CURRENT_ASSIGNMENT_SUBMISSION,
  UPDATE_ASSIGNMENT,
  FIND_ASSIGNMENT_DETAIL,
} from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import { has, lowerCase, reduce, sortBy } from 'lodash';

export interface AssignmentsState {
  assignments: Record<string, ClassesNamespace.Assignments[]>;
  assignmentDetails: Record<string, Record<string, any>[]>;
  assignmentById: Record<string, any>;
  assignmentDetailsById: Record<string, Record<string, any>>;
  currentAssignments: Record<string, ClassesNamespace.Assignments>;
  currentAssignmentDetails: Record<string, ClassesNamespace.Assignments>;
  submissions: Record<
    string,
    {
      submitted: ClassesNamespace.AssignmentSubmission[];
      all: ClassesNamespace.AssignmentSubmission[];
      pending: ClassesNamespace.AssignmentSubmission[];
    }
  >;
  submissionsById: Record<
    string,
    Record<string, ClassesNamespace.AssignmentSubmission>
  >;
  currentSubmissions: Record<string, ClassesNamespace.AssignmentSubmission>;
}

export const AssignmentsStateDefaultState: AssignmentsState = {
  assignments: {},
  assignmentDetails: {},
  assignmentById: {},
  currentAssignments: {},
  submissions: {},
  submissionsById: {},
  assignmentDetailsById: {},
  currentSubmissions: {},
  currentAssignmentDetails: {},
};

const assignmentReducer = (
  state = AssignmentsStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_ASSIGNMENTS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        assignmentById: {
          ...state.assignmentById,
          [action.key]: byIdFind,
        },
        assignments: {
          ...state.assignments,
          [action.key]: Object.values(byIdFind),
        },
        currentAssignments: {
          ...state.currentAssignments,
          [action.key]: {},
        },
      });
    }

    case SET_CURRENT_ASSIGNMENT: {
      return {
        ...state,
        currentAssignments: {
          ...state.currentAssignments,
          [action.key]: payload,
        },
      };
    }
    case GET_ASSIGNMENT.SUCCESS:
    case UPDATE_ASSIGNMENT.SUCCESS: {
      const assignments = [...(state.assignments[action.key] ?? [])];

      const assignmentIndex = assignments.findIndex(
        (o) => action.payload?._id && o?._id === action.payload?._id
      );

      if (assignmentIndex !== -1) {
        assignments[assignmentIndex] = Object.assign(
          {},
          assignments[assignmentIndex],
          action.payload
        );
      } else {
        assignments.push(action.payload);
      }

      return Object.assign({}, state, {
        assignmentById: {
          ...state.assignmentById,
          [action.key]: arrayToById(assignments),
        },
        assignments: {
          ...state.assignments,
          [action.key]: assignments,
        },
        currentAssignments: {
          ...state.currentAssignments,
          [action.key]: payload,
        },
      });
    }

    case CREATE_ASSIGNMENT.SUCCESS: {
      const assignments = state.assignments[action.key];
      const newAssignments = [payload].concat(assignments);

      const byId = arrayToById(newAssignments);

      return Object.assign({}, state, {
        assignmentById: {
          ...state.assignmentById,
          [action.key]: byId,
        },
        assignments: {
          ...state.assignments,
          [action.key]: newAssignments,
        },
        currentAssignments: {
          ...state.currentAssignments,
          [action.key]: payload,
        },
      });
    }
    case DELETE_ASSIGNMENT.SUCCESS: {
      const byId = { ...state.assignmentById[action.key] };
      delete byId[payload._id as keyof ClassesNamespace.Assignments];

      const assignments = [...(state.assignments[action.key] ?? [])];
      const assignmentIndex = assignments.findIndex(
        (o) => action.payload?._id && o?._id === action.payload?._id
      );
      if (assignmentIndex !== -1) {
        assignments.splice(assignmentIndex, 1);
      }

      return Object.assign({}, state, {
        assignmentById: {
          ...state.assignmentById,
          [action.key]: byId,
        },
        assignments: {
          ...state.assignments,
          [action.key]: assignments,
        },
      });
    }

    // SUBMISSIONS
    case FIND_ASSIGNMENT_SUBMISSIONS.SUCCESS: {
      const byIdFind = arrayToById(payload || [], 'user._id');
      return Object.assign({}, state, {
        submissionsById: {
          ...state.submissionsById,
          [action.key]: byIdFind,
        },
        submissions: {
          ...state.submissions,
          [action.key]: groupSubmissions(payload),
        },
        currentSubmissions: {
          ...state.currentSubmissions,
          [action.key]: {},
        },
      });
    }

    // Assignment Details
    case FIND_ASSIGNMENT_DETAIL.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        assignmentDetailsById: {
          ...state.assignmentDetailsById,
          [action.key]: byIdFind,
        },
        assignmentDetails: {
          ...state.assignmentDetails,
          // [action.key]: Object.values(byIdFind),
          [action.key]: payload,
        },
        currentAssignmentDetails: {
          ...state.currentAssignmentDetails,
          [action.key]: {},
        },
      });
    }

    case SET_CURRENT_ASSIGNMENT_SUBMISSION: {
      return {
        ...state,
        currentSubmissions: {
          ...state.currentSubmissions,
          [action.key]: payload,
        },
      };
    }

    case GET_ASSIGNMENT_SUBMISSION.SUCCESS:
    case MODIFY_ASSIGNMENT_SUBMISSION.SUCCESS: {
      const submissions = state.submissions[action.key]?.all ?? [];

      const assignmentIndex = submissions.findIndex(
        (o) =>
          action.payload?.user?._id &&
          o?.user?._id === action.payload?.user?._id
      );

      let currentAssignment = {};

      if (assignmentIndex !== -1) {
        currentAssignment = Object.assign(
          {},
          submissions[assignmentIndex],
          action.payload
        );
        submissions[assignmentIndex] = Object.assign(
          {},
          submissions[assignmentIndex],
          action.payload
        );
      } else {
        submissions.push(action.payload);
      }

      const byIdFind = arrayToById(submissions || [], 'user._id');

      return Object.assign({}, state, {
        submissionsById: {
          ...state.submissionsById,
          [action.key]: byIdFind,
        },
        submissions: {
          ...state.submissions,
          [action.key]: groupSubmissions(submissions),
        },
        currentSubmissions: {
          ...state.currentSubmissions,
          [action.key]: currentAssignment,
        },
      });
    }

    case CREATE_ASSIGNMENT_SUBMISSION.SUCCESS: {
      const submissions = state.submissions[action.key];
      const newSubmissions = [payload].concat(submissions?.all);

      const byId = arrayToById(newSubmissions || [], 'user._id');

      return Object.assign({}, state, {
        submissionsById: {
          ...state.submissionsById,
          [action.key]: byId,
        },
        submissions: {
          ...state.assignments,
          [action.key]: groupSubmissions(newSubmissions),
        },
        currentSubmissions: {
          ...state.currentSubmissions,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export function groupSubmissions(submissions: Record<string, any>[]) {
  const callback = (
    accumulator: Record<string, Record<string, any>[]>,
    current: Record<string, any>
  ) => {
    if (
      has(current, 'activitySubmission') &&
      lowerCase(current.activitySubmission.status) === 'completed'
    ) {
      return Object.assign({}, accumulator, {
        submitted: sortBy([current].concat(accumulator.submitted), [
          'user.firstName',
        ]),
      });
    }
    return Object.assign({}, accumulator, {
      pending: sortBy([current].concat(accumulator.pending), [
        'user.firstName',
      ]),
    });
  };

  const defaultAccumulator = {
    submitted: [],
    pending: [],
    all: sortBy(submissions, ['user.firstName']),
  };

  return reduce(submissions, callback, defaultAccumulator);
}

export default assignmentReducer;
