import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { FIND_ASSIGNMENT_DETAIL } from '../../../actions/assignments/findAssignmentDetails';

export const findAssignmentDetail: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_ASSIGNMENT_DETAIL.START) {
      const { key, params } = action.meta;
      const { studentId, assignmentId } = params;
      console.log('%c fontsize 30 actionmeta::::', action.meta);
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.activities}/${assignmentId}/student-submission/${studentId}`,
          key: key || 'findAssignmentDetail',
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_ASSIGNMENT_DETAIL.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
