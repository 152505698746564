import { Button, Col, Form, Row, Select, Space, Tooltip } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { get, has, isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import { DatePicker } from '../../../../_shared/utils/override';

interface FormDetailsSectionProps {
  classList: any[];
  currentLesson?: LessonNameSpace.Lesson;
}

export const FormDetailsSection = (props: FormDetailsSectionProps) => {
  const { classList, currentLesson } = props;
  const [alreadySelectedClasses, setAlreadySelectedClasses] = useState<{
    [id: string]: number;
  }>({});

  const [isClassDropdownVisible, setClassDropdownVisibility] = useState(false);

  const isAlreadySelectedClass = (
    classId: string,
    classSectionIndex: number
  ) => {
    return (
      has(alreadySelectedClasses, classId) &&
      get(alreadySelectedClasses, classId) !== classSectionIndex
    );
  };

  const hasSelectedClass = (index: number) => {
    const indexes = Object.values(alreadySelectedClasses);
    return indexes.includes(index);
  };

  const onRemoveClassSectionClasses = (
    classSectionIndex: number,
    callback?: () => void
  ) => {
    setAlreadySelectedClasses((prev) => {
      const newState: typeof alreadySelectedClasses = {};

      for (const classId in prev) {
        const currentValue = get(prev, classId);
        if (currentValue !== classSectionIndex) {
          newState[classId] = currentValue;
        }
      }

      if (callback && isFunction(callback)) {
        callback();
      }
      return newState;
    });
  };

  const onSetSelectedClass = (classId: string, classSectionIndex: number) => {
    setAlreadySelectedClasses((prev) => {
      if (hasSelectedClass(classSectionIndex)) {
        const newState: typeof alreadySelectedClasses = {};

        for (const classId in prev) {
          const currentValue = get(prev, classId);
          if (currentValue !== classSectionIndex) {
            newState[classId] = currentValue;
          }
        }
        return Object.assign({}, newState, { [classId]: classSectionIndex });
      }
      return Object.assign({}, prev, { [classId]: classSectionIndex });
    });
  };

  const onInitSelectedClasses = () => {
    setAlreadySelectedClasses((prev) => {
      const cloned = Object.assign({}, prev);
      if (
        currentLesson &&
        currentLesson.classes &&
        Array.isArray(currentLesson.classes)
      ) {
        for (let index = 0; index < currentLesson.classes.length; index++) {
          const classId = get(currentLesson, `classes.${index}.class`);
          if (classId) {
            cloned[classId] = index;
          }
        }
      }
      return cloned;
    });
  };

  useEffect(() => {
    if (currentLesson) {
      onInitSelectedClasses();
    }
  }, [currentLesson?._id]);

  return (
    <>
      <Form.Item noStyle>
        <span className="stm-create-lesson-form-section-title padded-bottom">
          Add Details
        </span>
      </Form.Item>
      <Form.List name="classes">
        {(fields, { add, remove }) => (
          <AnimatePresence>
            {fields.map(({ name, ...restField }, index) => {
              const fieldKey = name;
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key={`@@${name}/${index}/key`}
                >
                  <Row gutter={[16, 16]}>
                    <Col sm={24} md={24} lg={8} xl={8} xxl={8}>
                      <Form.Item
                        label="Class section"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a class section',
                          },
                        ]}
                        {...restField}
                        name={[name, 'class']}
                        fieldKey={[fieldKey, 'class']}
                      >
                        <Select
                          data-testid="select"
                          placeholder="Please select a class section"
                          // disabled={!isEmpty(queryString?.classId)}
                          onChange={(classId: string) => {
                            onSetSelectedClass(classId, fieldKey);
                          }}
                          allowClear
                          onClear={() => onRemoveClassSectionClasses(fieldKey)}
                          open={isClassDropdownVisible}
                          onDropdownVisibleChange={setClassDropdownVisibility}
                          aria-expanded={isClassDropdownVisible}
                        >
                          {classList.map((singleClass) => (
                            <Select.Option
                              key={singleClass._id}
                              value={singleClass._id}
                              disabled={isAlreadySelectedClass(
                                singleClass._id,
                                fieldKey
                              )}
                            >
                              {singleClass.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={5} xl={5} xxl={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'publishDate']}
                        fieldKey={[fieldKey, 'publishDate']}
                        label="Published on"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose publish date',
                          },
                        ]}
                      >
                        <DatePicker
                          format="MM-dd-yyyy"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={5} xl={5} xxl={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'startDate']}
                        fieldKey={[fieldKey, 'startDate']}
                        label="Lesson date"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose start date',
                          },
                        ]}
                      >
                        <DatePicker
                          format="MM-dd-yyyy"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={23} md={24} lg={5} xl={5} xxl={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'lessonTime']}
                        fieldKey={[fieldKey, 'lessonTime']}
                        label="Lesson time"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose lesson time',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker
                          picker="time"
                          format="hh:mm aaa"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Form.Item label={' '} name={' '}>
                        <motion.div whileTap={{ scale: 0.85 }}>
                          <Tooltip title={'Remove class section'}>
                            <Button
                              shape={'circle'}
                              type={'link'}
                              // disabled={!isEmpty(queryString?.classId)}
                              icon={
                                <span className="anticon">
                                  <i className="ri-close-circle-line" />
                                </span>
                              }
                              onClick={() => {
                                onRemoveClassSectionClasses(fieldKey, () =>
                                  remove(name)
                                );
                              }}
                            >
                              <span className="sr-only">
                                Remove class section
                              </span>
                            </Button>
                          </Tooltip>
                        </motion.div>
                      </Form.Item>
                    </Col>
                  </Row>
                </motion.div>
              );
            })}
            <Form.Item>
              <motion.div layout>
                <Space align={'center'} style={{ width: '100%' }}>
                  <Button
                    type="link"
                    onClick={() => add()}
                    size={'large'}
                    className={'stm-create-lesson-class-section-add-btn'}
                    icon={
                      <span className="anticon">
                        <i className="ri-add-circle-line" />
                      </span>
                    }
                    // disabled={!isEmpty(queryString?.classId)}
                  >
                    Add class section
                  </Button>
                </Space>
              </motion.div>
            </Form.Item>
          </AnimatePresence>
        )}
      </Form.List>
    </>
  );
};
