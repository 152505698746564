import { Form, FormItemProps, Input, InputProps } from 'antd';
import { isValidURL, prefixHTTPProtocol } from 'src/_shared/utils';

interface LinkFormItem {
  formItemProps?: FormItemProps;
  inputProps?: InputProps;
}
export const LinkFormItem = (props: LinkFormItem) => {
  return (
    <Form.Item
      {...(props?.formItemProps ?? {})}
      normalize={(value) => {
        return value?.trim() ? prefixHTTPProtocol(value) : value;
      }}
      rules={[
        () => ({
          validator(_, value) {
            if (!value) {
              return Promise.reject('Missing link URL');
            }
            if (value.trim().length === 0) {
              return Promise.reject('Link URL cannot be empty');
            }

            if (isValidURL(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Invalid link URL');
          },
        }),
      ]}
      // noStyle
    >
      <Input
        placeholder={'Paste or enter link URL'}
        data-testid={'link-input'}
        aria-label={'link-input'}
        {...(props?.inputProps ?? {})}
      />
    </Form.Item>
  );
};
