import { apiRequest, FIND_PLAYLIST } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findPlaylist: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_PLAYLIST.START) {
      const { key, alternateKey, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: ServicesURLs.playlist,
          key: key || 'findPlaylist',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_PLAYLIST.SUCCESS,
              payload: data,
              key,
              alternateKey,
            });
          },
        })
      );
    }
  };
