import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { motion } from 'framer-motion';
import { QuestionOptions } from './QuestionOptions';
import './question.scss';
import { useEffect, useState } from 'react';
import UUID from 'uuidjs';
import {
  first,
  get,
  isEmpty,
  isNull,
  isUndefined,
  omit,
  pick,
  startCase,
} from 'lodash';
import { tagRender } from '../../../_shared/utils';
import { CKEditorExtended } from '../CKEditorExtended';

export enum QuestionType {
  FileUpload = 'FileUpload',
  LongAnswer = 'LongAnswer',
  MultipleAnswers = 'MultipleAnswers',
  SingleAnswer = 'SingleAnswer',
  ShortAnswer = 'ShortAnswer',
  TrueFalse = 'TrueFalse',
}

const choiceTypeInitialValue = (questionType: QuestionType) => [
  {
    question: undefined,
    questionType,
    options: [
      {
        option: undefined,
      },
    ],
    points: 1,
    SingleAnswerAnswer: undefined,
  },
];

export const QUESTION_TYPES: Record<QuestionType, any> = Object.freeze({
  SingleAnswer: {
    initialValues: choiceTypeInitialValue(QuestionType.SingleAnswer),
  },
  MultipleAnswers: {
    initialValues: choiceTypeInitialValue(QuestionType.MultipleAnswers),
  },
  ShortAnswer: {
    initialValues: [
      {
        question: undefined,
        questionType: 'ShortAnswer',
        shortAnswer: undefined,
        points: 1,
      },
    ],
  },
  FileUpload: {
    initialValues: [
      {
        question: undefined,
        questionType: QuestionType.FileUpload,
        shortAnswer: undefined,
        points: 1,
      },
    ],
  },
  TrueFalse: {
    initialValues: [
      {
        question: undefined,
        questionType: QuestionType.TrueFalse,
        shortAnswer: undefined,
        points: 1,
      },
    ],
  },
  LongAnswer: {
    initialValues: [
      {
        question: undefined,
        questionType: 'LongAnswer',
        longAnswer: undefined,
        points: 1,
      },
    ],
  },
});

export const transformQuestion = {
  toRequest: (questions: any[]) => {
    return questions.map((question: Record<string, any>) => {
      const clonedQuestion = Object.assign({}, question);
      if (
        clonedQuestion.questionType === QuestionType.SingleAnswer ||
        clonedQuestion.questionType === QuestionType.TrueFalse
      ) {
        clonedQuestion.options = (clonedQuestion?.options ?? []).map(
          (option: Record<string, any>, index: number) => {
            if (index === question.singleChoiceAnswer) {
              return Object.assign(
                {},
                {
                  ...option,
                  isCorrect: true,
                }
              );
            }
            return Object.assign(
              {},
              {
                ...option,
                isCorrect: false,
              }
            );
          }
        );
      }

      if (
        Object.values(
          omit(QuestionType, [
            QuestionType.MultipleAnswers,
            QuestionType.SingleAnswer,
            QuestionType.TrueFalse,
          ])
        ).indexOf(clonedQuestion.questionType) !== -1
      ) {
        delete clonedQuestion.options;
      } else {
        clonedQuestion.answer = clonedQuestion.options.reduce(
          (acc: string[], current: Record<string, any>) => {
            if (current.isCorrect) {
              return acc.concat(current.text);
            }
            return acc;
          },
          []
        );
      }

      return Object.assign(
        {},
        pick(clonedQuestion, [
          'points',
          'answer',
          'options',
          'question',
          'questionType',
          'class',
          'standards',
        ])
      );
    });
  },
  fromRequest: (questions: any[]) => {
    if (Array.isArray(questions)) {
      return questions.map((question: Record<string, any>) => {
        const clonedQuestion = Object.assign({}, question);
        let singleChoiceAnswer = undefined;
        // Parses the API RESPONSE question model to fit that of the frontend.
        if (
          question.questionType === QuestionType.SingleAnswer ||
          question.questionType === QuestionType.TrueFalse
        ) {
          (question?.options ?? []).forEach(
            (option: Record<string, any>, index: number) => {
              if (option.isCorrect) {
                singleChoiceAnswer = index;
              }
            }
          );
        }
        if (!isUndefined(singleChoiceAnswer)) {
          clonedQuestion.singleChoiceAnswer = singleChoiceAnswer;
        }
        return clonedQuestion;
      });
    }
    return questions;
  },
};

interface QuestionProps {
  defaultQuestionType?: QuestionType;
  allowedQuestionTypes?: Array<QuestionType>;
  standards: any[] | undefined | null;
  form?: FormInstance;
  optionsLength?: {
    min?: number;
    max?: number;
  };
  questionsLength?: {
    min?: number;
    max?: number;
  };
  helpText?: string;
}

export const Question = (props: QuestionProps) => {
  const MIN = 1;
  const MAX = 256;
  const {
    defaultQuestionType,
    allowedQuestionTypes = Object.keys(QUESTION_TYPES) as QuestionType[],
    standards,
    form,
    optionsLength = { min: MIN, max: MAX },
    questionsLength = { min: MIN, max: MAX },
    helpText,
  } = props;

  const questions = form?.getFieldValue('questions');

  const [questionType, setQuestionType] = useState<string[] | QuestionType[]>(
    defaultQuestionType ? [defaultQuestionType] : []
  );

  const isSingleQuestion = allowedQuestionTypes.length === 1;
  const isStandardQuestion =
    !isNull(standards) && !isUndefined(standards) && Array.isArray(standards);

  const onQuestionsValueChange = () => {
    const initialiseQuestionTypes =
      questions && !isEmpty(questions)
        ? questions.map((o: any) => o.questionType ?? defaultQuestionType)
        : [];
    setQuestionType(initialiseQuestionTypes);
  };

  const onQuestionTypeChange = (type: string, index: number) => {
    setQuestionType((prev) => {
      const questionTypes = [...prev];
      questionTypes[index] = type as QuestionType;
      const questions = form?.getFieldValue('questions') ?? [];
      const currentQuestion = questions[index];
      if (currentQuestion && type === 'TrueFalse') {
        const options = [
          { text: 'True', isCorrect: false },
          { text: 'False', isCorrect: false },
        ];
        questions[index] = Object.assign({}, currentQuestion, { options });
        form?.setFieldsValue({ questions });
      }

      return questionTypes;
    });
  };

  const onRemoveQuestionType = (index: number) => {
    setQuestionType((prev) => {
      const questionTypes = [...prev];
      questionTypes.splice(index, 1);
      return questionTypes;
    });
  };

  useEffect(() => {
    if (questions && !isEmpty(questions)) {
      onQuestionsValueChange();
    }
  }, [questions]);

  return (
    <>
      <Form.List name="questions">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const fieldKey = name;
              return (
                <motion.div
                  key={key + index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  data-testid={'question-item'}
                >
                  <Row gutter={[15, 0]}>
                    {helpText && (
                      <Col span={24}>
                        <Form.Item>
                          <Alert message={helpText} type="info" />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24} style={{ zIndex: 1050 }}>
                      <Form.Item
                        className={'stm-question-question-form-item'}
                        label={
                          <div className="w-100 stm-flex stm-items-center stm-justify-between">
                            <span
                              className={
                                'stm-create-lesson-activity-question-question-header'
                              }
                            >
                              {'Question '.concat(String(index + 1))}
                            </span>
                            <Tooltip
                              title={'Remove question'}
                              placement={'left'}
                            >
                              <motion.div
                                key={'@@remove/' + key + index}
                                className="stm-flex stm-justify-end stm-items-center"
                              >
                                <Button
                                  block
                                  type={'link'}
                                  shape={'circle'}
                                  size={'large'}
                                  data-testid={'question-remove-btn'}
                                  icon={
                                    <span className="anticon">
                                      <i
                                        className="ri-close-circle-line"
                                        style={{ fontSize: 18 }}
                                      />
                                    </span>
                                  }
                                  disabled={
                                    fields.length <=
                                    (questionsLength?.min ?? MIN)
                                  }
                                  onClick={() => {
                                    if (
                                      fields.length >
                                      (questionsLength?.min ?? MIN)
                                    ) {
                                      remove(name);
                                      onRemoveQuestionType(index);
                                    }
                                  }}
                                />
                              </motion.div>
                            </Tooltip>
                          </div>
                        }
                        {...restField}
                        name={[name, 'question']}
                        fieldKey={[fieldKey, 'question']}
                        rules={[
                          { required: true, message: 'Missing question' },
                        ]}
                      >
                        <CKEditorExtended
                          editorKey={`@@Editor:${fieldKey}/question/${index}`}
                          config={{ placeholder: 'Enter question...' }}
                          initialValue={
                            get(
                              form?.getFieldValue('questions'),
                              `${index}.question`
                            ) ?? ''
                          }
                          key={`@@${index}/${fieldKey}/question`}
                          {...{ 'data-testid': 'question-text' }}
                        />

                        {/*<Input*/}
                        {/*  style={{ width: '100%' }}*/}
                        {/*  placeholder={'Enter question...'}*/}
                        {/*/>*/}
                      </Form.Item>
                    </Col>

                    {!isSingleQuestion && (
                      <Col span={12}>
                        <Form.Item
                          label={'Question type'}
                          {...restField}
                          name={[name, 'questionType']}
                          fieldKey={[fieldKey, 'questionType']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing question type',
                            },
                          ]}
                        >
                          <Select
                            placeholder={'Select Question Type'}
                            onChange={(value: string) =>
                              onQuestionTypeChange(value, index)
                            }
                            data-testid={'question-type'}
                          >
                            {allowedQuestionTypes.map((type) => {
                              if (type === QuestionType.TrueFalse) {
                                return (
                                  <Select.Option value={type} key={type}>
                                    True Or False
                                  </Select.Option>
                                );
                              }
                              return (
                                <Select.Option value={type} key={type}>
                                  {startCase(type)}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={isSingleQuestion ? 24 : 12}>
                      <motion.div
                        key={'@@remove/' + key + index}
                        className={
                          isSingleQuestion
                            ? 'stm-flex stm-justify-center stm-items-center h-100 w-100'
                            : undefined
                        }
                      >
                        <div className={'w-100'}>
                          <Form.Item
                            label={'Points'}
                            {...restField}
                            name={[name, 'points']}
                            fieldKey={[fieldKey, 'points']}
                            rules={[
                              { required: true, message: 'Missing points' },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              step={0.5}
                              style={{ width: '100%' }}
                              placeholder={'Enter points...'}
                              data-testid={'question-point'}
                            />
                          </Form.Item>
                        </div>
                      </motion.div>
                    </Col>
                  </Row>
                  {isStandardQuestion && (
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label={'Select Standard'}
                          {...restField}
                          name={[name, 'standards']}
                          fieldKey={[fieldKey, 'standards']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing standards',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select standards"
                            onChange={(value: string) =>
                              onQuestionTypeChange(value, index)
                            }
                            tagRender={tagRender}
                            showArrow
                            data-testid={'question-standards'}
                          >
                            {(standards ?? []).map(
                              (standard: Record<string, any>) => {
                                return (
                                  <Select.Option
                                    key={standard?._id}
                                    value={`${standard?._id}`}
                                  >
                                    {standard?.code}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      <Form.Item noStyle>
                        {[
                          'MultipleAnswers',
                          'SingleAnswer',
                          'TrueFalse',
                        ].indexOf(questionType[index] as string) !== -1 && (
                          <QuestionOptions
                            name={name}
                            optionsLength={optionsLength}
                            questionType={questionType[index] as string}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    type={'horizontal'}
                    style={{
                      width: '100%',
                      borderColor: 'var(--border)',
                      marginTop: 0,
                    }}
                  />
                </motion.div>
              );
            })}
            <Form.Item key={UUID.genV4().toString()}>
              <motion.div>
                <Button
                  type="dashed"
                  disabled={fields.length >= (questionsLength?.max ?? MAX)}
                  onClick={() => {
                    if (fields.length < (questionsLength?.max ?? MAX)) {
                      const defaultValue = first(
                        get(QUESTION_TYPES, [
                          defaultQuestionType ?? QuestionType.MultipleAnswers,
                          'initialValues',
                        ])
                      ) as any;

                      add({ ...defaultValue });
                      onQuestionTypeChange(
                        defaultQuestionType ?? QuestionType.MultipleAnswers,
                        questionType.length
                      );
                    }
                  }}
                  block
                  icon={
                    <span className="anticon">
                      <i className="ri-add-line" />
                    </span>
                  }
                  data-testid={'question-add-btn'}
                >
                  Add a question
                </Button>
              </motion.div>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
