import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const DELETE_LESSON = createActionType('DELETE_LESSON', 'LESSON');

export const deleteLesson = (_id: string, options?: ActionOption) => ({
  type: DELETE_LESSON.START,
  meta: {
    _id,
    ...options
  }
});
