import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_ASSIGNMENT = createActionType(
  'DELETE_ASSIGNMENT',
  'ASSIGNMENTS'
);

export const deleteAssignment = (_id: string, options?: ActionOption) => ({
  type: DELETE_ASSIGNMENT.START,
  meta: {
    _id,
    ...options,
  },
});
