import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_us from './data/en';
import es from './data/es';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en_us,
    },
    es: {
      translations: es,
    },
  },
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  // react: {
  //   wait: true,
  // },
});

export default i18n;
