import { createSelector } from 'reselect';
import { UnitState } from '../../redux/reducers/unit';
import { capitalize } from 'lodash';

const units = (unit: UnitState, key: string) => unit?.units[key] || [];

const lessonGroups = (unit: UnitState, key: string) =>
  unit?.groupedLesson[key] || [];

export const selectGroupLessonByUnit = createSelector(
  lessonGroups,
  (lessonGroups: LessonNameSpace.GroupedLessonByUnit[]) =>
    lessonGroups?.map((lessonGroup) => {
      const lessonId = lessonGroup?._id;
      const unit = lessonId?.unit;
      const allCompletedLessons = lessonGroup?.lessons.find(
        (lesson) => new Date(lesson?.classes?.startDate) > new Date()
      );
      return {
        id: unit?._id,
        name: unit?.title,
        completed: !allCompletedLessons,
        week: lessonId?.week,
        lessons: lessonGroup?.lessons,
      };
    })
);

export const selectUnitList = createSelector(
  units,
  (userClasses: LessonNameSpace.Class[]) => {
    return userClasses.map(({ _id, title }) => ({
      value: _id,
      label: capitalize(title),
    }));
  }
);
