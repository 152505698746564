import {
  createSyllabusCategory,
  deleteSyllabusCategory,
  findSyllabusCategory,
  getSyllabusCategory,
  updateSyllabusCategory,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import { selectSyllabusCategoryList } from '../../selectors';

export type UseSyllabusCategoryProps = {
  classId?: string;
  params?: Record<string, any> | any;
  key: string;
  doFind?: boolean;
};

export type UseSyllabusCategoryType = {
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    _id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleFetch: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  handleDelete: (_id: string, options?: ActionOption) => void;
  syllabusCategories: ClassesNamespace.SyllabusCategory[];
  syllabusCategoryList: { label: string; value: string }[];
  currentSyllabusCategory: ClassesNamespace.SyllabusCategory;
};

export const useSyllabusCategory = ({
  classId,
  params,
  key,
  doFind,
}: UseSyllabusCategoryProps): UseSyllabusCategoryType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
  };

  const { syllabusCategories, currentSyllabusCategory, syllabusCategoryList } =
    useSelector(({ syllabusCategory }: RootState) => ({
      currentSyllabusCategory: syllabusCategory?.currentSyllabusCategory[key],
      syllabusCategories: syllabusCategory?.syllabusCategories[key],
      syllabusCategoryList: selectSyllabusCategoryList(syllabusCategory, key),
    }));

  useEffect(() => {
    if (doFind) {
      dispatch(
        findSyllabusCategory({
          ...defaultOptions,
          params,
        })
      );
    }
  }, [doFind, classId]);

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      createSyllabusCategory(payload, { ...defaultOptions, ...options })
    );
  };

  const handleUpdate = (
    id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      updateSyllabusCategory(id, payload, { ...defaultOptions, ...options })
    );
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(deleteSyllabusCategory(id, { ...defaultOptions, ...options }));
  };

  const handleFetch = (options?: ActionOption) => {
    dispatch(findSyllabusCategory({ ...defaultOptions, ...options }));
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getSyllabusCategory(id, { ...defaultOptions, ...options }));
  };

  return {
    handleCreate,
    handleUpdate,
    handleFetch,
    handleFetchOne,
    handleDelete,
    syllabusCategories,
    syllabusCategoryList,
    currentSyllabusCategory,
  };
};
