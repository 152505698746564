import { getOverviewStats } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';

export type UseOverViewType = {
  key: string;
  classId: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseOverViewReturnType = {
  overviewStats: {
    totalMissedAssignment: number;
    totalAnnouncement: number;
    totalAbsence: number;
    totalAverageScore: number;
  };
};

export const UseOverView = ({
  classId,
  params,
  key,
  doFind,
}: UseOverViewType): UseOverViewReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const { overviewStats } = useSelector(({ overview }: RootState) => ({
    overviewStats: overview?.overviewStats[key],
  }));

  //get overview stats
  useEffect(() => {
    if (doFind && classId) {
      dispatch(
        getOverviewStats({
          ...defaultOptions,
          ...{ classId },
        })
      );
    }
  }, [classId]);

  return {
    overviewStats,
  };
};
