import { apiRequest, FIND_RECORDING } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findRecording: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_RECORDING.START) {
      const { key, params } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `/classes/${params.classId}${ServicesURLs.recordings}`,
          key: key || 'findRecording',
          ...params,
          onSuccess: (data: any) => {
            dispatch({ type: FIND_RECORDING.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
