import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  FIND_ACTIVITIES,
  FIND_ACTIVITIES_BY_CLASS,
  FIND_EXIT_TICKET_SUBMISSIONS,
  GET_ACTIVITY,
  UPDATE_ACTIVITY,
} from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';

export interface ActivityState {
  exitTicketSubmissions: Record<
    string,
    ClassesNamespace.AssignmentSubmission[]
  >;
  activities: Record<string, LessonNameSpace.Activity[]>;
  activitiesById: Record<string, Record<string, LessonNameSpace.Activity>>;
  currentActivity: LessonNameSpace.Activity | any;
}

export const ActivityStateDefaultState: ActivityState = {
  activities: {},
  activitiesById: {},
  currentActivity: {},
  exitTicketSubmissions: {},
};

const activityReducer = (state = ActivityStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_ACTIVITIES_BY_CLASS.SUCCESS:
    case FIND_ACTIVITIES.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        activitiesById: {
          ...state.activitiesById,
          [action.key]: byIdFind,
        },
        activities: {
          ...state.activities,
          [action.key]: Object.values(byIdFind),
        },
        currentActivity: {
          ...state.currentActivity,
          [action.key]: {},
        },
      });
    }
    case GET_ACTIVITY.SUCCESS:
    case CREATE_ACTIVITY.SUCCESS:
    case UPDATE_ACTIVITY.SUCCESS: {
      const byIdCreate = {
        ...state.activitiesById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        activitiesById: {
          ...state.activitiesById,
          [action.key]: byIdCreate,
        },
        activities: {
          ...state.activities,
          [action.key]: Object.values(byIdCreate),
        },
        currentActivity: {
          ...state.currentActivity,
          [action.key]: payload,
        },
      });
    }
    case DELETE_ACTIVITY.SUCCESS: {
      const byId = { ...state.activitiesById[action.key] };
      delete byId[payload._id];
      return Object.assign({}, state, {
        activitiesById: {
          ...state.activitiesById,
          [action.key]: byId,
        },
        activities: {
          ...state.activities,
          [action.key]: Object.values(byId),
        },
        currentActivity: null,
      });
    }
    case FIND_EXIT_TICKET_SUBMISSIONS.SUCCESS: {
      return Object.assign({}, state, {
        exitTicketSubmissions: {
          ...state.activitiesById,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default activityReducer;
