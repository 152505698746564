import { findSyllabusCategory } from './findSyllabusCategory';
import { createSyllabusCategory } from './createSyllabusCategory';
import { updateSyllabusCategory } from './updateSyllabusCategory';
import { deleteSyllabusCategory } from './deleteSyllabusCategory';
import { getSyllabusCategory } from './getSyllabusCategory';

export default [
  findSyllabusCategory,
  createSyllabusCategory,
  updateSyllabusCategory,
  deleteSyllabusCategory,
  getSyllabusCategory,
];
