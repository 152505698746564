import {createActionType} from '../../../../_shared/utils';

export const FIND_LEARNER_GROUPS = createActionType('FIND_LEARNER_GROUPS', 'FIND_LEARNER_GROUP');

export const findLearnerGroup = (params: Record<string, any>, key?: string) => ({
  type: FIND_LEARNER_GROUPS.START,
  meta: {
    key,
    params,
  }
});

export default findLearnerGroup;
