import { Router } from '@reach/router';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { lazy, Suspense } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'remixicon/fonts/remixicon.css';
import 'video-react/dist/video-react.css';
import { useRoutes } from '../hooks/useRoutes';
import './App.scss';
import './assets/styles/stm-theme.min.css';

const Loading = () => null;

const StemuliLayout = lazy(() => import('../app/layout'));

const App = () => {
  const routes = useRoutes();

  return (
    <div data-id={'entry-point'}>
      <Suspense fallback={<Loading />}>
        <Router primary={true}>
          <StemuliLayout path={'/'}>{routes.private}</StemuliLayout>
          {routes.public}
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
