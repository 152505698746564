import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const GET_QUESTION = createActionType('GET_QUESTION', 'QUESTION');

export const getQuestion = (_id: string, option?: ActionOption) => ({
  type: GET_QUESTION.START,
  meta: {
    _id,
    ...option
  }
});
