import { apiRequest, FIND_ACTIVITIES_BY_CLASS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findActivityByClass: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_ACTIVITIES_BY_CLASS.START) {
      const { key, classId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.classes}/${classId}/activities`,
          key: key || 'findActivityByClass',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_ACTIVITIES_BY_CLASS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
