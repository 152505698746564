import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_UNIT_LESSON = createActionType(
  'DELETE_UNIT_LESSON',
  'UNIT'
);

export const deleteUnitLesson = (
  lessonId: string,
  unitId: string,
  options?: ActionOption
) => ({
  type: DELETE_UNIT_LESSON.START,
  meta: {
    ...options,
    lessonId,
    unitId,
  },
});
