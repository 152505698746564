import { useEffect, useState } from 'react';
import { Button, Form, FormInstance } from 'antd';
import { parse } from 'query-string';
import { get, has, isEmpty, isPlainObject, isString, union } from 'lodash';
import { FormBorderedButton } from './FormBorderedButton';
import { FormListContainer } from './FormListContainer';
import { FormPlaylistList } from './FormPlaylistList';
import { FormDetailsSection } from './FormDetailsSection';
import { FormLessonTitleSection } from './FormLessonTitleSection';
import { FormLessonAnnouncementSection } from './FormLessonAnnouncementSection';
import { FormStandardsSection } from './FormStandardsSection';
import { CreateUnitForm } from './FormCreateUnit';
import { useAuth, useClassMember, useUIState } from 'src/hooks';
import { useUnit } from 'src/hooks/useUnit';
import { useLocation } from '@reach/router';
import { classname, parseFilenameFromURL } from '../../../_shared/utils';
import { PROVIDER_KEYS } from 'src/_shared/constants';
import UppyUpload from '../../_shared/UppyUpload';
import { addSeconds, differenceInSeconds } from 'date-fns';
import UploadDropDown from './upload-drop-down';

interface CreateLessonFormProps {
  onCreateOrEditPlaylist?: (visibility: boolean) => void;
  onCreateExitVisibilityChange: (visibility: boolean) => void;
  setStandards: (standards: string[]) => void;
  currentLesson?: LessonNameSpace.Lesson;
  onSubmit: (payload: any, options?: Record<string, any>) => void;
  onFormValuesChange: (
    changedValues: Record<string, any>,
    values: Record<string, any>
  ) => void;
  exitTicketItems: Record<string, any>;
  formLoading?: boolean;
  form: FormInstance;
}

const CreateLessonForm = (props: CreateLessonFormProps) => {
  const {
    onCreateOrEditPlaylist,
    onCreateExitVisibilityChange,
    currentLesson,
    onSubmit,
    formLoading,
    exitTicketItems,
    form,
    onFormValuesChange,
  } = props;

  const UNITS_KEY = 'unitList';

  const [createUnitForm] = Form.useForm();

  const location = useLocation();
  const queryString = parse(location.search);

  const [uploadVideoVisibility, setUploadVideoVisibility] =
    useState<boolean>(false);

  const [uploadResourceVisibility, setUploadResourceVisibility] =
    useState<boolean>(false);

  const [showCreateUnit, setShowCreateUnit] = useState<boolean>(false);

  const [resourceData, setResourceData] = useState<Record<string, any>>({
    file: [],
    uploadedFiles: [],
    url: [],
  });

  const [videoData, setVideoData] = useState<Record<string, any>>({
    file: [],
    uploadedFiles: [],
    url: [],
  });

  const { auth } = useAuth();

  const { classList } = useClassMember({
    key: PROVIDER_KEYS.Class,
    doFind: false,
  });

  const { unitList, handleCreate: handleCreateUnit } = useUnit({
    key: UNITS_KEY,
    doFind: true,
    params: {
      all: true,
    },
    userId: auth?._id,
  });

  const {
    uiLoaders: { [UNITS_KEY]: loadingUnits },
  } = useUIState();

  const onCreateUnit = (payload: Record<string, any>) => {
    handleCreateUnit(payload, {
      key: UNITS_KEY,
      onFinish: () => {
        setShowCreateUnit(false);
        createUnitForm.resetFields();
      },
    });
  };

  useEffect(() => {
    setResourceData(resourceData);
  }, [resourceData]);

  const onFormSubmit = (
    values: Record<string, any>,
    options?: Record<string, any>
  ) => {
    const clonedValues = {
      ...values,
    };
    // if (resourceData) clonedValues.resources = resourceData.uploadedFiles;
    // if (videoData) clonedValues.videos = videoData.uploadedFiles;

    clonedValues.classes = clonedValues?.classes?.map(
      (singleClass: Record<string, any>) => {
        const newClass = { ...singleClass };
        const timeDifferenceInSeconds = newClass?.lessonTime
          ? Math.round(
              differenceInSeconds(
                newClass?.lessonTime[1],
                newClass?.lessonTime[0]
              ) / 10
            ) * 10
          : 0;
        const dateTime =
          newClass?.lessonTime && new Date(newClass?.lessonTime[0]);
        const dateDate = new Date(newClass?.startDate);

        newClass.startDate = new Date(
          `${
            dateDate?.getMonth() + 1
          }-${dateDate?.getDate()}-${dateDate?.getFullYear()} ${dateTime?.getHours()}:${dateTime?.getMinutes()}:00`
        );

        newClass.endDate = addSeconds(
          newClass?.startDate,
          timeDifferenceInSeconds
        );

        return newClass;
      }
    );

    if (
      !isEmpty(clonedValues?.announcement) &&
      !isEmpty(clonedValues?.announcement?.description)
    ) {
      if (clonedValues?.announcement?.description !== '<p><br></p>') {
        clonedValues.announcement.audience = ['Student'];
      } else {
        delete clonedValues?.announcement;
      }
    }
    if (options) {
      return onSubmit(clonedValues, {
        autoSave: true,
        onFinish: options.onFinish,
      });
    }
    onSubmit(clonedValues);
  };

  const getFormatInitialValues = () => {
    if (!isEmpty(currentLesson) && isEmpty(queryString)) {
      // const existingStandards = form?.getFieldValue('standards') ?? [];
      const standards = union(
        [],
        currentLesson?.standards?.map((standard) => {
          if (isPlainObject(standard)) return get(standard, '_id');
          if (isString(standard)) return standard;
          return undefined;
        })
      );
      return Object.assign({}, currentLesson, {
        classes: currentLesson?.classes?.map((o) => ({
          ...o,
          publishDate: new Date(o.publishDate),
          startDate: new Date(o.startDate),
          lessonTime: o.endDate
            ? [new Date(o.startDate), new Date(o.endDate)]
            : undefined,
        })),
        standards,
        standardsOptions: standards,
        announcement: currentLesson?.announcement
          ? {
              ...currentLesson?.announcement,
              publishDate: currentLesson?.announcement?.publishDate
                ? new Date(currentLesson?.announcement?.publishDate)
                : undefined,
            }
          : null,
      });
    }
    if (!isEmpty(queryString) && !isEmpty(currentLesson)) {
      // const existingStandards = form?.getFieldValue('standards') ?? [];
      const standards = union(
        [],
        currentLesson?.standards?.map((standard) => {
          if (isPlainObject(standard)) return get(standard, '_id');
          if (isString(standard)) return standard;
          return undefined;
        })
      );
      return Object.assign({}, currentLesson, {
        classes: currentLesson?.classes?.map((o) => ({
          ...o,
          class: queryString.classId,
          publishDate: new Date(o.publishDate),
          startDate: new Date(o.startDate),
          lessonTime: o.endDate
            ? [new Date(o.startDate), new Date(o.endDate)]
            : undefined,
        })),
        standards,
        unit: queryString.unitId,
        announcement: currentLesson?.announcement
          ? {
              ...currentLesson?.announcement,
              publishDate: currentLesson?.announcement?.publishDate
                ? new Date(currentLesson?.announcement?.publishDate)
                : undefined,
            }
          : null,
      });
    }
    if (isEmpty(currentLesson) && !isEmpty(queryString)) {
      return {
        unit: queryString.unitId,
        classes: [
          {
            class: queryString.classId,
            publishDate: undefined,
            startDate: undefined,
            lessonTime: undefined,
          },
        ],
      };
    }
    return {
      classes: [
        {
          class: undefined,
          publishDate: undefined,
          startDate: undefined,
          lessonTime: undefined,
        },
      ],
    };
  };

  const onInitializeResources = () => {
    if (currentLesson?.resources) {
      setResourceData(() => {
        const url = currentLesson?.resources.map((o) => get(o, 'url'));

        const uploadedFiles = currentLesson?.resources ?? [];

        const file = currentLesson?.resources?.map((o) => ({
          ...o,
          name: parseFilenameFromURL(o)?.filename,
          href: parseFilenameFromURL(o)?.href,
        }));

        return {
          file: file ?? [],
          url,
          uploadedFiles,
        };
      });
    }
    if (currentLesson?.videos) {
      setVideoData(() => {
        const url = currentLesson?.videos.map((o) => get(o, 'url'));

        const uploadedFiles = currentLesson?.videos ?? [];

        const file = currentLesson?.videos?.map((o) => ({
          ...o,
          name: parseFilenameFromURL(o)?.filename,
          href: parseFilenameFromURL(o)?.href,
        }));

        return {
          file: file ?? [],
          url,
          uploadedFiles,
        };
      });
    }
  };

  const initialValues = getFormatInitialValues();

  useEffect(() => {
    onInitializeResources();
  }, []);

  useEffect(() => {
    form?.setFieldsValue(initialValues);
    /* This is used to prevent the form from resetting to its initial value when
    the playlist and exitTicket objects in the current lesson are updated*/
  }, [currentLesson?._id]);

  console.log('currentLesson:::::::', currentLesson);

  return (
    <div className="stm-create-lesson-form">
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFormSubmit}
        onValuesChange={onFormValuesChange}
        scrollToFirstError
        style={{ width: '100%' }}
      >
        <Form.Item noStyle>
          <FormLessonTitleSection
            units={unitList}
            setShowCreateUnit={setShowCreateUnit}
          />
        </Form.Item>

        {/* Detail Section Starts*/}
        <Form.Item noStyle>
          <FormDetailsSection
            classList={classList}
            currentLesson={currentLesson}
          />
        </Form.Item>
        {/* Detail Section Ends*/}

        {currentLesson && (
          <>
            {/* Lesson Announcement Section Starts*/}

            <FormLessonAnnouncementSection form={form} />

            {/* Lesson Announcement Section Ends*/}

            {/* Standards Section Starts*/}
            <Form.Item>
              <FormStandardsSection form={form} />
            </Form.Item>
            {/* Standards Section Ends*/}

            {/* Main Instructional Video Section Starts*/}
            {!isEmpty(videoData.file) && (
              <Form.Item noStyle name={'videos'}>
                <div
                  className={classname({
                    'stm-create-lesson-form-section-title stm-mt-5': true,
                    'stm-mb-10': isEmpty(videoData.file),
                    'stm-mb-4': !isEmpty(videoData.file),
                  })}
                >
                  Main Instructional
                  {/*<Button*/}
                  {/*  type="link"*/}
                  {/*  size={'large'}*/}
                  {/*  onClick={() => setUploadVideoVisibility((prev) => !prev)}*/}
                  {/*  className="stm-create-lesson-form-section-title-add-btn"*/}
                  {/*  icon={*/}
                  {/*    !uploadVideoVisibility && (*/}
                  {/*      <span className="anticon">*/}
                  {/*        <i className="ri-add-circle-line" />*/}
                  {/*      </span>*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {uploadVideoVisibility ? 'X Close' : 'Add video'}*/}
                  {/*</Button>*/}
                </div>
              </Form.Item>
            )}
            <UploadDropDown
              visibility={uploadVideoVisibility}
              fileData={videoData}
              setFilesData={setVideoData}
              submitType={'videos'}
              onSuccess={onFormSubmit}
              lessonForm={form}
            />
            <UppyUpload
              allowedFileTypes={[
                'video/mp4',
                'video/ogg',
                'video/x-msvideo',
                'video/mpeg',
                'video/webm',
                'video/3gpp',
              ]}
              note={'Supports: mpeg, mov, mp4, 3gp, webm videos'}
              fileData={videoData}
              setFilesData={setVideoData}
              openDropDown={setUploadVideoVisibility}
              form={form}
              submitType={'videos'}
              onSuccess={onFormSubmit}
            />

            {/* In class resources Section Starts*/}
            <Form.Item label="" noStyle name={'resources'}>
              <div
                className={classname({
                  'stm-create-lesson-form-section-title stm-mt-6': true,
                  'stm-mb-10': isEmpty(resourceData.file),
                  'stm-mb-4': !isEmpty(resourceData.file),
                })}
              >
                In Class Resources{' '}
                <span className="stm-create-lesson-form-section-title-sub">
                  (Optional)
                </span>
                <Button
                  type="link"
                  size={'large'}
                  onClick={() => setUploadResourceVisibility((prev) => !prev)}
                  className={'stm-create-lesson-form-section-title-add-btn'}
                  icon={
                    !uploadResourceVisibility && (
                      <span className="anticon">
                        <i className="ri-add-circle-line" />
                      </span>
                    )
                  }
                >
                  {uploadResourceVisibility ? 'X Close' : 'Add File'}
                </Button>
              </div>
            </Form.Item>
            <UploadDropDown
              visibility={uploadResourceVisibility}
              fileData={resourceData}
              setFilesData={setResourceData}
              trigger={'uppy-file-display'}
              submitType={'resources'}
              onSuccess={onFormSubmit}
              lessonForm={form}
            />
            <UppyUpload
              allowedFileTypes={[
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/msword',
                'application/pdf',
                'text/plain',
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'image/*',
                'video/*',
              ]}
              note={
                'Supports: .ppt, .doc, .docx, .xlsx, .pdf, .csv, .txt, image, video'
              }
              fileData={resourceData}
              setFilesData={setResourceData}
              trigger={'.uppy-file-display'}
              openDropDown={setUploadResourceVisibility}
              form={form}
              submitType={'resources'}
              onSuccess={onFormSubmit}
            />

            {/* Playlist Section Starts*/}
            <Form.Item label="" noStyle>
              <span className="stm-create-lesson-form-section-title padded-bottom">
                Playlist{' '}
                <span className="stm-create-lesson-form-section-title-sub">
                  (Optional)
                </span>
              </span>
            </Form.Item>
            {onCreateOrEditPlaylist && (
              <Form.Item noStyle>
                <FormBorderedButton
                  description={
                    "Add activities to help students master today's lesson"
                  }
                  onButtonClick={() => onCreateOrEditPlaylist(true)}
                  buttonProps={{
                    htmlType: 'button',
                    children: `${
                      !isEmpty(currentLesson?.playlist) ? 'Update' : 'Create'
                    } Playlist`,
                  }}
                />
              </Form.Item>
            )}
            <Form.Item noStyle>
              <FormListContainer>
                <FormPlaylistList
                  items={currentLesson?.playlist?.[0]?.activities ?? []}
                  playlist={currentLesson?.playlist ?? {}}
                />
              </FormListContainer>
            </Form.Item>
            {/* Playlist Section Ends*/}

            {/* Exit Ticket Section Starts*/}
            <Form.Item label="" noStyle>
              <div className="flex items-center justify-between	w-full">
                <span className="stm-create-lesson-form-section-title padded-bottom stm-mt-8">
                  Exit Ticket{' '}
                  <span className="stm-create-lesson-form-section-title-sub">
                    (Optional)
                  </span>
                </span>
              </div>
            </Form.Item>
            <Form.Item noStyle>
              <FormBorderedButton
                description={'Verify what your students learned today'}
                onButtonClick={() => onCreateExitVisibilityChange(true)}
                buttonProps={{
                  htmlType: 'button',
                  children: `${
                    exitTicketItems?.questions?.length ? 'Update' : 'Create'
                  } Exit Ticket`,
                }}
              />
            </Form.Item>
            {/* Exit Ticket Section Ends*/}
          </>
        )}
        {/* Create unit modal */}
        <CreateUnitForm
          showCreateUnit={showCreateUnit}
          setShowCreateUnit={setShowCreateUnit}
          loading={loadingUnits}
          onCreateUnit={onCreateUnit}
          classList={classList}
          form={createUnitForm}
        />
        <div className={'w-full flex justify-end mt-10'}>
          <Button
            htmlType="submit"
            type="primary"
            className="mx-auto"
            loading={formLoading}
          >
            {has(currentLesson, '_id') ? 'Update' : 'Create'} Lesson
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateLessonForm;
