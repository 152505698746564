import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_SYLLABUS_CATEGORY = createActionType(
  'DELETE_SYLLABUS_CATEGORY',
  'SYLLABUS_CATEGORY'
);

export const deleteSyllabusCategory = (
  _id: string,
  options?: ActionOption
) => ({
  type: DELETE_SYLLABUS_CATEGORY.START,
  meta: {
    _id,
    ...options,
  },
});
