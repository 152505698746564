import { Fragment, ReactNode, ReactNodeArray } from 'react';

interface ConditionalProps {
  condition: boolean;
  default?: ReactNode | ReactNodeArray;
  children: ReactNode | ReactNodeArray;
}
export const ConditionalRenderer = (props: ConditionalProps) => {
  if (props.condition) return <Fragment>{props.children} </Fragment>;
  if (props.default) return <Fragment>{props.default} </Fragment>;
  return null;
};
