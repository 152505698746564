import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_STANDARD = createActionType('GET_STANDARD', 'STANDARD');

export const getStandard = (_id: string, option?: ActionOption) => ({
  type: GET_STANDARD.START,
  meta: {
    ...option,
    _id,
  },
});
