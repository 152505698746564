import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const CREATE_EXIT_TICKET = createActionType(
  'CREATE_EXIT_TICKET',
  'LESSON'
);

export const createExitTicket = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_EXIT_TICKET.START,
  meta: {
    ...options,
    payload,
  },
});
