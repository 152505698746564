import { findStandard } from './findStandard';
import { getStandard } from './getStandard';
import { updateStandard } from './updateStandard';
import { deleteStandard } from './deleteStandard';
import { createStandard } from './createStandard';

export default [
  findStandard,
  createStandard,
  updateStandard,
  deleteStandard,
  getStandard,
];
