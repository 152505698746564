import { FIND_CLASS_MEMBERS, GET_USER_CLASSES } from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';

export interface ClassMemberState {
  userClasses: Record<string, LessonNameSpace.Class[]>;
  userClassesById: Record<string, LessonNameSpace.Class[]>;
  classMembers: Record<string, LessonNameSpace.ClassMember[]>;
  classMembersById: Record<string, Record<string, LessonNameSpace.ClassMember>>;
}

export const ClassMemberStateDefaultState: ClassMemberState = {
  userClasses: {},
  userClassesById: {},
  classMembers: {},
  classMembersById: {},
};

const classMemberReducer = (
  state = ClassMemberStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_CLASS_MEMBERS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        classMembersById: {
          ...state.classMembersById,
          [action.key]: byIdFind,
        },
        classMembers: {
          ...state.classMembers,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    case GET_USER_CLASSES.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        userClassesById: {
          ...state.userClassesById,
          [action.key]: byIdFind,
        },
        userClasses: {
          ...state.userClasses,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    default:
      return state;
  }
};

export default classMemberReducer;
