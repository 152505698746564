import { createSelector } from 'reselect';
import { SyllabusCategoryState } from '../../redux/reducers/syllabusCategory';
import { capitalize } from 'lodash';

const syllabusCategories = (
  syllabusCategory: SyllabusCategoryState,
  key: string
) => syllabusCategory?.syllabusCategories[key] || [];

export const selectSyllabusCategoryList = createSelector(
  syllabusCategories,
  (categories: ClassesNamespace.SyllabusCategory[]) => {
    return categories.map(({ _id, name }) => ({
      value: _id,
      label: capitalize(name),
    }));
  }
);
