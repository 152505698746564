import { useEffect } from 'react';
import { getAuthUser, logout } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

export type UseAuthType = {
  auth: AuthNameSpace.User | undefined;
  getLoggedIn?: (user: AuthNameSpace.User) => void;
  logout?: () => void;
};

interface UseAuth {
  doFind: boolean;
  key?: string;
}
export const useAuth = (props?: UseAuth): UseAuthType => {
  const { doFind = false, key = 'getAuthUser' } = props ?? {};
  const dispatch = useDispatch();

  // const { auth } = useSelector((auth: AppState) => auth || {});
  const auth = useSelector(({ app }: RootState) => app?.auth ?? {});

  useEffect(() => {
    if (doFind) {
      dispatch(getAuthUser(key));
    }
  }, []);

  const getLoggedIn = () => {
    dispatch(getAuthUser(key));
  };

  const dispatchLogout = () => {
    dispatch(logout());
  };

  return {
    auth,
    getLoggedIn,
    logout: dispatchLogout,
  };
};
