import { deleteAssignment } from './deleteAssignment';
import { createAssignment } from './createAssignment';
import { updateAssignment } from './updateAssignment';
import { getAssignment } from './getAssignment';
import { findAssignments } from './findAssignments';
import { findAssignmentSubmissions } from './findAssignmentSubmissions';
import { getAssignmentSubmission } from './getAssignmentSubmission';
import { modifyAssignmentSubmission } from './modifyAssignmentSubmission';
import { findAssignmentDetail } from './findAssignmentDetails';
import { getAssignmentDetail } from './getAssignmentDetail';
import { updateFinalActivityGrade } from './updateFinalActivityGrade';
import { updateStudentQuestionSubmission } from './updateStudentQuestionSubmission';

export default [
  deleteAssignment,
  findAssignments,
  createAssignment,
  updateAssignment,
  getAssignment,
  findAssignmentSubmissions,
  getAssignmentSubmission,
  modifyAssignmentSubmission,
  findAssignmentDetail,
  getAssignmentDetail,
  updateFinalActivityGrade,
  updateStudentQuestionSubmission,
];
