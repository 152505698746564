import { getMastery } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import { selectMasteryData } from '../../selectors/mastery';

export type useMasteryPropType = {
  key: string;
  params?: Record<string, any> | any;
  classId?: string;
  doFind?: boolean;
};

export type useMasteryReturnType = {
  masteryData: Record<string, any>;
  handleFindAllMastery: (classId: string) => void;
};

export const useMastery = ({
  params,
  key,
  classId,
  doFind = true,
}: useMasteryPropType): useMasteryReturnType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const { masteryData } = useSelector(({ mastery }: RootState) => ({
    masteryData: selectMasteryData(mastery, key),
  }));

  useEffect(() => {
    if (doFind && classId) {
      handleFindAllMastery(classId);
    }
  }, [classId]);

  const handleFindAllMastery = (classId: string) => {
    dispatch(
      getMastery({
        ...defaultOptions,
        ...{ classId },
      })
    );
  };

  return {
    masteryData,
    handleFindAllMastery,
  };
};
