import './activity.scss';
import { ActivityForm } from './ActivityForm';
import { ActivityContainer } from './ActivityContainer';
import { ActivityType } from 'src/_shared/constants';
import { ActiveTypeEnumerator } from './ActivityType';
import { Empty, FormInstance } from 'antd';
import { classname } from '../../../../_shared/utils';

interface ActivityProps {
  activityType: ActivityType | null;
  onCreateActivity: (value: Record<string, any>) => void;
  activities: Record<string, any>[];
  initialValues?: Record<string, any>;
  currentActivity: any;
  form: FormInstance;
  classList: Record<string, any>[];
  syllabusCategoryList: Record<string, any>[];
  learnerGroupList: { label: string; value: string }[];
  onActivityItemSelected: (activity: ActivityType, resetForm?: boolean) => void;
  currentLesson?: LessonNameSpace.Lesson;
  onRemovePlaylistActivity: () => void;
}

export const Activity = (props: ActivityProps) => {
  const {
    activityType,
    onCreateActivity,
    activities,
    currentActivity,
    form,
    initialValues,
    classList,
    syllabusCategoryList,
    learnerGroupList,
    onActivityItemSelected,
    currentLesson,
    onRemovePlaylistActivity,
  } = props;

  const hasActivities = !!activities.length;
  return (
    <div
      className={classname({
        'stm-create-lesson-activity': true,
        'stm-create-lesson-activity-shifted': hasActivities,
      })}
    >
      {activityType && (
        <ActivityContainer>
          <ActivityForm
            formProps={{
              onFinish: onCreateActivity,
              initialValues,
              name: activityType,
              form,
            }}
            currentActivity={currentActivity}
            classList={classList}
            syllabusCategoryList={syllabusCategoryList}
            learnerGroupList={learnerGroupList}
            activityType={activityType}
            onRemove={onRemovePlaylistActivity}
            currentLesson={currentLesson}
            onActivityItemSelected={onActivityItemSelected}
          >
            <ActiveTypeEnumerator
              onActivityItemSelected={onActivityItemSelected}
              activityType={activityType}
              currentLesson={currentLesson}
              form={form}
            />
          </ActivityForm>
        </ActivityContainer>
      )}

      {!activityType && (
        <div className="stm-create-lesson-activity-empty">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              'To begin creating your playlist add an activity from the side bar'
            }
          />
        </div>
      )}
    </div>
  );
};
