import {
  createUnit,
  deleteUnit,
  deleteUnitLesson,
  findUnit,
  getUnit,
  groupLessonByUnit,
  setCurrentUnit,
  updateDragAndDrop,
  updateUnit,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { useEffect } from 'react';
import { selectUnitList } from '../../selectors/unit';

export type UseUnitProps = {
  classId?: string;
  params?: Record<string, any> | any;
  key: string;
  userId?: string;
  alternateKey?: string;
  doFind?: boolean;
};

export type UseUnitType = {
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    _id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  handleGroupLessonByUnit: (options?: ActionOption) => void;
  handleFetch: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  handleDelete: (_id: string, options?: ActionOption) => void;
  handleDragAndDrop: (
    payload: Record<'units', any>,
    options: ActionOption
  ) => void;
  handleDeleteUnitLesson: (
    lessonId: string,
    unitId: string,
    options?: ActionOption
  ) => void;
  setCurrentUnit: (
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  units: LessonNameSpace.Unit[];
  unitList: { label: string; value: string }[];
  currentUnit: LessonNameSpace.Unit;
  groupedLesson: LessonNameSpace.GroupedLesson[];
};

export const useUnit = ({
  classId,
  params = {},
  key,
  doFind,
  alternateKey,
  userId,
}: UseUnitProps): UseUnitType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    alternateKey,
    params: {
      user: userId,
      ...params,
    },
  };

  const { units, currentUnit, unitList, groupedLesson } = useSelector(
    ({ unit }: RootState) => ({
      currentUnit: unit?.currentUnit[key],
      unitList: selectUnitList(unit, key),
      units: unit?.units[key],
      groupedLesson: unit?.groupedLesson[key],
    })
  );

  useEffect(() => {
    if (doFind) {
      dispatch(
        findUnit({
          ...defaultOptions,
        })
      );
    }
  }, [doFind, classId]);

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(createUnit(payload, { ...defaultOptions, ...options }));
  };

  const handleUpdate = (
    id: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(updateUnit(id, payload, { ...defaultOptions, ...options }));
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(deleteUnit(id, { ...defaultOptions, ...options }));
  };

  const handleFetch = (options?: ActionOption) => {
    dispatch(findUnit({ ...defaultOptions, ...options }));
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getUnit(id, { ...defaultOptions, ...options }));
  };

  const handleGroupLessonByUnit = (options?: ActionOption) => {
    dispatch(groupLessonByUnit({ ...defaultOptions, ...options }));
  };
  const setCurrent = (payload: Record<string, any>, options?: ActionOption) => {
    dispatch(setCurrentUnit(payload, { ...defaultOptions, ...options }));
  };

  const handleDeleteUnitLesson = (
    lessonId: string,
    unitId: string,
    options?: ActionOption
  ) => {
    dispatch(
      deleteUnitLesson(lessonId, unitId, { ...defaultOptions, ...options })
    );
  };

  const handleDragAndDrop = (payload: Record<'units', any>) => {
    dispatch(updateDragAndDrop(payload, classId));
  };

  return {
    setCurrentUnit: setCurrent,
    handleCreate,
    handleUpdate,
    handleFetch,
    handleFetchOne,
    handleDelete,
    handleGroupLessonByUnit,
    handleDeleteUnitLesson,
    handleDragAndDrop,
    units,
    unitList,
    currentUnit,
    groupedLesson,
  };
};
