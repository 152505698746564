export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$',
    'i'
  );
  return pattern.test(url);
};

export const isIFrame = (
  input: HTMLElement | null | Element
): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';
