import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ASSIGNMENT_DETAIL = createActionType(
  'FIND_ASSIGNMENT_DETAIL',
  'ASSIGNMENTS'
);

export const findAssignmentDetail = (option?: ActionOption) => ({
  type: FIND_ASSIGNMENT_DETAIL.START,
  meta: {
    ...option,
  },
});
