import {Col, FormInstance} from 'antd';
import {Question, QuestionType} from '../../../../../_shared';
import {ActivityType} from '../../../../../../_shared/constants';
import {useStandard} from '../../../../../../hooks';
import {uniqBy} from 'lodash';

interface ActivityGameProps {
  activityType: ActivityType;
  form?: FormInstance;
  currentLesson?: LessonNameSpace.Lesson;
}

export const GameForm = (props: ActivityGameProps) => {
  const { activityType, form, currentLesson } = props;
  const { collations } = useStandard({ doFind: false, key: 'create-lesson' });
  return (
    <div data-testid={'activity-game-form'} className={'w-100'}>
      <Col span={24}>
        {activityType === ActivityType.RacingGame && (
          <Question
            standards={
              currentLesson
                ? uniqBy(
                    (currentLesson?.standards ?? []).concat(collations ?? []),
                    '_id'
                  )
                : null
            }
            defaultQuestionType={QuestionType.SingleAnswer}
            optionsLength={{
              min: 2,
              max: 2,
            }}
            form={form}
            allowedQuestionTypes={[QuestionType.SingleAnswer]}
          />
        )}
        {activityType === ActivityType.ObbyGame && (
          <Question
            standards={
              currentLesson
                ? uniqBy(
                    (currentLesson?.standards ?? []).concat(collations ?? []),
                    '_id'
                  )
                : null
            }
            defaultQuestionType={QuestionType.SingleAnswer}
            optionsLength={{
              min: 1,
              max: 4,
            }}
            form={form}
            allowedQuestionTypes={[QuestionType.SingleAnswer]}
          />
        )}
        {/* Plot Game ONLY gets a text field */}
        {activityType === ActivityType.PlotActivity && (
          <>
            <Question
              standards={
                currentLesson
                  ? uniqBy(
                      (currentLesson?.standards ?? []).concat(collations ?? []),
                      '_id'
                    )
                  : null
              }
              defaultQuestionType={QuestionType.FileUpload}
              form={form}
              allowedQuestionTypes={[QuestionType.FileUpload]}
              questionsLength={{ min: 1, max: 1 }}
              helpText={
                'Students can be required to upload a video for submission of their plot. Fill out the following question to outline what you would like your student to upload.'
              }
            />
          </>
        )}
      </Col>
    </div>
  );
};
