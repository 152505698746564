import { ActionOption, RootState } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAssignmentSubmission,
  findAssignmentSubmissions,
  getAssignmentSubmission,
  modifyAssignmentSubmission,
  setCurrentAssignmentSubmission,
  updateFinalActivityGrade,
} from '../../redux/actions';
import { get as LodashGet } from 'lodash';
import { useEffect } from 'react';

export type UseAssignmentSubmissionParameters = {
  initialKey?: string;
  initialOptions?: Record<string, any>;
};

export interface UseAssignmentSubmission {
  readonly setCurrent: (
    submission: ClassesNamespace.AssignmentSubmission | object,
    key: string
  ) => void;
  readonly create: (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly modify: (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;
  readonly handleUpdateGrade: (
    payload: Record<string, any>,
    key: string,
    options?: Record<'studentId', any>
  ) => void;
  readonly findAll: (
    assignmentId: string,
    key: string,
    options?: ActionOption
  ) => void;
  readonly findOne: (_id: string, key: string, options?: ActionOption) => void;
  readonly get: (key: string) => {
    readonly submissionList: {
      submitted: ClassesNamespace.AssignmentSubmission[];
      all: ClassesNamespace.AssignmentSubmission[];
      pending: ClassesNamespace.AssignmentSubmission[];
    };
    readonly submissionsById: Record<
      string,
      ClassesNamespace.AssignmentSubmission
    >;
    readonly current?: ClassesNamespace.AssignmentSubmission | null;
  };
}

export const useAssignmentSubmission = (
  props?: UseAssignmentSubmissionParameters
): UseAssignmentSubmission => {
  const { initialKey, initialOptions } = props ?? {};
  const dispatch = useDispatch();

  const { currentAssignmentSubmission, submissions, submissionsById } =
    useSelector(({ assignments }: RootState) => ({
      currentAssignmentSubmission: assignments?.currentSubmissions,
      submissions: assignments?.submissions,
      submissionsById: assignments?.submissionsById,
    }));

  const create = (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(createAssignmentSubmission(payload, { ...options, key }));
  };

  const modify = (
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(modifyAssignmentSubmission(payload, { ...options, key }));
  };

  const findAll = (
    assignmentId: string,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(findAssignmentSubmissions(assignmentId, { ...options, key }));
  };

  const findOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(getAssignmentSubmission(id, { ...options, key }));
  };

  const setCurrent = (
    submission: ClassesNamespace.AssignmentSubmission | object,
    key: string
  ) => {
    dispatch(setCurrentAssignmentSubmission(submission, key));
  };

  const get = (key: string) => {
    return {
      current: LodashGet<
        Record<string, ClassesNamespace.AssignmentSubmission>,
        string,
        ClassesNamespace.AssignmentSubmission | null | undefined
      >(currentAssignmentSubmission, key, null),
      submissionList: LodashGet<
        Record<
          string,
          {
            submitted: ClassesNamespace.AssignmentSubmission[];
            all: ClassesNamespace.AssignmentSubmission[];
            pending: ClassesNamespace.AssignmentSubmission[];
          }
        >,
        string,
        {
          submitted: ClassesNamespace.AssignmentSubmission[];
          all: ClassesNamespace.AssignmentSubmission[];
          pending: ClassesNamespace.AssignmentSubmission[];
        }
      >(submissions, key, { submitted: [], all: [], pending: [] }),
      submissionsById: LodashGet<
        Record<string, Record<string, ClassesNamespace.AssignmentSubmission>>,
        string,
        Record<string, ClassesNamespace.AssignmentSubmission>
      >(submissionsById, key, {}),
    };
  };

  useEffect(() => {
    if (initialKey) {
      findAll(
        initialOptions?.assignmentId,
        initialKey,
        Object.assign({}, initialOptions)
      );
    }
  }, []);

  const handleUpdateGrade = (
    payload: Record<string, any>,
    key: string,
    options?: Record<string, any>
  ) => {
    dispatch(updateFinalActivityGrade(payload, { ...options, key }));
  };

  return {
    findOne,
    findAll,
    get,
    modify,
    create,
    setCurrent,
    handleUpdateGrade,
  };
};
