import { createSelector } from 'reselect';
import { ActivityState } from '../../redux/reducers/activity';

const activities = (activity: ActivityState, key: string) =>
  activity?.activities[key] || [];

export const selectActivityList = createSelector(
  activities,
  (userClasses: LessonNameSpace.Activity[]) => {
    return userClasses.map(({ _id: value, title: label }) => ({
      value,
      label,
    }));
  }
);
