import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_PLAYLIST = createActionType('FIND_PLAYLIST', 'PLAYLIST');

export const findPlaylist = (options?: ActionOption) => ({
  type: FIND_PLAYLIST.START,
  meta: {
    ...options,
  },
});
