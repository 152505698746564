import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ASSIGNMENT_SUBMISSION = createActionType(
  'GET_ASSIGNMENT_SUBMISSION',
  'ASSIGNMENTS'
);

export const getAssignmentSubmission = (
  submissionId: string,
  option?: ActionOption
) => ({
  type: GET_ASSIGNMENT_SUBMISSION.START,
  meta: {
    submissionId,
    ...option,
  },
});
