import { Badge, Space, Tag } from 'antd';
import { capitalize, get } from 'lodash';
import { COLOR_LIST_ALPHA } from '../../../../../_shared/constants';

interface FormPlaylistListItemProps {
  columns: [string, string, Array<{ value: string; label: string }>, boolean];
  onGradedActivity: (value: boolean) => void;
  itemId: string;
  playlistId: string;
}
export const FormPlaylistListItem = (props: FormPlaylistListItemProps) => {
  const { columns } = props;
  // const {} = usePlaylist({
  //   key: LESSON_PLAYLIST_KEY,
  //   doFind: true,
  //   userId: LESSON_PLAYLIST_KEY,
  //   params: {
  //     population: JSON.stringify(['questions', 'activities']),
  //   },
  //   alternateKey: LESSONS_PAGE_KEY,
  // });

  // const onRemovePlaylistActivity = () => {
  //   handleDeletePlaylist(playlistId, itemId, {
  //     params: {
  //       population: JSON.stringify([
  //         { path: 'activities', populate: ['questions'] },
  //       ]),
  //     },
  //     uiKey: `@@LESSON_PLAYLIST_DELETE_ACTIVITY/${itemId}`,
  //   });
  // };

  // const {
  //   uiLoaders: {
  //     // [`@@LESSON_PLAYLIST_DELETE_ACTIVITY/${itemId}`]: deletingActivity,
  //   },
  // } = useUIState();

  return (
    <div className="stm-create-lesson-form-list-item stm-pa-5">
      <Space size={20}>
        <Badge
          color={get(COLOR_LIST_ALPHA, capitalize(columns[0] ?? 'A').charAt(0))}
        />
        <span>{capitalize(columns[0])}</span>
      </Space>

      <Tag color={'purple'}>{columns[1]}</Tag>
      {/*<Select*/}
      {/*  placeholder={'Select...'}*/}
      {/*  options={columns[2] ?? []}*/}
      {/*  style={{ width: 180 }}*/}
      {/*/>*/}

      <Space size={20}>
        {/*<Checkbox*/}
        {/*  checked={columns[3]}*/}
        {/*  onChange={(e) => onGradedActivity(e.target.value)}*/}
        {/*>*/}
        {/*  Graded Activity*/}
        {/*</Checkbox>*/}
      </Space>
    </div>
  );
};
