import { Action } from '../../types';
import {
  GET_DUE_ACTIVITIES,
  GET_OVERVIEW_STATS,
  GET_WEEK_AT_A_GLANCE,
} from '../../actions';
import { endOfDay } from 'date-fns';
import { concat, get, has, reduce } from 'lodash';

export interface OverviewState {
  weekAtAGlance: Record<
    string,
    {
      [week: string]: ClassesNamespace.WeekAtAGlance[];
    }
  >;

  dueActivities: Record<string, ClassesNamespace.Assignments[]>;
  overviewStats: Record<
    string,
    {
      totalMissedAssignment: number;
      totalAnnouncement: number;
      totalAbsence: number;
      totalAverageScore: number;
    }
  >;
}

export const overviewDefaultState: OverviewState = {
  weekAtAGlance: {},
  dueActivities: {},
  overviewStats: {},
};

const overviewReducer = (state = overviewDefaultState, action: Action) => {
  const { payload, key, meta } = action;
  switch (action.type) {
    case GET_WEEK_AT_A_GLANCE.SUCCESS: {
      const weekAtAGlance = Object.assign({}, state.weekAtAGlance[key], {
        [meta?.week]: groupWeekAtAGlance(payload, meta?.daysInWeek ?? []),
      });

      return {
        ...state,
        weekAtAGlance: {
          [key]: weekAtAGlance,
        },
      };
    }

    case GET_OVERVIEW_STATS.SUCCESS: {
      return Object.assign({}, state, {
        overviewStats: {
          ...state.overviewStats,
          [action.key]: payload,
        },
      });
    }

    case GET_DUE_ACTIVITIES.SUCCESS: {
      return Object.assign({}, state, {
        dueActivities: {
          ...state.dueActivities,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export function groupWeekAtAGlance(
  payload: Record<string, any>[],
  daysInWeek: Date[]
) {
  const defaultGroup = reduce(
    daysInWeek,
    (accumulator: Record<string, any>, day: Date) => {
      return {
        ...accumulator,
        [endOfDay(new Date(day)).toISOString()]: [],
      };
    },
    {}
  );

  const callback = (
    accumulator: Record<string, any>,
    current: Record<string, any>
  ) => {
    const date = get(current, 'classes.startDate');
    const clonedAccumulator = { ...accumulator };

    if (date) {
      const startDate = endOfDay(new Date(date));
      const dateString = startDate.toISOString();

      if (has(clonedAccumulator, dateString)) {
        clonedAccumulator[dateString] = concat(
          get(clonedAccumulator, [dateString], []),
          current
        );
        return clonedAccumulator;
      }
      return clonedAccumulator;
    }

    return clonedAccumulator;
  };

  return reduce(payload, callback, defaultGroup);
}

export default overviewReducer;
