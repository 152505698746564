import { Action } from '../../types';
import {
  CREATE_EXIT_TICKET,
  CREATE_LESSON,
  CREATE_PLAYLIST,
  DELETE_LESSON,
  DELETE_PLAYLIST,
  DELETE_PLAYLIST_ACTIVITY,
  FIND_LESSON,
  GET_LESSON,
  GET_TODAY_LESSON,
  UPDATE_LESSON,
  UPDATE_PLAYLIST,
} from '../../actions';
import { arrayToById } from '../../../_shared/utils';
import { LESSON_PLAYLIST_KEY } from '../../../components/lesson/playlist/CreatePlayList';
import { LESSONS_PAGE_KEY } from '../../../pages/teacher/lessons/create';

export interface LessonState {
  lessons: Record<string, LessonNameSpace.Lesson[]> | any;
  lessonsById: Record<string, Record<string, LessonNameSpace.Lesson>> | any;
  currentLesson: LessonNameSpace.Lesson | any;
  todayLesson: LessonNameSpace.Lesson | any;
}

export const LessonStateDefaultState: LessonState = {
  lessons: {},
  lessonsById: {},
  currentLesson: {},
  todayLesson: {},
};

const lessonReducer = (state = LessonStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_LESSON.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        lessonsById: {
          ...state.lessonsById,
          [action.key]: byIdFind,
        },
        lessons: {
          ...state.lessons,
          [action.key]: Object.values(byIdFind),
        },
        currentLesson: {
          ...state.currentLesson,
          [action.key]: {},
        },
      });
    }
    case GET_LESSON.SUCCESS:
    case CREATE_LESSON.SUCCESS:
    case UPDATE_LESSON.SUCCESS: {
      const byIdCreate = {
        ...state.lessonsById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        lessonsById: {
          ...state.lessonsById,
          [action.key]: byIdCreate,
        },
        lessons: {
          ...state.lessons,
          [action.key]: Object.values(byIdCreate),
        },
        currentLesson: {
          ...state.currentLesson,
          [action.key]: payload,
        },
      });
    }
    case DELETE_LESSON.SUCCESS: {
      const byId = { ...state.lessonsById[action.key] };
      delete byId[payload._id];
      return Object.assign({}, state, {
        lessonsById: {
          ...state.lessonsById,
          [action.key]: byId,
        },
        lessons: {
          ...state.lessons,
          [action.key]: Object.values(byId),
        },
        currentLesson: {
          ...state.currentLesson,
          [action.key]: {},
        },
      });
    }
    case GET_TODAY_LESSON.SUCCESS: {
      return Object.assign({}, state, {
        todayLesson: {
          ...state.todayLesson,
          [action.key]: payload,
        },
      });
    }
    case CREATE_EXIT_TICKET.SUCCESS: {
      if (action.key === LESSONS_PAGE_KEY && action.alternateKey) {
        return {
          ...state,
          currentLesson: {
            ...state.currentLesson,
            [action.alternateKey]: Object.assign(
              {},
              state.currentLesson[action.alternateKey],
              { exitTicket: action.payload }
            ),
          },
        };
      }
      return { ...state };
    }

    case DELETE_PLAYLIST_ACTIVITY.SUCCESS:
    case DELETE_PLAYLIST.SUCCESS:
    case UPDATE_PLAYLIST.SUCCESS:
    case CREATE_PLAYLIST.SUCCESS: {
      if (action.key === LESSON_PLAYLIST_KEY && action.alternateKey) {
        const playlist = [...(state.currentLesson?.playlist ?? [])];
        const playlistIndex = playlist.findIndex(
          (o) => action.payload?._id && o?._id === action.payload?._id
        );
        if (playlistIndex !== -1) {
          if (
            action.key === DELETE_PLAYLIST.SUCCESS ||
            action.key === DELETE_PLAYLIST_ACTIVITY.SUCCESS
          ) {
            playlist.splice(playlistIndex, 1);
          } else {
            playlist[playlistIndex] = action.payload;
          }
        } else {
          playlist.push(action.payload);
        }

        return {
          ...state,
          currentLesson: {
            ...state.currentLesson,
            [action.alternateKey]: Object.assign(
              {},
              state.currentLesson[action.alternateKey],
              { playlist }
            ),
          },
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default lessonReducer;
