import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ASSIGNMENT = createActionType('GET_ASSIGNMENT', 'ASSIGNMENTS');

export const getAssignment = (_id: string, option?: ActionOption) => ({
  type: GET_ASSIGNMENT.START,
  meta: {
    _id,
    ...option,
  },
});
