import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_MASTERY = createActionType('GET_MASTERY', 'MASTERY');

export const getMastery = (options: ActionOption) => ({
  type: GET_MASTERY.START,
  meta: {
    ...options,
  },
});
