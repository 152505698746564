import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_PLAYLIST = createActionType('DELETE_PLAYLIST', 'PLAYLIST');

export const deletePlaylist = (_id: string, options?: ActionOption) => ({
  type: DELETE_PLAYLIST.START,
  meta: {
    ...options,
    _id,
  },
});
