import { createActionString } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const SET_CURRENT_UNIT = createActionString('SET_CURRENT_UNIT', 'UNIT');

export const setCurrentUnit = (
  payload?: Record<string, any>,
  options: ActionOption = {}
) => ({
  type: SET_CURRENT_UNIT,
  payload,
  ...options,
});
