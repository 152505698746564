import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GOOGLE_IMPORT_GET_COURSE_WORKS = createActionType(
  'GOOGLE_IMPORT_GET_COURSE_WORKS',
  'GOOGLE IMPORT'
);

export const getGoogleCourseWorks = (
  courseId: string,
  option?: ActionOption
) => ({
  type: GOOGLE_IMPORT_GET_COURSE_WORKS.START,
  meta: {
    courseId,
    ...option,
  },
});
