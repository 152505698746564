import { findPlaylist } from './findPlaylist';
import { createPlaylist } from './createPlaylist';
import { updatePlaylist } from './updatePlaylist';
import { getPlaylist } from './getPlaylist';
import { deletePlaylist } from './deletePlaylist';
import { deletePlaylistActivity } from './deletePlaylistActivity';

export default [
  findPlaylist,
  createPlaylist,
  updatePlaylist,
  getPlaylist,
  deletePlaylist,
  deletePlaylistActivity,
];
