export const UploadResourceIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/webp':
      return (
        <span className="anticon" data-testid={'icon-type/image'}>
          <i className="ri-image-line" />
        </span>
      );
    case 'video/mp4':
    case 'video/ogg':
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/webm':
    case 'video/3gpp':
    case 'application/x-mpegURL':
    case 'video/x-ms-wmv':
      return (
        <span className="anticon" data-testid={'icon-type/video'}>
          <i className="ri-video-line" />
        </span>
      );
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/3gpp':
    case 'audio/aac':
      return (
        <span className="anticon" data-testid={'icon-type/audio'}>
          <i className="ri-file-music-line" />
        </span>
      );
    case 'application/pdf': {
      return (
        <span className="anticon" data-testid={'icon-type/pdf'}>
          <i className="ri-file-pdf-line" />
        </span>
      );
    }
    case 'text/plain': {
      return (
        <span className="anticon" data-testid={'icon-type/txt'}>
          <i className="ri-file-text-line" />
        </span>
      );
    }
    case 'image/gif': {
      return (
        <span className="anticon" data-testid={'icon-type/gif'}>
          <i className="ri-file-gif-line" />
        </span>
      );
    }
    case 'application/zip': {
      return (
        <span className="anticon" data-testid={'icon-type/zip'}>
          <i className="ri-file-zip-line" />
        </span>
      );
    }
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return (
        <span className="anticon" data-testid={'icon-type/spreadsheet'}>
          <i className="ri-file-excel-2-line" />
        </span>
      );
    }
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return (
        <span className="anticon" data-testid={'icon-type/document'}>
          <i className="ri-file-word-2-line" />
        </span>
      );
    }
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return (
        <span className="anticon" data-testid={'icon-type/presentation'}>
          <i className="ri-file-ppt-2-line" />
        </span>
      );
    }
    case 'link':
      return (
        <span className="anticon" data-testid={'icon-type/link'}>
          <i className="ri-links-line" />
        </span>
      );
    default:
      return (
        <span className="anticon" data-testid={'icon-type/file'}>
          <i className="ri-file-4-line" />
        </span>
      );
  }
};
